import { Pipe, PipeTransform } from "@angular/core";
import { Controller } from "src/app/core/models/controller.model";

@Pipe({
    name: 'isIoCommonArea'
})
export class IsIoCommonAreaPipe  implements PipeTransform {
    transform(controller: Controller): boolean {
        return Controller.isIOCommonArea(controller);
    }
}