import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { CardLogin } from 'src/app/core/models/card-login.modal';
import { UrlService } from 'src/app/shared/services/url.service';


@Component({
  selector: 'app-card-login',
  templateUrl: './card-login.component.html',
  styleUrls: ['./card-login.component.scss']
})
export class CardLoginComponent implements OnInit {

  @Input() cardUid: string;
  @ViewChild('search') searchElement: ElementRef;
  showError = false;
  pin: string;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private urlService: UrlService,
              private modalController: ModalController) { }

  ngOnInit(): void { 
  }
    
  ngAfterViewInit() {
    setTimeout( () => {
      this.searchElement.nativeElement.focus();
    },800)
  }

  onLogin(pin?: string) {
    let cardLogin: CardLogin = new CardLogin();
    cardLogin.PIN = this.pin;
    cardLogin.UID = this.cardUid;
    this.authService.loginWithCard(cardLogin).subscribe(
      response => {
        this.modalController.dismiss();
        if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login')) {
          this.router.navigate([this.urlService.lastUrl]);
        } else {
          this.router.navigate(['/rooms']);
        }
      }
    );
  }


}

