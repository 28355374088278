import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { LocationGroup } from '../../services/api-location-groups.service';

@Component({
  selector: 'app-buttons-select',
  templateUrl: './buttons-select.component.html',
  styleUrls: ['./buttons-select.component.scss']
})
export class ButtonsSelectComponent implements OnInit {

  @Input() locationCrossRef: LocationPresetCrossRef[];
  @Input() defaultPresets: DefaultsPreset[];
  @Input() hvacModeLocationGroups: LocationGroup[];
  @Input() controller: Controller;

  heatingPresets: DefaultsPreset[] = [];
  coolingPresets: DefaultsPreset[] = [];

  presetsToSelect: DefaultsPreset[];

  constructor(private popoverController: PopoverController) { }

  ngOnInit(): void {

    this.hvacModeLocationGroups = this.hvacModeLocationGroups.filter (group => group.locations.some( loc => loc.controllers.$values.some( cont => cont.designation == this.controller.designation)))
    this.locationCrossRef = this.locationCrossRef.filter (crossReff => {
      return this.hvacModeLocationGroups.some (group => {
        if (crossReff.locationId == group.id) {return true;}
      })
    })

    this.defaultPresets = this.defaultPresets.filter( preset => {
      return this.locationCrossRef.some( crossReff => {
        if (crossReff.presetId == preset.id) {return true;}
      })
    })

    this.heatingPresets = this.defaultPresets.filter( preset => {
      const target = this.locationCrossRef.find( element => element.presetId == preset.id)
      if (target.hvacMode == 0) {
        return true;
      }
    })

    this.coolingPresets = this.defaultPresets.filter( preset => {
      const target = this.locationCrossRef.find( element => element.presetId == preset.id)
      if (target.hvacMode == 1) {
        return true;
      }
    })
  }

  sendPreset(preset: DefaultsPreset) {
    this.popoverController.dismiss(preset.id)

  }

}
