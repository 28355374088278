<div class="flex-row-center-full">
  <ion-spinner *ngIf="!ccpsReady" class="margin-top100 flex-row-center-full" name="lines-sharp"></ion-spinner>
</div>

<div *ngIf="ccpsReady" class="flex-row margin-top30 width100 flex-row margin-right50 margin-left100">
  <div class="flex-column width8">
    <ion-list *ngIf="controllers.length > 1" class="margin20">
      <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
      <ion-item
        button
        style="width: 80px;"
        *ngFor="let controller of controllers"
        (click)="selectController(controller)"
        [ngClass]="{'ion-list-activated': selectedController.id === controller.id}">
        {{ controller.name }}
        <div [ngClass]="{'inactive-icon-small': !controller.isOnline}"></div>
      </ion-item>
    </ion-list>
  </div>

  <div class="flex-row-vertical margin-bottom50" style="width: 70%;">
    <table>
      <thead>
        <tr>
          <th class="width10" style="align-content: end;">
            <ion-checkbox
            [checked]="selectedControllerCommParams.length === controllerCommParams.length"
            (ionChange)="onSelectAllParams($event)">
            </ion-checkbox>
          </th>
          <th class="width20">Name</th>
          <th class="width20">Memory type</th>
          <th class="width20">Memory position</th>
          <th class="width10">Value</th>
          <th class="width20">Controller Value</th>
          <th class="width20">
            <ion-button
              color="primary"
              size="small"
              (click)="addNewParam()">+</ion-button>
          </th>
        </tr>
      </thead>
      <ng-container *ngIf="controllerCommParams.length !== 0 && controllerCommParams; let i = index">
        <tr *ngFor="let param of controllerCommParams; let i = index">
          <th class="width10" style="align-content: end;">
            <ion-checkbox
              [checked]="isParamSelected(i)"
              (ionChange)="onSelectOneParam(i, $event)"
            ></ion-checkbox>
          </th>
          <td class="width20">
            <input
              class="margin-left5"
              style="width: 120px;"
              type="text"
              [(ngModel)]="param.description"
              [value]="param.description"
              size="5"/>
          </td>
          <td class="width20">
            <select [(ngModel)]="param.memoryType">
              <option [ngValue]="'INT'">Internal memory</option>
              <option [ngValue]="'EP1'">Page 1</option>
              <option [ngValue]="'EP2'">Page 2</option>
              <option [ngValue]="'EP3'">Page 3</option>
            </select>
          </td>
          <td class="width20">
            <input
              class="margin-left5 width40"
              type="number"
              name="number"
              required min="0" max="255"
              #number="ngModel"
              (input)="checkValidity($event)"
              [(ngModel)]="param.memoryPosition"
              [value]="param.memoryPosition"
              size="5"/>
              <div *ngIf="number.invalid && (number.dirty || number.touched)">
                <div *ngIf="number.errors?.required" style="color: red;">
                  Number is required!
                </div>
                <div *ngIf="number.errors?.min || number.errors?.max" style="color: red;">
                  Number must be between 0 and 255!
                </div>
              </div>
          </td>
          <td class="width10">
            <div class="flex-row-center-full">
              <input
                class="margin-left5 width60"
                type="number"
                [(ngModel)]="param.value"
                [value]="param.value"
                size="8"/>
                <!-- {{ param.value }} -->
            </div>
          </td>
          <td >
            <div class="flex-row-center-full">
              <!-- <input
                class="margin-left5"
                type="text"
                [(ngModel)]="controllerValue"
                size="8"/> -->
                 {{ controllerValues[i] }}
            </div>
          </td>
          <td class="flex-row" style="width: max-content;">
            <ion-button
            color="primary"
            class="ion-no-border"
            fill="outline"
            (click)="readParam(i)"
            size="small">READ</ion-button>
            <ion-button
            color="primary"
            class="ion-no-border"
            fill="outline"
            (click)="sendParam(i)"
            size="small">SEND</ion-button>
            <ion-button
            color="primary"
            class="ion-no-border"
            fill="outline"
            (click)="deleteParam(i)"
            size="small">X</ion-button>
          </td>
        </tr>
      </ng-container>
    </table>
    <div class="flex-column" style="align-content: end;">
      <div class="flex-row margin-top10 margin-bottom10">
        <ion-button
        color="primary"
        class="ion-no-border"
        fill="outline"
        (click)="readSelected()"
        [disabled]="selectedControllerCommParams.length <= 0"
        size="small">READ</ion-button>
        <ion-button
        color="primary"
        class="ion-no-border"
        fill="outline"
        (click)="sendSelected()"
        [disabled]="selectedControllerCommParams.length <= 0"
        size="small">SEND</ion-button>
        <ion-button
        color="primary"
        class="ion-no-border"
        fill="outline"
        (click)="openApplyToModal()"
        [disabled]="selectedControllerCommParams.length <= 0"
        size="small">APPLY TO</ion-button>
      </div>
      <ion-button
        style="align-self: flex-end;"
        (click)="save()"
        [disabled]="selectedControllerCommParams.length <= 0 || (!isValid && isValid !== undefined)"
        >SAVE</ion-button>
    </div>
  </div>
</div>

<!-- <div class="flex-display margin20">
  <div class="loc-sidebar">
    <div class="loc-sidebar-header">{{'Controller ids' | translate}}</div>
    <div
      class="listSingleItem"
      *ngFor="let controller of controllers"
      (click)="selectController(controller)"
    >
      {{ controller.locationId }}
    </div>
    <div class="loc-sidebar-header margin-top100">{{'Location ids' | translate}}</div>
    <div
      class="listSingleItem"
      *ngFor="let controller of controllers"
      (click)="selectLocation(controller.locationId)"
    >
      {{ controller.locationId }}
    </div>
  </div>
  <div
    *ngIf="!selectedLocation && !selectedController"
    class="margin-top50 margin-left100"
  >
    {{'Select a location Id or controller' | translate}}
  </div>

  <app-edit-location-modal
    *ngIf="selectedLocation"
    [locationInput]="selectedLocation"
    (cancelEvent)="cancelSelection()"
  ></app-edit-location-modal>

  <app-edit-controller
    *ngIf="selectedController"
    [controllerInput]="selectedController"
    (cancelEvent)="cancelSelection()"
  ></app-edit-controller>
</div> -->

