import { PipeTransform, Pipe } from '@angular/core';
import { Tag } from 'src/app/core/models/project/location.model';

@Pipe({
    name: 'textInclude'
  })
  export class TextIncludePipe implements PipeTransform {
    transform(fullText: Tag[], searchText: string): Tag[] {
        if (!searchText) {
            return fullText;
        }
        searchText = searchText.toLowerCase();
        return fullText.filter(tag => {
            return tag.tag.includes(searchText)
        });
    }
  }
