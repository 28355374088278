import { Component, OnInit, Input } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Subject } from 'rxjs';
import { Action } from 'src/app/core/models/automation/action';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  @Input() equipmentProperty: Property;
  @Input() equipmentPropertyType: PropertyType;
  @Input() actionInput: Action;
  action: Action = new Action();
  actionChanged$ = new Subject<Action>();
  conditionChanged$ = new Subject<Action>();

  constructor() { }

  ngOnInit(): void {
  }

}
