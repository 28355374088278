import { DATE_PICKER_OPTIONS } from 'src/environments/environment';
import { User } from '../user/user.model';

export class Card {
    uid: string;
    userId: number;
    type: number;
    validFrom: Date;
    validTo: Date;
    blacklisted: number;
    accessBits?: string;
    groups?: string;
    customData?: string;
    accessLogs?: any[];
    user?: User;
    isBackup: boolean;
    techTypeId: number;

    constructor() {
        this.setValidFromToDates();
    }

    setValidFromToDates() {
        this.validFrom = new Date();
        this.validTo = new Date();
        if (DATE_PICKER_OPTIONS.startDate !== 'today') {
            this.validFrom.setDate(this.validFrom.getDate() + +DATE_PICKER_OPTIONS.startDate);
        }
        if (DATE_PICKER_OPTIONS.startTime !== 'now') {
            const hhmm = DATE_PICKER_OPTIONS.endTime.split(':');
            this.validFrom.setHours(+hhmm[0], +hhmm[1]);
        }
        if (DATE_PICKER_OPTIONS.endDate !== 'today') {
            this.validTo.setDate(this.validTo.getDate() + +DATE_PICKER_OPTIONS.endDate);
        }
        if (DATE_PICKER_OPTIONS.endTime !== 'now') {
            const hhmm = DATE_PICKER_OPTIONS.endTime.split(':');
            this.validTo.setHours(+hhmm[0], +hhmm[1]);
        }
    }
}
