import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MqttProjectService } from 'src/app/core/app-load/mqtt-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-convert-loading',
  templateUrl: './convert-loading.component.html',
  styleUrls: ['./convert-loading.component.scss']
})
export class ConvertLoadingComponent {
  loadingStatus$ = this.mqttProjectService.getConvertLoadingStatus();

  constructor(private mqttProjectService: MqttProjectService,
              private loadingModalController: ModalController) {
  }

  closeModal() {
    this.loadingModalController.dismiss();
  }
}
