import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

@Pipe({
  name: 'getControllerByIndex'
})
export class GetControllerByIndexPipe implements PipeTransform {

  transform(controllers: Controller[], controllerIndex: number): Controller[] {
    if (typeof controllerIndex !== 'number') {
      return controllers
    }
    controllers.sort(this.sortBySubzone)

    if (!controllers[controllerIndex]) {
      return []
    }
    const controllerAtIndex = controllers[controllerIndex]
    return [controllerAtIndex];
  }

  sortBySubzone(controllerA: Controller, controllerB: Controller) {
    return Number(controllerA.subZone) - Number(controllerB.subZone)
  }
}


