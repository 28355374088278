import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getFancoilSpeedProps'
})
export class GetFancoilSpeedPropsPipe implements PipeTransform {

  transform(controllers: Controller[], v1Code: number = 5300,  v2Code: number = 5310,  v3Code: number  = 5320, updated?: Date): Property[] {
    if (!controllers) {
      return null;
    }

    const fanSpeedProps =  controllers?.reduce((allFilteredProps, controller)=> {
      const filteredPropsOnController = controller?.controllerProperties?.$values?.filter((prop: Property) => {
        return prop.type === v1Code || prop.type === v2Code || prop.type === v3Code;
      });
      if (filteredPropsOnController) {
        allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
      }
      return allFilteredProps;
    },[]);
    return fanSpeedProps;
  }

}
