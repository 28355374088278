import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { API_BASE_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAccessLogsService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  logHeaders: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient ) { }

  getAccessLogs(requestBody: SsFilterSort, queryParams) {
    const requestParams = new HttpParams()
    .set('PageNumber', queryParams.pageNumber.toString())
    .set('PageSize', queryParams.pageSize.toString())
    .set( 'includeArchive', queryParams.includeArchive);

    return this.http.post<AccessLog[]>(`${API_BASE_URL}/AccessLogs/`,  requestBody  ,
        {
      params: requestParams,
      headers: this.headers,
      observe: 'response'
        });
  }




  exportLogToCSV(requestBody: SsFilterSort, language) {
    const requestParams = new HttpParams()
    .set('language', language)

    this.logHeaders = this.logHeaders.set('Accept', 'text/csv')
    return this.http.post(`${API_BASE_URL}/AccessLogs/export/csv`, requestBody,
        {
      params: requestParams,
      headers: this.logHeaders,
      responseType: 'blob',
      observe: 'response'
        });
  }
  
  
  exportLogToPDF(requestBody: SsFilterSort, language) {
    const requestParams = new HttpParams()
    .set('language', language)

    this.logHeaders = this.logHeaders.set('Accept', 'application/pdf')

    return this.http.post(`${API_BASE_URL}/AccessLogs/export/pdf`, requestBody,
        {
      params: requestParams,
      headers: this.logHeaders,
      responseType: 'blob',
      observe: 'response'
        });
  }

}
