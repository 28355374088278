import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputStatusName'
})
export class GetRelayOutputStatusNamePipe implements PipeTransform {

  transform(properties: Property[]): string {
    if (!Array.isArray(properties)) {
      properties = [properties];
    }
    if (!properties[0]) {
      return ''
    }

    return properties[0].name.split('|')[0];
  }

}
