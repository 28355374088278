import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})
export class ModalSelectComponent implements OnInit {
  @Input() type: string;
  @Input() content: any;
  @Output() decision: EventEmitter<{choice: boolean; selectedId: number}> = new EventEmitter();
  @Input() loading = false;

  selectedId: number = null;

  constructor() { }

  ngOnInit(): void {
  }

  decisionClick(choice: boolean) {
    this.decision.emit({choice: choice, selectedId: this.selectedId});
  }
}
