import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputPropsOfSameType'
})
export class GetRelayOutputPropsOfSameTypePipe implements PipeTransform {

  transform(property: Property, allLocations: Location[]): unknown {
    const relayOutputPropsOfSameType = []
    const propName = property.name.split('|')[0]

    allLocations.forEach((loc: Location)=> {
      loc.controllers.$values.forEach((cont: Controller)=> {
        cont.controllerProperties.$values.forEach((prop: Property )=> {
          if (propName === prop.name.split('|')[0] && Property.isAnyNonStatusRelayOutputProperty(prop)) {
           const propAlreadyInArray = relayOutputPropsOfSameType.some((p)=> {
            return p.name === prop.name
           })
           if (!propAlreadyInArray) {
            relayOutputPropsOfSameType.push(prop)
           }
          }
        })
      })
    })
    return relayOutputPropsOfSameType;
  }
}
