import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Controller } from 'src/app/core/models/controller.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model'
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { Property } from 'src/app/core/models/project/property.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { DateTimeFromToComponent } from 'src/app/modules/logs/components/date-time-from-to/date-time-from-to.component';
import { ApiAccessLogsService } from 'src/app/modules/logs/services/http/api-access-logs.service';
import { ApiAlarmLogsService } from 'src/app/modules/logs/services/http/api-alarm-logs.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { FilterSelectComponent } from 'src/app/shared/components/filter-select/filter-select.component';
import { LogTabType } from 'src/app/shared/components/room-modal-log/room-modal-log.component';

@Component({
  selector: 'app-hvac-common-area-modal-log',
  templateUrl: './hvac-common-area-modal-log.component.html',
  styleUrls: ['./hvac-common-area-modal-log.component.scss']
})
export class HvacCommonAreaModalLogComponent implements OnInit {

  @ViewChild('dateTimeFromTo') dateTimeFromTo: DateTimeFromToComponent;
  // @ViewChild('tablePropertiesSelect') tablePropertiesSelect: any;
  // @ViewChild('tableTemperatureSelect') tableTemperatureSelect: any;
  @Input() controllers: Controller[];
  private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/cntproptypes.json';
  activeCard: LogTabType ;
  propertiesList: Array<any>;
  signedInUser: User;
  SoftwarePermissionId = SoftwarePermissionId;

  // graph
  graphCreated = false;
  tableCreated = true;
  tempGraph: any[];
  circumstancesGraph: any[];
  tableData: any = [];
  valueTableProperties: string[] = [];
  temperatureProperties: string[] = [];
  selectedController: Controller;
  LegendPosition = LegendPosition;

  body: ValueGraph = new ValueGraph;

    // options
  legend = true;
  // xAxis = true;
  // yAxis = true;
  yScaleTempMin = 0;
  yScaleTempMax = 30;
  yScaleHumidityMin = 0;
  yScaleHumidityMax = 100;
  yScaleCo2MeasurmentMin = 200;
  yScaleCo2MeasurmentMax = 2000;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  yAxisLabel = 'Values';
  timeline = false;
  // colorScheme = {domain: ['#6BA0C4', '#03143A']};


  hideCustomTime = true;
  tempEvent: CustomEvent;
  tempEventTemperature: CustomEvent;
  showSelect = true;

  Property = Property;

  propSelect: any;
  temperatureTypesSelect: any;

  // event log
  page = 1;
  giveAccess  = false;
  giveAlarm = false;
  logsLoading = false;

  accessCheckbox = false;
  alarmCheckbox = false;

  queryParams = {
    pageNumber: 1,
    pageSize: 10000,
    includeArchive: false
  };

  searchActive = false
  searchValue = '';
  designationFilter: string[]

  customTemperatureAlertOptions = {
    cssClass: 'custom-temperature-props-alert'
  };

  useHiddenFlag = true;

  constructor(private apiProjectService: ApiProjectService,
              private apiAccessLogService: ApiAccessLogsService,
              private apiAlarmLogsService: ApiAlarmLogsService,
              private popoverController: PopoverController,
              private http: HttpClient,
              private currentUserStoreService: CurrentUserStoreService
              ) { }

  ngOnInit(): void {
    this.selectedController = this.controllers[0];
    this.http.get<any>(this.EQUIPMENT_PROPERTY_TYPES_URL).subscribe( resp => {
      this.propertiesList = resp.Definitions;
    })
    this.designationFilter = this.controllers.map((cont: Controller)=> {
      return cont.designation
    })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
     if (this.signedInUser.havePermission(SoftwarePermissionId.ValueLogView)) {
      // this.activeCard = 'temperature';
      // this.createGraph()
      this.cardClicked('temperature')
    }
    else if (this.signedInUser.havePermission(SoftwarePermissionId.AccessLogView)) {
      this.activeCard = 'access';
    } else if (this.signedInUser.havePermission(SoftwarePermissionId.AlarmsLogView)) {
      this.activeCard = 'alarm';
    }
  }

  cardClicked(card: LogTabType) {
    this.activeCard = card;
    this.valueTableProperties = [];
    this.temperatureTypesSelect = [];
    this.temperatureProperties = [];
    this.propSelect = undefined;
    if (this.activeCard === 'temperature') {
      this.selectedController.controllerProperties.$values.forEach ( prop => {
        if (Property.isRoomTemperatureCurrent(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isRoomTemperatureCurrent)
        } else if (Property.isRoomTemperatureSet(prop)) {
          this.temperatureProperties.push(prop.type.toString())
          this.temperatureTypesSelect.push(Property.isRoomTemperatureSet)
        }
      })
      this.createGraph();
    }
    if (this.activeCard === 'valueLog') {
      setTimeout (() => {
        this.showLast7days();
      },200)
    }
  }

  getPropertyName(propType: string) {
    const target = this.propertiesList.find( (prop: PropertyType) => {
      if (+propType >= prop.CodeFrom && +propType <= prop.CodeTo) { return true}
    })

    if (propType) {
      if (target) {
        return target.Name;
      } else {
        return 'Unknown property';
      }
    } else {
      return null;
    }
  }

  createGraph() {
    this.logsLoading = true;
    this.body.controllerDesignations = [];
    this.body.controllerDesignations.push(this.selectedController.designation);
    if (this.activeCard === 'temperature') {
      this.graphCreated = false;
      // this.body.properties = ['6250', '6200', ...this.valueTableProperties];
      this.body.properties = [...this.temperatureProperties,...this.valueTableProperties];
      const daikinCurrentTemp = this.selectedController.controllerProperties.$values
      .find( prop => Property.isDaikinUnitMeasuredTemperature(prop))
      if (daikinCurrentTemp) {
        this.body.properties.push(daikinCurrentTemp.type.toString())
      }
      const daikinSetTemp = this.selectedController.controllerProperties.$values.find( prop => Property.isDaikinUnitSetTemperature(prop))
      if (daikinSetTemp) {
        this.body.properties.push(daikinSetTemp.type.toString())
      }
    }
    if (this.activeCard === 'valueLog') {
      this.tableCreated = false;
      this.body.properties = this.valueTableProperties;
    }

    if ((this.valueTableProperties.length > 0 && this.activeCard === 'valueLog') || this.activeCard === 'temperature') {
      this.apiProjectService.getGraph(this.body).subscribe( response => {
        if (response) {
          if (this.activeCard === 'temperature') {
            this.tempGraph = this.createDataFromResponse(response);
            this.graphCreated = true;
          }

          if (this.activeCard === 'valueLog') {
            this.tableData = this.reduceDataForTableView(response);
            this.getAlarmAndAcessLogs();
          }
        } else {
          this.graphCreated = false;
        }
      });
    } else {
      this.getAlarmAndAcessLogs();
    }
  }

  reduceDataForTableView(response: any) {
    const tempData = [];
    this.valueTableProperties.forEach( prop => {
      response.forEach( (element , index) => {
        if ((index === 0 && element.propertyType === prop) ||
         (element.propertyType === prop && response[index].value !== response[index-1].value)) {
            tempData.push(element);
        }
      });
    })
    return tempData;
  }

  createDataFromResponse(resp: any) {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: []})
    })

    resp.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      if (target) {
        target.series.push({name: new Date(data.timestamp), value: data.value})
      }
    });
    if (this.activeCard === 'temperature') {
      this.circumstancesGraph = [];
      this.valueTableProperties.forEach( propType => {
        const prop = this.selectedController.controllerProperties.$values.find (p => p.type === +propType);
        if (prop) {
          this.circumstancesGraph.push(...results.filter( data => data.name === this.getPropertyName(prop.type.toString())));
        }
        results = results.filter( data => data.name !== this.getPropertyName(propType));
      })
    }
    return results;
  }

  yAxisTickFormatting = (value) => {
    if (value === 0) {
      return `Closed`
    }
    if (value === 1) {
      return `Open`
    }
  };

  setDateTimeFromTo(selectedDateTime: Date[]) {
    if (!(selectedDateTime[0] === undefined || selectedDateTime[0] === null) &&
     !(selectedDateTime[1] === undefined || selectedDateTime[1] === null)) {
      this.body.dateTimeFrom = selectedDateTime[0].toISOString();
      this.body.dateTimeTo = selectedDateTime[1].toISOString();
      this.createGraph();
    }
  }

  getAlarmAndAcessLogs() {
    if (this.accessCheckbox) {
      this.removeAccessLog();
      this.getAccessLog(1)
    }
    if (this.alarmCheckbox) {
      this.removeAlarmLog()
      this.getAlarmLog(1)
    }

    if (!this.alarmCheckbox && !this.accessCheckbox) {
      this.tableCreated = true;
      this.logsLoading = false;
    }
  }


  showLast24hours() {
    this.dateTimeFromTo.showLast24hours();
  }

  showLast7days() {
    this.dateTimeFromTo.showLast7days();
  }

  showLast30days() {
    this.dateTimeFromTo.showLast30days();
  }

  selectChanged(event: CustomEvent) {
    // saving last selected props for more user friendly exp when swaping controllers
    this.tempEvent = event;
    this.runEventCode(event);
    this.createGraph();
    this.onDismiss()
  }

  selectValuePropsChanged(propTypeValues: string[]) {
    // saving last selected props for more user friendly exp when swaping controllers
    // this.tempEvent = event;
    // this.runEventCode(event);
    this.valueTableProperties = propTypeValues
    this.createGraph();
    this.onDismiss()
  }

  simulateClick() {
    // we use this to emulate button click to open the search component
      // let elements = document.querySelectorAll('.alert-head.sc-ion-alert-md');
      //   console.log(elements)
      //   var rect = elements[0].getBoundingClientRect();
      //   console.log(rect.top, rect.right, rect.bottom, rect.left);
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        clientX: 0,
        clientY:0,
        screenX: 0,
        screenY:0
      });
      this.openSearch(evt);
  }


  onDismiss() {
    if (this.searchActive) {
      this.searchActive = false;
      this.popoverController.dismiss(null)
      this.searchValue = '';
    }
  }

  async openSearch(ev) {
    if (!this.searchActive) {
      this.searchActive = true;
      const myEmitter = new EventEmitter<string>();
      myEmitter.subscribe( value => {
        this.searchValue = value
      });
      const popover = await this.popoverController.create({
        component: FilterSelectComponent,
        cssClass: 'select-search',
        event: ev,
        backdropDismiss: false,
        showBackdrop: false,
        componentProps: {
          inputDelay: 500,
          someTextWriten: myEmitter
        },
      });
      await popover.present();

      popover.onDidDismiss().then( () => {
      })

    }
  }

  runEventCode(event: CustomEvent) {
    this.valueTableProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      event.detail.value.forEach( value => {
        target = value(prop);
        if(target && this.valueTableProperties.indexOf(prop.type.toString()) === -1) {
          this.valueTableProperties.push(prop.type.toString());
        }
      });
    })
  }

  controllerChanged(controller: Controller) {
    this.selectedController = controller;
    if (this.tempEvent) {
      this.runEventCode(this.tempEvent);
    }
    this.createGraph()

    // force ion-select update when swaping controllers
    this.showSelect = false
    setTimeout( () => {
      this.showSelect = true;
    },100)
  }

  propertyExistInController(func: Function) {
    let exists = false;
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let target: Property;
      target = func(prop);
      if (target) {
        exists = true;
      }
    })
    return exists;
  }


  showCustom() {
    if (this.hideCustomTime === true) {
      this.hideCustomTime = false;
    } else {
      this.hideCustomTime = true;
      this.dateTimeFromTo.showLast7days();
    }
  }

  onAccessLogClick(event: any) {
    if (event.target.checked) {
      this.accessCheckbox = true;
      this.createGraph()
    } else {
      this.accessCheckbox = false;
      this.removeAccessLog()
      this.giveAccess = false;
    }
  }

  onAlarmLogClick(event: any) {
    if (event.target.checked) {
      this.alarmCheckbox = true;
      this.createGraph()
    } else {
      this.alarmCheckbox = false;
      this.removeAlarmLog()
      this.giveAlarm = false;
    }
  }

  getAccessLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.controllerDesignations = this.designationFilter
    this.queryParams.pageNumber = pageNumber;

    this.apiAccessLogService.getAccessLogs(requestBody, this.queryParams).subscribe((resp: HttpResponse<AccessLog[]>) => {
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      let accessLogData = resp.body;

      // filtering for single room
        accessLogData = accessLogData.filter( accessData => {
          if (accessData.locationName.includes(this.selectedController.name)) {
            return true;
          }
        })

        this.combineData(accessLogData);
        if (paginationData.HasNext) {
          this.getAccessLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAccess = true;
        }

    });
  }


  getAlarmLog(pageNumber: number) {
    this.tableCreated = false;
    this.logsLoading = true;
    const requestBody: SsFilterSort = new SsFilterSort();
    requestBody.dateTimeFrom = this.body.dateTimeFrom;
    requestBody.dateTimeTo = this.body.dateTimeTo;
    requestBody.controllerDesignations = this.designationFilter


    this.queryParams.pageNumber = pageNumber;


    this.apiAlarmLogsService.getAlarmLogs(requestBody, this.queryParams).subscribe(resp => {
      const alarmLogData = resp.body;
      const paginationData = JSON.parse(resp.headers.get('X-Pagination'));
        this.combineData(null, alarmLogData);
        if (paginationData.HasNext) {
          this.getAlarmLog(paginationData.CurrentPage + 1)
        } else {
          this.sortTable();
          this.giveAlarm = true;
        }
    });
  }

  combineData(accessData?: AccessLog[], alarmData?: any) {
    if (accessData) {
      accessData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), user: data.userFullName, locationName: data.locationName, type: 'access'})
      })
    }

    if(alarmData) {
      alarmData.forEach( data => {
        this.tableData.push({timestamp: new Date(data.timestamp), level: data.level, description: data.description, type: 'alarm'})
      })
    }
  }

  removeAccessLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'access');
  }

  removeAlarmLog() {
    this.tableData = this.tableData.filter( element => element.type !== 'alarm');
  }

  sortTable() {
    this.tableData.sort( (a, b) => {
      return <any>new Date(a.timestamp) - <any> new Date(b.timestamp)
    })
    this.logsLoading = false;
    this.tableCreated = true;
  }

  onPageChange(value: any) {
    this.page = value
  }

  showDataAtPage(index: number) {
    if (index === 0 && this.page === 1) {
      return true
    } else {
      const value = index / 50;
      return (this.page === Math.ceil(value))
    }
  }

  selectTemperatureChanged(event: CustomEvent) {
    this.tempEventTemperature = event;
    this.runEventCodeForTemperature(event.detail.value)
    this.createGraph();
  }

  runEventCodeForTemperature (selectedPropertyFunctions: any[]) {
    this.temperatureProperties = [];
    this.selectedController.controllerProperties.$values.forEach ( prop => {
      let propIsSelected: boolean;
      selectedPropertyFunctions.forEach( propFunction => {
        propIsSelected = propFunction(prop);
        if((propIsSelected && this.temperatureProperties.indexOf(prop.type.toString()) === -1)) {
          this.temperatureProperties.push(prop.type.toString());
        }
      });
    })
  }
}
