<div class="flex-column-center-full">
    <h3 class="br-b-s margin-top10">{{'Active tags' | translate}}</h3>
    <div class="flex-row margin-left5 margin-right5 margin-top15">
        <div *ngFor="let tag of location.locationTags.$values" class="flex-row-center-full margin-right6 tag-box">
            {{tag.tag}} <ion-icon name="close-circle-outline" class="clickable" (click)="removeTagFromController(tag)"></ion-icon>
        </div>
    </div>
    <div class="flex-row-center-full margin-top5">
        <input [(ngModel)]="newTagName" class="tag-input" [placeholder]="'Add new tag' | translate" type="text" (keydown.enter)="addNewTag()" (focus)="onFocus()" (blur)="onBlur()">
        <ion-icon name="checkmark-outline" class="clickable" (click)="addNewTag()"></ion-icon>
    </div>
    <div *ngIf="showDropdown" class="tags-dropdown-container">
        <div class="flex-column tags-dropdown " [ngClass]="{'no-display' : (existingTags | textInclude : newTagName).length === 0}">
            <div *ngFor="let tag of existingTags | textInclude : newTagName" class="clickable tag-hover"  (click)="addNewTag(tag.tag)">
                {{tag.tag}}
            </div>
        </div>
    </div>
</div>


<div class="flex-row-center-full widht100 margin-top10 margin-bottom5">
    <ion-button
      size="small"
      class="square-secondary margin-right5"
      color="ocean-blue"
      (click)="onCancel()"
      >{{ "Cancel" | translate }}</ion-button>
    <ion-button
      size="small"
      class="square-secondary "
      color="ocean-blue"
      (click)="onSave()"
      >{{ "Save" | translate }}</ion-button>

  </div>
