import { LogStatus } from './log-status.enum';

export const LogStatusNameHr = new Map<number, string>([
    [LogStatus.UnknownStatus, 'Nepoznat status'],
    [LogStatus.RejectDoorNotFound, 'Odbijen - Vrata nisu pronađena '],
    [LogStatus.RejectCardNotFound, 'Odbijen - Kartica nije pronađena '],
    [LogStatus.RejectCardNotYetValid, 'Odbijen - Kartica još nije validna '],
    [LogStatus.RejectCardExpired, 'Odbijen - Kartica istekla '],
    [LogStatus.RejectCardBlacklisted, 'Odbijen - Kartica zabranjena '],
    [LogStatus.RejectNoPermission, 'Odbijen - Nema dozvole '],
    [LogStatus.RejectByPermissionRule, 'Odbijen - Od pravila dozvole '],
    [LogStatus.RejectByCounter, 'Odbijen - Od brojača '],
    [LogStatus.RejectByAntiPassback, 'Odbijen - Od zabrane ponovnog prolaska '],
    [LogStatus.StudentCardRegistered, 'Studenska kartica registrirana '],
    [LogStatus.AccessGranted, 'Pristup odobren'],
    [LogStatus.ExitGrantedByConfiguration, 'Izlaz odobren od konfiguracije'],
    [LogStatus.EntryGrantedByConfiguration, 'Ulaz odobren od konfiguracije'],
    [LogStatus.ScheduledDoorUnlock, 'Zakazano otključavanje vrata'],
    [LogStatus.ScheduledDoorLock, 'Zakazano zaključavanje vrata'],
    [LogStatus.SynchronizedFromController, 'Sinkronizirano iz kontrolera - '],
    [LogStatus.Exit, 'Izlaz - ']
    ]);
