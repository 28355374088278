<div class="modal-background-layer">
  <div [ngClass]="{'modal-container': type !== 'levelFourAlarm',
                   'modal-container-alarm': type === 'levelFourAlarm'}">
      <div [ngClass]="{'modal-header': type !== 'levelFourAlarm',
                       'modal-header-alarm': type === 'levelFourAlarm'}">
          <div [ngSwitch]="type">
             <!--  <div *ngSwitchCase="'userdelete'">
                  <h2 >{{'You will delete user' | translate }}:
                    <br>
                    <span class="text-tooltip">
                      <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                      <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br>
                  </h2>
              </div> -->
            <!--   <div *ngSwitchCase="'userlistcantdelete'">
                  <h2 >{{'Can't delete user' | translate }}:
                    <br>
                    <span class="text-tooltip">
                      <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                      <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br>
                  </h2>
              </div> -->
          <!--     <div *ngSwitchCase="'door'" class="text-tooltip">
                  <h2 >{{'You will delete door' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div> -->
              <div *ngSwitchCase="'roomSwap'" class="text-tooltip">
                <h2 >{{'Move guest to room' | translate }}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
            </div>
          <!--     <div *ngSwitchCase="'doorgroup'" class="text-tooltip">
                <h2 >{{'You will delete door group' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div> -->
            <!--   <div *ngSwitchCase="'room'" class="text-tooltip">
                <h2 >{{'You will delete room' | translate}}: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div> -->
             <!--  <div *ngSwitchCase="'role'" class="text-tooltip">
                <h2 >{{'You will delete role' | translate}}: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div> -->
             <!--  <div *ngSwitchCase="'card'">
                <h2 >{{'You will delete card' | translate}}</h2>
              </div> -->
              <!-- <div *ngSwitchCase="'alarmsubscription'" class="text-tooltip">
                <h2 class="text-align-center">{{'You will delete alarm subscription' | translate}}</h2>
              </div> -->
           <!--    <div *ngSwitchCase="'doorunlock'" class="text-tooltip">
                  <h2 >{{'You will unlock door' | translate}}:<br> {{content.name | textSizeFilterPipe : 22}}.</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
              </div> -->
              <div *ngSwitchCase="'cardholder'">
                  <h2 >{{'Put a card on the card reader' | translate}}</h2>
                  <div style="font-size: small;">({{"Please don't remove the card from the encoder while this window is open" | translate}})</div>
                  <div>
                    <ion-button *ngIf="guestCard && VIRTUAL_CARD" size="small" (click)="createVirtualCard()">Create virtual</ion-button>
                  </div>

              </div>
              <div *ngSwitchCase="'cardExist'">
                  <h2 >{{'Card already in use' | translate}}</h2>
              </div>

              <div *ngSwitchCase="'levelFourAlarm'" style="margin: 5px;">
                <!-- <h2 style="color: black;">{{content | translate}}</h2> -->
                <h2 style="color: black;">{{'Multiple consequent burglaries in:' | translate}}</h2>
              </div>
              <div *ngSwitchDefault>
                  <h2 >{{'You will delete a' | translate}} {{type}}.</h2>
              </div>
          </div>
          <h3 *ngIf="type !== 'cardholder' && type !== 'noassigned' && type !== 'haveassigned' && type !== 'userlistcantdelete' && type !== 'cardExist'  && type !== 'levelFourAlarm'">{{'Are you sure' | translate}}?</h3>
        </div>
       <div *ngIf="loading && type !== 'roomSwap' && type !== 'levelFourAlarm'" class="flex-row-center-full">
        <ion-spinner name="lines-sharp"></ion-spinner>
      </div>
      <div *ngIf="!loading && type !== 'roomSwap' && type !== 'levelFourAlarm'" class="flex-row-center-full" style="visibility: hidden;">
        <ion-spinner name="lines-sharp"></ion-spinner>
      </div>

      <div *ngIf="type === 'cardExist'" class="flex-row margin10">
        <div *ngFor="let room of burgRooms.slice(0, 20)">
          <div class="room">{{'Room ' + room.name}}</div>
        </div>
        <div *ngIf="burgRooms.length > 20" class="margin-left10 font-size16"> + {{burgRooms.length - 20}} more</div>
      </div>
      <div [ngClass]="{'modal-button-box': type !== 'levelFourAlarm',
                       'modal-button-box-alarm': type === 'levelFourAlarm'}" class="margin-bottom5">
          <ion-button size="small" *ngIf="type !== 'haveassigned' && type !== 'userlistcantdelete' && type !== 'cardExist'  && type !== 'levelFourAlarm'" (click)="decisionClick(false)">{{'Cancel' | translate}}</ion-button>
          <ion-button size="small" *ngIf="type === 'haveassigned' || type === 'userlistcantdelete' || type === 'cardExist'" (click)="decisionClick(false)">Ok</ion-button>
          <ion-button size="small" *ngIf="type === 'roomSwap' || type === 'levelFourAlarm'" (click)="decisionClick(true)">OK</ion-button>
      </div>

  </div>


</div>
