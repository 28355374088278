import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getLogTemperatureProps'
})
export class GetLogTemperaturePropsPipe implements PipeTransform {

  transform(controller: Controller,checkForHidden = false, searchValue = ''): Property [] {
    const tempValueProps = controller.controllerProperties.$values.filter((prop: Property)=> {
      return !(checkForHidden && prop.hidden)
      && (prop.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      && Property.isTemperatureProperty(prop)
      && prop.logValue
    });
    return tempValueProps

  }

}
