import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { RoomTagPopoverComponent } from 'src/app/shared/components/room-tag-popover/room-tag-popover.component';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';
import { IPropDictionary, PropDictionaryService } from 'src/app/shared/services/prop-dictionary.service';
import { FORBID_HEAT_COOL_CHANGE } from 'src/environments/environment';

@Component({
  selector: 'app-hvac-common-area-modal-view',
  templateUrl: './hvac-common-area-modal-view.component.html',
  styleUrls: ['./hvac-common-area-modal-view.component.scss']
})
export class HvacCommonAreaModalViewComponent implements OnInit, OnDestroy {
  @Input() location: Location;
  controllers: Controller[];
  allTags: Tag[] = []


  isRoomTemperatureCurrent = Property.isRoomTemperatureCurrent;
  isRoomTemperatureSet = Property.isRoomTemperatureSet;
  isHvacHeatCool = Property.isHvacHeatCool
  isHvacHeatCoolAuto = Property.isHvacHeatCoolAuto
  isHVACLargeTempDiffRoom = Property.isHVACLargeTempDiffRoom;
  isHVACLargeTempDiffBathroom = Property.isHVACLargeTempDiffBathroom;


  isHvacActive = Property.isHvacActive;
  isFancoilV1 = Property.isFancoilV1;
  isFancoilV2 = Property.isFancoilV2;
  isFancoilV3 = Property.isFancoilV3;

  isCommunicationError = Property.isCommunicationError;
  isWindowOpened = Property.isWindowOpened;
  isWindowOpenTooLongAlarm = Property.isWindowOpenTooLongAlarm;

  SoftwarePermissionId = SoftwarePermissionId
  signedInUser: User;
  Property = Property

  daikinUnitStatusDictionary: IPropDictionary = this.propDictionaryService.daikinUnitStatusDictionary
  daikinUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.daikinUnitFanSpeedDictionary
  daikinUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.daikinUnitOperationModeDictionary

  lgUnitStatusDictionary: IPropDictionary = this.propDictionaryService.lgUnitStatusDictionary
  lgUnitFanSpeedDictionary: IPropDictionary = this.propDictionaryService.lgUnitFanSpeedDictionary
  lgUnitOperationModeDictionary: IPropDictionary = this.propDictionaryService.lgUnitOperationModeDictionary

  FORBID_HEAT_COOL_CHANGE = FORBID_HEAT_COOL_CHANGE;
  toggleHvacExist = false;
  stopControllerPolling: boolean = false;
  controllerPollingSubscription: Subscription;
  
  constructor(
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService,
    private propDictionaryService: PropDictionaryService,
    private pipe: CustomTranslatePipe
    ) { }

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.apiProjectService.getAllTags().subscribe( value => {
      this.allTags = value;
    })
    this.isControllerPolled()
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
    this.toggleHvacExist = this.isPropertyInController(Property.isHvacToggle)
  }

  clearStatus() {
    // to clear status of /safety/sos,   /security/burglary, and  /security/safe/burglary,
    //  send any true value to any of those endpoints
    let commError: string;
    let commErrorid: number;
    let commErrorValue;


    this.controllers.forEach((controller: Controller) => {
      controller.controllerProperties.$values.forEach((prop)=> {
        if(Property.isCommunicationError(prop)) {
          commError = controller.designation;
          commErrorid = prop.id;
          commErrorValue = prop.value;
        }
      })
      }
    );

      if(Number(commErrorValue)) {
        this.apiProjectService.changeProperty(commError, commErrorid, 1).subscribe();
      }
  }

  setHvacMode(hvacModeNewValue: 0 | 1 | 2) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, hvacModeNewValue)
      .subscribe();
  }

  
  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.controllers[0].id).subscribe()
  }

  toggleHvac() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacToggle);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, 1)
      .subscribe();
  }

  isPropertyInController(target: any) {
    let property: Property;
    const active: boolean = this.controllers.some(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(target);
        return property;
      }
    );

    return active
  }

  getHvacActiveMode() {
    const target = this.controllers[0].controllerProperties.$values.find(prop => {
      if (Property.fancoilMode(prop) && typeof (+prop.value) === 'number'  && prop.value !== '') {
        return true;
      }
    })

    if(+target.value === 0) {
      return 'i-hvac-off';
    } else if (+target.value === 1) {
      return 'icon-hvac-low font-size15';
    } else if(+target.value === 2) {
      return 'icon-hvac-mid font-size15';
    } else if (+target.value === 3) {
      return 'icon-hvac-high font-size15';
    } else if (+target.value === 6) {
      return 'i-hvac-auto'
    }
  }

  getAlarmName(prop: Property) {
    if (Property.isBurglary(prop)) {
      return this.pipe.transform('Burglary detected')
    } else if (Property.isSafeCommError(prop)) {
      return this.pipe.transform('Safe comm error')
    }
    else if (Property.isHVACLargeTempDiffRoom(prop)) {
      return this.pipe.transform('Large temp. diff. room')
    }
    else if (Property.isHVACLargeTempDiffBathroom(prop)) {
      return this.pipe.transform('Large temp. diff. bathroom' )
    }
    else if (Property.isFlood(prop)) {
      return this.pipe.transform('Flood')
    }
    else if (Property.isRoomBurglaryPIR(prop)) {
      return this.pipe.transform('Burglary detected by PIR')
    }
    else if (Property.isBurglaryDetectedWindow(prop)) {
      return this.pipe.transform('Burglary detected on win.')
    }
    else if (Property.isBurglaryDetectedDoor(prop)) {
      return this.pipe.transform('Burglary detected on door')
    }
     else if (Property.isSOS(prop)) {
      return this.pipe.transform('SOS')
    }
    else if (Property.isDoorOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Door open too long')
    }
    else if (Property.isWindowOpenTooLongAlarm(prop)) {
      return this.pipe.transform('Win. open too long')
    }
    else if (Property.isDoorLockBatteryLow(prop)) {
      return this.pipe.transform('Door lock bat. low')
    }
    else if (Property.isLowWindowOpenTooLong(prop)) {
      return this.pipe.transform('Low win. open too long')
    }
    else if (Property.isSafeBurglary(prop)) {
      return this.pipe.transform('Safe burglary detected')
    }
  }

  async editControllerTags(ev) {
    const popover = await this.popoverController.create({
      component: RoomTagPopoverComponent,
      cssClass: 'tags-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        location: this.location,
        existingTags: this.allTags
      }
    });
    await popover.present();

    popover.onDidDismiss().then( () => {
    })
  }

  ngOnDestroy(){
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }

  }

}
