import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValidFromToCss'
})
export class GetValidFromToCssPipe implements PipeTransform {

  transform(vldFrm) {
    const validFrom = new Date(vldFrm)
    const today = new Date()
    if (validFrom > today) {
      return true
    } else {
      return false
    }
  }

}
