import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-display-dictionary-prop-in-room-overview',
  templateUrl: './display-dictionary-prop-in-room-overview.component.html',
  styleUrls: ['./display-dictionary-prop-in-room-overview.component.scss']
})
export class DisplayDictionaryPropInRoomOverviewComponent  implements OnInit, OnDestroy  {
  @Input({required:true}) dictionary;
  @Input({required:true}) prop: Property;
  @Input({required:true}) displaySize;
  @Input() icon = 'icon-temp';

  updatedSub: Subscription;
  updated;
  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.updatedSub = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

  ngOnDestroy () {
    if (this.updatedSub) {
      this.updatedSub.unsubscribe();
    }
  }
}
