import { Injectable } from "@angular/core";
import { Property } from "src/app/core/models/project/property.model";

@Injectable({
    providedIn: 'root'
})
export class PropertyCopyService {

    savedProperty: Property;

    constructor() { }


    saveProperty(prop: Property) {
        this.savedProperty = prop;
    }

    getSavedProperty() {
        return this.savedProperty;
    }

}
