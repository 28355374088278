import { Injectable } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { pairwise, filter } from "rxjs/operators";



@Injectable({
    providedIn: 'root'
  })
export class UrlService {

    constructor(private router: Router) {}

    lastUrl: string;
    currentUrl: string

    startUrlChangeDetection() {
        this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
        this.lastUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
          // console.log('last url', events[0].urlAfterRedirects);
          // console.log('current url', events[1].urlAfterRedirects);
        });
    }
}