<div class="content-header flex-row">
  <div class="icon-back_arrow clickable font-size24 margin-right25" routerLink="/settings"></div>
  <h1 >{{'Audit Logs' | translate}}</h1>
  <div *ngIf="loading" class="loading-indicator"><div></div><div></div><div></div><div></div></div>
</div>

<div class="content-container">
  <div class="flex-row">
    <app-filter
      class="margin-right50"
      [inputDelay]="1500"
      (filteredValue)="searchFilterReady($event)" >
    </app-filter>

    <app-date-time-from-to
      class="margin-right50"
      (selectedDateTimeFromTo)="setDateTimeFromTo($event)">
    </app-date-time-from-to>

    <!-- <span class="icon-three-vert-dots font-size24 margin-top4 tooltip " (click)="showAdditionalOptions=!showAdditionalOptions">
      <span class="bottom">{{'Additional options' | translate}}</span>
    </span> -->

    <app-paginator
      *ngIf="auditLogsData.length > 0"
      class="to-right"
      [paginationData]="paginationData"
      (targetPage)="onSubmit($event)">
    </app-paginator>
  </div>

  <div *ngIf="showAdditionalOptions" class="flex-row margin-bottom10">

  <input class="margin-top10 option-input-sm"
    type="checkbox"
    id="showArchive"
    name="showArchive"
    [(ngModel)]="queryParams.includeArchive"
    (change)="onSubmit()">
    <label class="margin-top15" for="showArchive"> {{'Include archived logs' | translate}}</label>
  </div>

  <div  *ngIf="auditLogsData.length === 0 && !loading">{{'No results found' | translate}}.</div>

  <div  class="margin-top30" *ngIf="auditLogsData.length > 0">
      <table class="logs-table">
          <thead>
              <tr>
              <th class="clickable width20" id="TIME" (click)="onSort($event.currentTarget.id)">
                {{'Timestamp' | translate}}
                {{this.requestBody.sorting==='ASC|TIME'?"▲":""}}
                {{this.requestBody.sorting==='DSC|TIME'?"▼":""}}
              </th>

              <th class="width40">{{'Data' | translate}}</th>
              <th class="clickable width20" id="USER" (click)="onSort($event.currentTarget.id)">
                {{'User' | translate}}
                {{this.requestBody.sorting==='ASC|USER'?"▲":""}}
                {{this.requestBody.sorting==='DSC|USER'?"▼":""}}
              </th>
              <th class="clickable width20" id="IP" (click)="onSort($event.currentTarget.id)">
                {{'IP Address' | translate}}
                {{this.requestBody.sorting==='ASC|IP'?"▲":""}}
                {{this.requestBody.sorting==='DSC|IP'?"▼":""}}
              </th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let log of auditLogsData">
                <td class=" ">{{ log.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}</td>
                <td class=" ">{{ log.data }}</td>
                <td class=" ">
                  <div class="text-tooltip">
                    <p>{{log.userFullName | textSizeFilterPipe : 15}}</p>
                  <span class="bottom" *ngIf="log.userFullName.length > 15">{{log.userFullName}}</span>
                </div>
                </td>
                <td class=" ">{{ log.ipAddress}}</td>
              </tr>
          </tbody>
      </table>

      <app-paginator class="flex-row from-back margin-top20 margin-bottom20"
          [paginationData]="paginationData"
          (targetPage)="onSubmit($event)">
      </app-paginator>

  </div>
</div>


