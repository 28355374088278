import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputName'
})
export class GetRelayOutputNamePipe implements PipeTransform {

  transform(controllers: Controller[], outputStatusNumber: string): string {

      const byRentedProps = [];
      const forcedOnProps = [];
      const forcedOffProps = [];

      controllers.forEach((cont)=> {
        cont.controllerProperties.$values.forEach((prop)=> {
          if (!prop.mqttTopic.endsWith(outputStatusNumber)) {
            return
           }

          if (Property.isRelayOutputByRentedOrPresence(prop) ) {
            byRentedProps.push(prop)
          } else if (Property.isRelayOutputForceOn(prop) ) {
            forcedOnProps.push(prop)
          } else if (Property.isRelayOutputForceOff(prop) ) {
            forcedOffProps.push(prop)
          } else if (Property.isRelayOutputByRentedOrPresence(prop) ) {
          }
        })
      })

      if (forcedOnProps.length>0) {
        return forcedOnProps[0].name.split('|')[0]
      } else if (forcedOffProps.length>0) {
        return forcedOffProps[0].name.split('|')[0]
      }else if (byRentedProps.length>0) {
        return byRentedProps[0].name.split('|')[0]
      }
  }

}
