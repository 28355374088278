import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-guest-stay-days-input',
  templateUrl: './guest-stay-days-input.component.html',
  styleUrls: ['./guest-stay-days-input.component.scss']
})
export class GuestStayDaysInputComponent implements OnInit {

  stayDaysForm: UntypedFormGroup;
  submitted = false

  constructor(private popoverController: PopoverController,
              private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.stayDaysForm = this.formBuilder.group({
        stayDays: [null , [Validators.required,  Validators.pattern("^[0-9]*$")]]
      });
    }, 150);
  }

  onCancel() {
    this.popoverController.dismiss(null)
  }

  onConfirm() {
    this.submitted = true;
    if (this.stayDaysForm.valid) {
      this.popoverController.dismiss(this.stayDaysForm.get('stayDays').value)
    }
  }
}
