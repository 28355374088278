import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-room-log-paginator',
  templateUrl: './room-log-paginator.component.html',
  styleUrls: ['./room-log-paginator.component.scss']
})
export class RoomLogPaginatorComponent implements OnInit {

  pageNumberSubject$ = new Subject<number>();
  pageNumberSubscription: Subscription;
  selectedPage: number = 1;
  lastPage: number;

  @Output() targetPage: EventEmitter<number> = new EventEmitter<number>();
  @Input() paginationData: any[]

  constructor() {
  }

  ngOnInit(): void {
    this.lastPage = Math.ceil(this.paginationData.length / 50);
    this.pageNumberSubscription = this.pageNumberSubject$.subscribe(page  => {
          this.targetPage.emit(page);
        });
    }

  onNavigate(targetPage?: number){
    this.selectedPage = targetPage;
    this.pageNumberSubject$.next(targetPage);
  }

  getPageNumber(value: number) {
    return Math.ceil((value / 50));
  }

  ngOnDestroy() {
    if (this.pageNumberSubscription) {
      this.pageNumberSubscription.unsubscribe();
    }
  }

}
