    <div class="modal-header">
      <h3>{{ 'select_days' | translate }}</h3>
    </div>
    <div class="modal-content">
      <div class="days-grid-container">
        <div
          *ngFor="let num of dayNumbers"
          class="single-grid-day-container"
          [ngClass]="[
            (this.selectedDays | includes : num) ? 'icon-selected' : 'icon-not-selected'
          ]"
          (click)="onClickDay(num)"
        >
          {{ num }}
        </div>
      </div>
    </div>

    <div class="modal-button-box">
      <ion-button size="default" class="square-secondary margin-right5" color="ocean-blue" (click)="decisionClick(false)">{{ 'cancel' | translate }}</ion-button>
      <ion-button size="default" class="square-secondary" (click)="decisionClick(true)" color="ocean-blue">{{ 'save' | translate }}</ion-button>
    </div>

