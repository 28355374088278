import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationGroup } from './api-location-groups.service';

@Injectable({
  providedIn: 'root'
})
export class LocationGroupsService {
  private locationGroups$ = new BehaviorSubject<LocationGroup[]>(null);
  private floorList$ = new BehaviorSubject<LocationGroup[]>(null);

  constructor() { }


  getLocationGroups() {
    return this.locationGroups$.asObservable();
  }

  setLocationGroups(locations: LocationGroup[]) {
    this.locationGroups$.next(locations);
  }

  getFloorList() {
    return this.floorList$.asObservable();
  }

  setFloorList(floorList: LocationGroup[]) {
    this.floorList$.next(floorList);
  }

  getLocationGroup(locGroupId: string): LocationGroup {
    return this.locationGroups$.getValue().find((locGroup)=> {
      return locGroup.id.toString() === locGroupId.toString();
    })
  }

}
