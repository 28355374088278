import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { API_BASE_URL } from 'src/environments/environment';
import { AlarmSubscriptionsService } from '../alarm-subscriptions.service';
import { AlarmNotifyType } from 'src/app/core/models/alarms/alarm-notify-type.model';
import { AlarmNotify } from 'src/app/core/models/alarms/alarm-notify.model';
import { AlarmAlertsService } from '../alarm-alerts.service';
import { Alarms } from 'src/app/core/models/alarms.model';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationData } from 'src/app/core/models/pagination-data.model';

@Injectable({
    providedIn: 'root'
})
export class ApiAlarmsService {

    headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    
    constructor(private http: HttpClient,
         private alarmSubscriptionsService: AlarmSubscriptionsService,
         private alarmAlertsService: AlarmAlertsService) {
    }

    getAlarmTypes() {
        return this.http.get<AlarmType[]>(API_BASE_URL + '/alarms/types').pipe(
            tap(alarmTypes => {
                this.alarmSubscriptionsService.setAlarmTypes(alarmTypes);
            }),
        );
    }

    getAlarmNotifyTypes() {
        return this.http.get<AlarmNotifyType[]>(API_BASE_URL + '/alarms/notifytypes').pipe(
            map(alarmNotifyTypes => {
                this.alarmSubscriptionsService.setAlarmNotifyTypes(alarmNotifyTypes);
                return alarmNotifyTypes;
            }),
        );
    }

    getAlarmSubscriptions() {
        return this.http.get<AlarmNotify[]>(API_BASE_URL + '/alarms/notify').pipe(
            map(alarmSubscriptions => {
                this.alarmSubscriptionsService.setAlarmSubscriptions(alarmSubscriptions);
                return alarmSubscriptions;
            }),
        );
    }

    getAlarmSubscription(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<AlarmNotify>(API_BASE_URL + '/alarms/notify/byId', {params}).pipe(
            map(alarmSubscription => {
                this.alarmSubscriptionsService.updateAlarmSubscription(alarmSubscription);
                return alarmSubscription;
            }),
        );
    }

    addAlarmSubscription(newAlarmSubscription: AlarmNotify) {
        let params = new HttpParams()
            .set('typeId', newAlarmSubscription.alarmTypeId.toString())
            .set('notificationTypeId', newAlarmSubscription.alarmNotifyTypeId.toString())
            .set('destination', newAlarmSubscription.destination);
        return this.http.post<AlarmNotify>(API_BASE_URL + '/alarms/notify', null, {params}).pipe(
            map(receivedAlarmSub => {
                this.alarmSubscriptionsService.addAlarmSubscription(receivedAlarmSub);
                return receivedAlarmSub;
            }),
        );
    }

    updateAlarmSubscription(updatedAlarmSubscription: AlarmNotify) {
        return this.http.put<AlarmNotify>(API_BASE_URL + '/alarms/notify', updatedAlarmSubscription).pipe(
            map(receivedAlarmSub => {
                this.alarmSubscriptionsService.updateAlarmSubscription(receivedAlarmSub);
                return receivedAlarmSub;
            }),
        );
    }

    deleteAlarmSubscrption(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.delete(API_BASE_URL + '/alarms/notify', {params}).pipe(
            map(() => {
                this.alarmSubscriptionsService.deleteAlarmSubscription(id);
                return;
            }),
        );
    }

    getUnconfirmedAlarms(requestBody: Alarms, queryParams) {
        const params = new HttpParams()
        .set('pagenumber', queryParams.pageNumber.toString())
        .set('pagesize', queryParams.pageSize.toString());
        return this.http.post<AlarmAlert[]>(API_BASE_URL + '/alarms/unconfirmed', requestBody,
        {
            params: params,
            headers: this.headers,
            observe: 'response'
        }).pipe(
            map( alarms => {
                const paginationData: PaginationData = JSON.parse(alarms.headers.get('X-Pagination')); 
                this.alarmAlertsService.setUnconfirmedAlarmsNumber(paginationData.TotalCount)
                this.alarmAlertsService.setAlarms(alarms.body);
                return alarms;
            })
        );
    }

    confirmAlarm(alarmId: number, text: string,  userId: number, requestBody: Alarms, queryParams) {
        return this.http.post<any>(API_BASE_URL + '/alarms/confirm', {AlarmLogId: alarmId, Text: text, confirmUserId: userId}).pipe(
            map( resp => {
                this.alarmAlertsService.confirmAlarm(alarmId);
                this.getUnconfirmedAlarms(requestBody, queryParams).subscribe();
                return resp;
            })
        );
    }

    confirmAllAlarms(text: string, userId: number ) {
        return this.http.post<any>(API_BASE_URL + '/alarms/confirmall', {Text: text, confirmUserId: userId}).pipe(
            map( resp => {
                this.alarmAlertsService.setAlarms([]);
                this.alarmAlertsService.setUnconfirmedAlarmsNumber(0)
                return resp;
            })
        );
    }


}
