import { Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { FindPropertyPipe } from '../../pipes/find-property.pipe';

@Component({
  selector: 'app-modal-view-mutex-boolean-prop',
  templateUrl: './modal-view-mutex-boolean-prop.component.html',
  styleUrls: ['./modal-view-mutex-boolean-prop.component.scss'],
  providers: [FindPropertyPipe]
})
export class ModalViewMutexBooleanPropComponent implements OnInit, DoCheck {

  @Input() controllers: Controller[];
  @Input() mutexPropsFunc: ((property: Property) => boolean)[];
  @Input() icon: string;
  @Input() text: string;
  activeProp: Property;
  // activeProps : Property[] = [];
  mutexProps : Property[] = [];
  activePropTextSufix: string;
  differ: KeyValueDiffer<string, Property>;
  propHidden = false;
  // allThreeFansActive = false;

  constructor(private findProperty: FindPropertyPipe) {}

  ngOnInit(): void {
    this.mutexPropsFunc.forEach( func => {
       this.mutexProps.push(...this.findProperty.transform(this.controllers, func , null, true))
    })
    this.mutexProps.sort((a,b)=> {return b.type-a.type})
    this.findActiveProp()
  }

  ngDoCheck() {
    this.findActiveProp()
  }

  findActiveProp() {
    this.activeProp = this.mutexProps.find(prop => Property.isActive(prop));
    const allThreeFansActive = this.mutexProps.filter((prop)=> {
      return Property.isFancoilV1(prop) || Property.isFancoilV2(prop) || Property.isFancoilV3(prop)
    }).every(prop => Property.isActive(prop));
    if(this.mutexProps.length == 0) {
      this.propHidden = true;
    }


    if (this.activeProp) {
      if (allThreeFansActive) {
          this.activePropTextSufix = 'Auto';
      } else if(Property.isFancoilVAuto(this.activeProp)) {
        this.activePropTextSufix = 'Auto';
      }else if(Property.isFancoilV3(this.activeProp)) {
        this.activePropTextSufix = 'V3';
      } else if (Property.isFancoilV2(this.activeProp)) {
        this.activePropTextSufix = 'V2';
      } else if (Property.isFancoilV1(this.activeProp)) {
        this.activePropTextSufix = 'V1';
      }
    }

  }


}
