import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroup } from 'src/app/shared/services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getControllersForRoomsOnFloor',
})

export class GetControllersForRoomsOnFloorPipe implements PipeTransform {

  transform(fl: LocationGroup , locations: Location[] ,  updated: Date )
    : Location[] {
    if (fl.locations.length === 0) {
      return [];
    }


    locations = locations.filter( location => {
      return fl.locations.some( loc => loc.locationId === location.locationId)
    })
    return locations
  }

}
