import { Component, OnInit , EventEmitter, Output, OnDestroy, ViewChild, Input} from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'app-date-time-from-to',
  templateUrl: './date-time-from-to.component.html',
  styleUrls: ['./date-time-from-to.component.scss']
})
export class DateTimeFromToComponent implements OnInit, OnDestroy {

  @ViewChild('dtFrom', { static: true }) dtFrom: OwlDateTimeComponent<any>;
  @ViewChild('dtTo', { static: true }) dtTo: OwlDateTimeComponent<any>;

  selectedDateTime: Date[] = [];
  @Output() selectedDateTimeFromTo: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  dateTimeSubject$ = new Subject<Date[]>();
  dateTimeSubscription: Subscription;

  toDT: Date;
  fromDT: Date;

  constructor() { }

  ngOnInit(): void {
    this.dateTimeSubscription = this.dateTimeSubject$.subscribe(value => {
      this.selectedDateTimeFromTo.emit(value);
    });
  }

  dateTimeInput(ev: any) {
    const date = ev.input.value.replaceAll('.', '').split(' ');
    let hours;

    if (date[3]) {
      hours = date[3].split(':')
    }
    if (ev.source._selectMode == 'rangeFrom') {
      this.fromDT = new Date()
      if (date[0]) {
        this.fromDT.setDate(date[0])
      }
      if (date[1]) {
        this.fromDT.setMonth(date[1]-1)
      }
      if (date[2]) {
        this.fromDT.setFullYear(date[2])
      }
      if(hours && hours[0]) {
        this.fromDT.setHours(hours[0])
        this.fromDT.setMinutes(0)
      }
      if(hours && hours[1]) {
        this.fromDT.setMinutes(hours[1])
      }

      if(this.fromDT instanceof Date && !isNaN(this.fromDT.valueOf())) {
        this.selectedDateTime[0] = this.fromDT;
      } else {
        this.selectedDateTime[0] = new Date()
      }
    }
    else if (ev.source._selectMode == 'rangeTo') {
      this.toDT = new Date()
      if (date[0]) {
        this.toDT.setDate(date[0])
      }
      if (date[1]) {
        this.toDT.setMonth(date[1]-1)
      }
      if (date[2]) {
        this.toDT.setFullYear(date[2])
      }
      if(hours && hours[0]) {
        this.toDT.setHours(hours[0])
        this.toDT.setMinutes(0)
      }
      if(hours && hours[1]) {
        this.toDT.setMinutes(hours[1])
      }

      if(this.toDT instanceof Date && !isNaN(this.toDT.valueOf())) {
        this.selectedDateTime[1] = this.toDT;
      } else {
        this.selectedDateTime[1] = new Date()
      }
    }
    this.dateTimeSubject$.next([this.fromDT, this.toDT]);
  }

  onClearDateTime(index: number) {
    this.selectedDateTime[index] = undefined;
    this.selectedDateTime = this.selectedDateTime.slice();
    this.dateTimeSubject$.next(this.selectedDateTime);
  }


  onCloseFrom() {
    this.selectedDateTime = [this.dtFrom.selecteds[0], this.dtFrom.selecteds[1]];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  onCloseTo() {
    this.selectedDateTime = [this.dtTo.selecteds[0], this.dtTo.selecteds[1]];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast24hours() {
    this.selectedDateTime = [ new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast7days() {
    this.selectedDateTime = [ new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  public showLast30days() {
    this.selectedDateTime = [ new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), new Date()];
    this.dateTimeSubject$.next(this.selectedDateTime);
  }

  ngOnDestroy() {
    if (this.dateTimeSubscription) {
      this.dateTimeSubscription.unsubscribe();
    }
  }

}
