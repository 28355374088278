import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'roomHaveBathroom'
})
export class RoomHaveBathroomPipe implements PipeTransform {

  transform(controllers: Controller[]): boolean
    {
        const bathroomBaseSetProp: Property =  controllers[0].controllerProperties.$values.find(Property.HVACBathroomBaseSet);
        const bathroomEcoHyst1Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst1);
        const bathroomEcoHyst2Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst2);

        if (bathroomBaseSetProp || bathroomEcoHyst1Prop || bathroomEcoHyst2Prop) {
            return true;
        } else {
            return false;
        }

    }
}
