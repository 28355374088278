import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getActiveProps'
})
export class GetActivePropsPipe implements PipeTransform {
  transform(properties: Property[]): Property[] {
    return properties.filter((prop)=> {
      return Property.isActive(prop)
    })
  }
}
