
    <div class="log-title backgroundBlue flex-row-center-h">
      {{'Room log' | translate}}
    </div>

    <div class="flex-row flex-row-space-evenly margin-left20 margin-top10 margin-right20">
        <ion-item class="width100">
          <ion-segment [(ngModel)]="activeCard"  (ngModelChange)="cardClicked($event)" >

            <ion-segment-button class="width50" value="alarm">
              <ion-label class="ion-text-wrap">{{'Alarm log' | translate}}</ion-label>
            </ion-segment-button>
            <ion-segment-button class="width50" value="valueLog">
              <ion-label class="ion-text-wrap">{{'Value log' | translate}}</ion-label>
            </ion-segment-button>
          </ion-segment>
      </ion-item>
      </div>

    <div *ngIf="activeCard === 'alarm'">
        <app-alarm-logs [controllers]="controllers" [singleRoom]="true"></app-alarm-logs>
    </div>

    <div class="content-container" *ngIf="activeCard === 'valueLog'">
      <div class="flex-row-center-v gap5 margin-top10 margin-left20">
        <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
        <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
        <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
        <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | translate}}</ion-button>
        <div class="flex-row-center-v  margin-left10">
          <select [(ngModel)]="selectedController" (ngModelChange)="controllerChanged($event)">
            <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
            </select>
        </div>
        <ion-list class="width40 margin-left20">
          <ion-item>

            <ion-select *ngIf="showSelect" multiple="true" (ionChange)="selectChanged($event.detail.value)" [(ngModel)]="valueTableProperties" label="{{'Properties' | translate}}" (click)="simulateClick()" (ionCancel)="onDismiss()" [interfaceOptions]="{cssClass: 'ionic-custom-select'}">
              <ng-container *ngFor="let valueLogProp of selectedController | getLogValueProps: useHiddenFlag : searchValue">
               <!--  <ng-container *ngFor="let valueLogProp of valueLogProperties">
 -->                <ion-select-option [value]="valueLogProp.type.toString()">{{valueLogProp.name | translate}}</ion-select-option>

              <!--   <ion-select-option *ngIf="selectedController | propExistInController : valueLogProp.propFunction : true : searchValue : propsHr" [value]="valueLogProp.propFunction" >{{valueLogProp.name | translate}}</ion-select-option> -->
              </ng-container>
            </ion-select>
          </ion-item>
        </ion-list>
        <ion-checkbox color="primary" (ionChange)="onAlarmLogClick($event)">{{'Alarm log' | translate}}</ion-checkbox>
        <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [hidden]="hideCustomTime"></app-date-time-from-to>
      </div>
      <div *ngIf="(tableCreated && valueTableProperties.length > 0) || ((giveAccess || giveAlarm) && !logsLoading)">
        <div class="flex-row-center-h margin-top20 width100">
            <table>
              <tr>
                <th class="   ">{{'Time recorded' | translate}}</th>
                <th *ngIf="tableCreated && valueTableProperties.length > 0" class=" ">{{'Property' | translate}}</th>
                <th *ngIf="tableCreated && valueTableProperties.length > 0" class=" ">{{'Value' | translate}}</th>
                <th *ngIf="giveAccess" class=" ">{{'User' | translate}}</th>
                <th *ngIf="giveAccess" class=" ">{{'Location' | translate}}</th>
                <th *ngIf="giveAlarm" class=" ">{{'Level' | translate}}</th>
                <th *ngIf="giveAlarm" class=" ">{{'Description' | translate}}</th>
              </tr>
                <tr *ngFor="let data of tableData; let i = index" class="br-gray-b">
                  <td *ngIf="showDataAtPage(i)" class="   ">
                   {{ data.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}
                  </td>
                  <td  *ngIf="tableCreated && valueTableProperties.length > 0 && showDataAtPage(i)" class=" ">
                    {{ getPropertyName(data.propertyType) }}
                  </td>
                  <td *ngIf="tableCreated && valueTableProperties.length > 0 && showDataAtPage(i)" class=" ">
                    {{ data.value}}
                  </td>
                  <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                    {{ data.user}}
                  </td>
                  <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                    {{ data.locationName}}
                  </td>
                  <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                    {{ data.level}}
                  </td>
                  <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                    {{ data.description}}
                  </td>
                </tr>
            </table>
          </div>
          <div class="flex-row width95 margin-top5">
            <app-room-log-paginator
              class="to-right"
              [paginationData]="tableData"
              (targetPage)="onPageChange($event)">
            </app-room-log-paginator>
          </div>
      </div>
    <div *ngIf="(!tableCreated && valueTableProperties.length > 0) || logsLoading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>
    </div>

