import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'showSubobject'
})
export class ShowSubobjectPipe implements PipeTransform {
  constructor() {}

    transform(locations: Location[], floorList: LocationGroup[] ): boolean {
      if (floorList.length === 0) {
       return false;
     }

     const subObjectHasRoomsOnAnyFloor =  floorList.some((floor)=> {
      return locations.some((loc)=> {
        return floor.locations.some(value => value.locationId === loc.locationId)
      })

     });
     return subObjectHasRoomsOnAnyFloor;
   }

}
