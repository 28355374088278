import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AssignedPermission } from "src/app/core/models/permissions/assigned-permssion.model";
import { Permission } from "src/app/core/models/permissions/permission.model";
import { Role } from "src/app/core/models/role.model";
import { User } from "src/app/core/models/user/user.model";
import { API_BASE_URL } from "src/environments/environment";
import { RolesService } from "../roles.service";

@Injectable({
    providedIn: 'root'
})
export class ApiRolesService {

    constructor (private http: HttpClient,
                private rolesService: RolesService) {}

    getRoles() {
        return this.http.get<Role[]>(API_BASE_URL + '/roles').pipe(
            map(roles => {
                this.rolesService.setRoles(roles);
                return roles;
            }),
        );
    }

    getRole(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<Role>(API_BASE_URL + '/roles/byId', {params}).pipe(
            map(role => {
                this.rolesService.updateRole(role);
                return role;
            }),
        );
    }

    addRole(role: Role) {
        return this.http.post<Role>(API_BASE_URL + '/roles', role).pipe(
            map(roleCreated => {
                this.rolesService.addRole(roleCreated);
                return roleCreated;
            }),
        );
    }

    updateRole(role: Role) {
        return this.http.put(API_BASE_URL + '/roles', role).pipe(
            map(() => {
                this.rolesService.updateRole(role);
                return;
            }),
        );
    }

    addUserToRole(userId: number, roleId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('roleId', roleId.toString());
        return this.http.post(`${API_BASE_URL}/users/toRole`, null, {params});
      }

      removeUserFromRole(userId: number, roleId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('roleId', roleId.toString());
        return this.http.post(`${API_BASE_URL}/users/fromRole`, null, {params});
      }

    deleteRole(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.delete(API_BASE_URL + '/roles', {params}).pipe(
            map(() => {
                this.rolesService.deleteRole(id);
                return;
            }),
        );
    }

    getUsersInRole(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<User[]>(API_BASE_URL + '/roles/membersbyId', {params});
    }

    getPermissions() {
        return this.http.get<Permission[]>(API_BASE_URL + '/permissions').pipe(
            map(permissions => {
                this.rolesService.setPermissions(permissions);
                return permissions;
            }),
        );
    }

/*     revokeRoleAssignedDoorPermission(roleId: number, doorId: number) {
        const params = new HttpParams()
        .set('roleId', roleId.toString())
        .set('doorId', doorId.toString());
        return this.http.delete(API_BASE_URL + '/permissions/assigned/roledoor', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

   /*  getRoleAssignedDoorPermission(roleId: number, doorId: number) {
        const params = new HttpParams()
        .set('roleId', roleId.toString())
        .set('doorId', doorId.toString());
        return this.http.get<AssignedPermission[]>(API_BASE_URL + '/permissions/assigned/roledoor', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

    /* getRoleAssignedDoorGroupPermission(roleId: number, doorGroupId: number) {
        const params = new HttpParams()
        .set('roleId', roleId.toString())
        .set('doorGroupId', doorGroupId.toString());
        return this.http.get<AssignedPermission[]>(API_BASE_URL + '/permissions/assigned/roledoorGroup', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

    /* revokeRoleAssignedDoorGroupPermission(roleId: number, doorGroupId: number) {
        const params = new HttpParams()
        .set('roleId', roleId.toString())
        .set('doorGroupId', doorGroupId.toString());
        return this.http.delete(API_BASE_URL + '/permissions/assigned/roledoorGroup', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */


   /*  getUserAssignedDoorPermission(userId: number, doorId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('doorId', doorId.toString());
        return this.http.get<AssignedPermission[]>(API_BASE_URL + '/permissions/assigned/userdoor', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

    /* revokeUserAssignedDoorPermission(userId: number, doorId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('doorId', doorId.toString());
        return this.http.delete(API_BASE_URL + '/permissions/assigned/userdoor', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

   /*  getUserAssignedDoorGroupPermission(userId: number, doorGroupId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('doorGroupId', doorGroupId.toString());
        return this.http.get<AssignedPermission[]>(API_BASE_URL + '/permissions/assigned/userdoorGroup', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

  /*   revokeUserAssignedDoorGroupPermission(userId: number, doorGroupId: number) {
        const params = new HttpParams()
        .set('userId', userId.toString())
        .set('doorGroupId', doorGroupId.toString());
        return this.http.delete(API_BASE_URL + '/permissions/assigned/userdoorGroup', {params}).pipe(
            map(response => {
                return response;
            }),
        );
    } */

}
