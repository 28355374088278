import { Pipe, PipeTransform } from '@angular/core';
import { SettingsPropData } from '../components/room-modal-settings/room-modal-settings.component';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propIsLoading'
})
export class PropIsLoadingPipe implements PipeTransform {

  transform(loadingElements: string[], propData: SettingsPropData, controllers: Controller[]): boolean {
    const parentController: Controller = controllers.find(
      (cont: Controller) => {
        return cont.controllerProperties.$values.some((prop: Property)=> {
          return (prop.type  >= propData.codeFrom) && (prop.type <= propData.codeTo)
        })
      }
    );
    const parentProp = parentController.controllerProperties.$values.find((prop: Property)=> {
      return (prop.type  >= propData.codeFrom) && (prop.type <= propData.codeTo)
    })
    const propIsLoading = loadingElements.includes(`${parentController.id}-${parentProp.id}`)
    return propIsLoading
  }


}


