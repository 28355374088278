import { Permission } from "./permissions/permission.model";

export class Role {
    roleId: number;
    name: string;
    parentRoleId: number;
    permissions: Permission[];
    canEdit: number;
    assignedBy?: number;
    cardTypeId: number;

    public static roleHasEveryPermission(role: Role, listOfPermissions: Permission[]): boolean {
      return listOfPermissions.every((perm)=> {
        return role?.permissions?.find((rolePerm)=> {
          return perm.permissionId === rolePerm.permissionId;
        })
      })
    }



}
