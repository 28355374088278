import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Role } from 'src/app/core/models/role.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';


@Injectable({
    providedIn: 'root'
})
export class RolesService {

    private roles: Role[] = [];
    rolesChanged = new Subject<Role[]>();
    private permissions: Permission[] = [];
    permissionsChanged = new Subject<Permission[]>();

    constructor() {}


    setRoles(roles: Role[]) {
        this.roles = roles.slice();
        this.rolesChanged.next(this.roles);
    }

    getRoles() {
        return this.roles.slice();
    }

    getRole(id: number) {
        if (!this.roles) {
            return undefined;
        }
        return this.roles.find(role => role.roleId === id);
    }

    updateRole(role: Role) {
        const index = this.roles.findIndex(roleToChange => roleToChange.roleId === role.roleId);
        if (index !== -1) {
            this.roles[index] = role;
            this.rolesChanged.next(this.roles);
        } else {
            this.addRole(role);
        }
    }

    addRole(role: Role) {
        this.roles.push(role);
        this.rolesChanged.next(this.roles);
    }

    deleteRole(id: number) {
        const index = this.roles.findIndex(role => role.roleId === id);
        this.roles.splice(index, 1);
        this.rolesChanged.next(this.roles);
    }

    setPermissions(permissions: Permission[]) {
        this.permissions = permissions.slice();
        this.permissionsChanged.next(this.permissions);
    }

    getPermissions() {
        return this.permissions.slice();
    }

    addPermission(permission: Permission) {
        this.permissions.push(permission);
        this.permissionsChanged.next(this.permissions);
    }
}
