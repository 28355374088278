import { Pipe, PipeTransform } from '@angular/core';
import { SettingsPropData } from '../components/room-modal-settings/room-modal-settings.component';

@Pipe({
  name: 'sortByAdminProps'
})
export class SortByAdminPropsPipe implements PipeTransform {

  transform(propData: SettingsPropData[]): SettingsPropData[] {
    return propData.sort((a, b) =>  {
      return Number(a.adminProp) - Number(b.adminProp)
    });
  }

}
