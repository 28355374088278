<div class="flex-row-space-evenly margin-top30">
  <div class="flex-row width90">
    <div class="flex-column margin10 width-150">
      <ion-list *ngIf="controllers.length > 1" >
        <ion-list-header>{{'Controllers' | translate}}</ion-list-header>
        <ion-item button *ngFor="let controller of controllers" (click)="selectController(controller)" [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }">
          {{ controller.name }}
        </ion-item>
      </ion-list>

      <div class="flex-column width12">
        <ion-list class="flex-row">
          <ion-list-header>{{ "Tags" | translate }}
            <ion-icon *ngIf="loggedInUser.havePermission(SoftwarePermissionId.LocationTags)"  class="margin-left15 clickable" name="create-outline" (click)="editControllerTags($event)"></ion-icon>
          </ion-list-header>
        </ion-list>
        <div *ngFor="let tag of location.locationTags.$values">
          {{tag.tag}}
        </div>
      </div>
    </div>

    <div class="flex-column width20">
      <div class="margin-bottom30">
        {{'LIGHTS' | translate}}</div>
        <ng-container *ngIf="selectedControllers | findProperty: isLight: null : true as lights">
          <div *ngFor="let light of lights" class="flex-row">
            <div class="status-container-small flex-row-center-v margin-bottom12 width230">
              <ng-container *ngIf="!(selectedControllers[0] | outputModeForLightIsControlPipe : light : isOutputMode : outputModesEnum.OutputControl);else lightButtons">
                <ng-container *ngIf="light | propIsActive; else propNotActive">
                  <div title="Light on" class="icon-background-on icon-welcome-light font-size19 yellow" ></div>
                </ng-container>
                <ng-template #propNotActive>
                  <div
                    title="Light off"
                    class="icon-background-on icon-welcome-light font-size19"
                  ></div>
                  <div class="status-text-off">{{ light.name }}</div>
                </ng-template>
              </ng-container>
              <ng-template #lightButtons>
                <ng-container *ngIf="light | propIsActive; else propNotActive">
                  <div title="Light on" class="icon-background-on icon-welcome-light font-size19 yellow"></div>
                  <div class="status-text-on">{{ light.name }}</div>
                </ng-container>
                <ng-template #propNotActive>
                  <div
                    title="Light off"
                    class="icon-background-on icon-welcome-light font-size19"
                  ></div>
                  <div class="status-text-off">{{ light.name }}</div>
                </ng-template>
              </ng-template>
            </div>
            <ion-button size="small" class="margin-left5">
              <ion-icon (click)="lightOnOff(light)" slot="icon-only" name="power-outline"></ion-icon>
            </ion-button>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedControllers | findProperty: isLuxomatBrodcast: null : true as luxomats">
          <div *ngFor="let luxomat of luxomats" class="status-container-small flex-row-center-v margin-bottom12 width230">
            <ng-container *ngIf="luxomat | propIsActive; else propNotActive">
              <div title="Luxomat on" class="icon-background-on icon-moon_and_sun font-size19 yellow"></div>
              <div class="status-text-on">{{ luxomat.name }}</div>
            </ng-container>
            <ng-template #propNotActive>
              <div
                title="Luxomat off"
                class="icon-background-on icon-moon_and_sun font-size19"
              ></div>
              <div class="status-text-off">{{ luxomat.name }}</div>
            </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedControllers | findProperty: isLuxomat: null : true as luxomats">
          <div *ngFor="let luxomat of luxomats" class="status-container-small flex-row-center-v margin-bottom12 width230">
            <ng-container *ngIf="luxomat | propIsActive; else propNotActive">
              <div title="Luxomat on" class="icon-background-on icon-moon_and_sun font-size19 yellow"></div>
              <div class="status-text-on">{{ luxomat.name }}</div>
            </ng-container>
            <ng-template #propNotActive>
              <div
                title="Luxomat off"
                class="icon-background-on icon-moon_and_sun font-size19"
              ></div>
              <div class="status-text-off">{{ luxomat.name }}</div>
            </ng-template>
          </div>
        </ng-container>
    </div>

    <div class="flex-column width65">
      <div  *ngIf="loggedInUser | userHasPermission : 8" class="
        full-minus-20 flex-stretch">
      <div class="flex-column">
        <ion-card-content class="width100">
            <ion-list class="flex-column-center-h">
              <ng-container *ngIf="controllers | findProperty: isOutputMode: null : true as outputModes">
                <div class="flex-row-center-v" *ngFor="let outputMode of outputModes">
                  <div class="flex-row-center-v">
                    <h2>{{ outputMode.name }}</h2>
                    <select class="custom-select margin-left10" [(ngModel)]="outputMode.value" (change)="modeSelected($event, outputMode)" >
                      <option [value]="''" disabled>Select output mode</option>
                      <option [value]="outputModesEnum.Off"> {{ getStatusName(outputModesEnum.Off) }}</option>
                      <option [value]="outputModesEnum.On"> {{ getStatusName(outputModesEnum.On) }}</option>
                      <option [value]="outputModesEnum.Luxomat"> {{ getStatusName(outputModesEnum.Luxomat) }}</option>
                      <option [value]="outputModesEnum.TimeInterval"> {{ getStatusName(outputModesEnum.TimeInterval) }}</option>
                      <option [value]="outputModesEnum.LuxomatAndTimeInterval"> {{ getStatusName(outputModesEnum.LuxomatAndTimeInterval) }}</option>
                      <option [value]="outputModesEnum.OutputControl"> {{ getStatusName(outputModesEnum.OutputControl) }}</option>
                      <option [value]="outputModesEnum.PIR"> {{ getStatusName(outputModesEnum.PIR) }}</option>
                      <option [value]="outputModesEnum.KeyboardIN1"> {{ getStatusName(outputModesEnum.KeyboardIN1) }}</option>
                      <option [value]="outputModesEnum.SwitchIN1"> {{ getStatusName(outputModesEnum.SwitchIN1) }}</option>
                      <option [value]="outputModesEnum.LuxomatAndPIR"> {{ getStatusName(outputModesEnum.LuxomatAndPIR) }}</option>
                      <option [value]="outputModesEnum.TimeIntervalAndPIR"> {{ getStatusName(outputModesEnum.TimeIntervalAndPIR) }}</option>
                    </select>
                    <div title="Copy" class="icon-copy font-size32 margin-left 10" (click)="copyProperty(outputMode)"></div>

                  </div>
                  <ng-container *ngIf="outputModeWithInterval(outputMode)">
                    <app-select-time-common-room
                    class="margin-left10 padding10"
                    (setTime$)="procesTimeObject($event)"
                    [denyFrom]="getDenyFromProperty(outputMode,isOutputStartTimeT1)"
                    [denyTo]="getDenyToProperty(outputMode,isOutputEndTimeT1)">
                    </app-select-time-common-room>
                    <app-select-time-common-room
                    class="margin-left10 padding10"
                    (setTime$)="procesTimeObject($event)"
                    [denyFrom]="getDenyFromProperty(outputMode,isOutputStartTimeT2)"
                    [denyTo]="getDenyToProperty(outputMode,isOutputEndTimeT2)">
                    </app-select-time-common-room>
                  </ng-container>

                </div>
                <div class="flex-row-center-v">
                  <ion-button
                  class="margin5"
                  color="light"
                  (click)="syncHvac()"
                  size="small"
                  ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
                  {{'Settings sync' | translate}}</ion-button>

                  <ion-item lines="none" class="item-background-color">
                    <ion-checkbox
                      (ionChange)="changeControllerPooling($event)"
                      justify="start"
                      labelPlacement="end"
                      class="margin-right5"
                      [checked]="stopControllerPolling"
                    >{{ "Stop controller polling" | translate }}</ion-checkbox>
                  </ion-item>
                </div>
              </ng-container>
            </ion-list>
        </ion-card-content>
      </div>
    </div>
    </div>
  </div>
</div>
