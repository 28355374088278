import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-modal-view-numeric-prop',
  templateUrl: './modal-view-numeric-prop.component.html',
  styleUrls: ['./modal-view-numeric-prop.component.scss']
})
export class ModalViewNumericPropComponent implements OnInit {
  @Input() controllers: Controller[];
  @Input() propFilterFunction: (property: Property) => boolean
  @Input() sufix = '°C';
  @Input() icon = 'icon-temp';
  @Input() forcePropIndex : number;

  // @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
