import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRoomTemperatureData'
})
export class GetRoomTemperatureDataPipe implements PipeTransform {

  transform(controllers: Controller[], location: 'room' | 'bathroom', updated: Date):
      {baseSet: number,  ecoHyst1: number, ecoHyst2: number, regulation?: number}
    {

    if (location === 'bathroom') {
      const bathroomBaseSetProp: Property =  controllers[0].controllerProperties.$values.find(Property.HVACBathroomBaseSet);
      const bathroomEcoHyst1Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst1);
      const bathroomEcoHyst2Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacBathroomEcoHyst2);

      return {
        baseSet: bathroomBaseSetProp? bathroomBaseSetProp.value : undefined,
        ecoHyst1: bathroomEcoHyst1Prop? bathroomEcoHyst1Prop.value : undefined,
        ecoHyst2: bathroomEcoHyst2Prop? bathroomEcoHyst2Prop.value : undefined,
      };
    } else {
      const roomBaseSetProp: Property =  controllers[0].controllerProperties.$values.find(Property.isHVACRoomBaseSet);
      const roomEcoHyst1Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRoomEcoHyst1);
      const roomEcoHyst2Prop: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRoomEcoHyst2);
      const roomHvacRegulationProp: Property =  controllers[0].controllerProperties.$values.find(Property.isHvacRegulation);

      return {
        baseSet: roomBaseSetProp? roomBaseSetProp.value : undefined,
        ecoHyst1: roomEcoHyst1Prop? roomEcoHyst1Prop.value : undefined,
        ecoHyst2: roomEcoHyst2Prop? roomEcoHyst2Prop.value : undefined,
        regulation: roomHvacRegulationProp? roomHvacRegulationProp.value : undefined
      };
    }
  }

}
