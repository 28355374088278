<div class="flex-row-center-full">
  <ion-spinner *ngIf="!tablesReady" class="margin-top100 flex-row-center-full" name="lines-sharp"></ion-spinner>
</div>

<div *ngIf="!roomSwap" class="content-container flex-row-space-evenly width90">
  <div class="width100 flex-row margin-left100 margin-top10">
    <!-- <div class="flex-column width15">
      <ion-list *ngIf="controllers.length > 1 && tablesReady" class="margin20 width-150">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>
    </div> -->

    <div *ngIf="tablesReady" class="width100">
      <div class="flex-row-space-between width100 margin-bottom30">
        <div class="uppercase margin-top5">{{'Guest Cards' | translate}} - {{guestFreeSpace}} {{ 'free spaces' | translate}}</div>
        <div  class="flex-row">
          <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsStaffUpdate)"
            [disabled]="loading" size="small" class="margin5" color="light" (click)="createBackupCard()">
            <span *ngIf="!backupCardExists">{{'Create backup card' | translate}}</span>
            <span *ngIf="backupCardExists">{{'Update backup card' | translate}}</span>
          </ion-button>
          <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate)" [disabled]="cardSyncActive || guestCards.length <= 0" size="small" class="margin5" color="light" (click)="syncAllRoomCards()">
            <span style="width: 350px;" *ngIf="!cardSyncActive" >{{'Sync all guest cards in room' | translate}}</span>
            <span style="width: 350px;" *ngIf="cardSyncActive" >{{'Sync in progress...' | translate}}</span>
          </ion-button>
        </div>
      </div>
          <table *ngIf="guestCards.length > 0">
            <tr>
              <!-- <th class="uppercase bg-white">{{'Num' | translate}}.</th> -->
              <th class="uppercase bg-white">{{'Valid from' | translate}}</th>
              <th class="uppercase bg-white">{{'Valid to' | translate}}</th>
              <!-- <th class="uppercase bg-white width5">{{''}}</th> -->
              <th class="uppercase bg-white">{{'User' | translate}}</th>
              <th class="uppercase bg-white">{{'Additional passages' | translate}}</th>
              <th class="uppercase bg-white">{{'Cards' | translate}}</th>
            </tr>
            <ng-container *ngIf="{data : lastGuestCardOnHolder$ | async } as guestCardOnHolderData">
              <tr *ngFor="let card of guestCards; let i = index" class="br-gray-b"
              [ngClass]="{'selected': (guestCardOnHolderData?.data?.uid === card?.uid), 'inactive-card': card.validFrom | getValidFromToCss}">
                <td>
               <div class="flex-row-center">
                    <span>{{ card.validFrom | date: "dd.MM.yyyy." }}</span>
                    <span>{{ card.validFrom | date: " - HH:mm" }}</span>
                  </div>
                </td>
                <td>
                  <div class="flex-row-center" [ngClass]="card.validTo | getValidToCssClass">
                    <span>{{ card.validTo | date: "dd.MM.yyyy." }}</span>
                    <span>{{ card.validTo | date: " - HH:mm" }}</span>
                  </div>
                </td>
                <!-- <td>
                  <ion-button
                  color="primary"
                  class="margin-right5 ion-no-border"
                  fill="outline"
                  size="small"
                  (click)="copyDates(card)"
                  >{{'Copy dates' | translate}}
                </ion-button>
                </td> -->
                <td>{{ card.user.fullName }}</td>
                <td class="">
                  <ion-button
                  (mouseenter)="showAccess($event, card)"
                  (mouseleave)="showAccess($event, card)"
                  color="primary"
                  class="margin-right5 ion-no-border"
                  fill="outline"
                  size="small"
                  >{{'Show' | translate}}
                </ion-button>
                <div *ngIf="showAccessPopup(card.uid)" class="trigger" [@enterAnimation]>
                  <div class="popup">
                    <div *ngIf="!userToShowInPopup" class="inner">
                      <div class="lds-dual-ring"></div>
                    </div>
                    <div *ngIf="userToShowInPopup" class="inner flex-row">
                      <div *ngFor="let loc of userToShowInPopup.permissions; let i = index;" class="flex-row margin-all5">
                        <div class="br-b">{{loc.locationName}}</div>
                        <div *ngIf="userToShowInPopup.permissions.length !== i+1">,</div>
                      </div>
                      <div *ngIf="userToShowInPopup.permissions.length === 0" class="width100">
                        <div class="text-align-center">{{'Empty' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                </td>
                <td>
                  <ion-button
                  *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                  (click)="generateQrCode(card)"
                  class="margin-right5"
                  color="secondary"
                  fill="outline"
                  size="small"
                  title="{{'Generate QR code' | translate}}"
                  [disabled]="loading">
                    <ion-icon name="qr-code-outline"></ion-icon>
                  </ion-button>
                  <ion-button
                  *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                  (click)="editCard(card.user.userId)"
                  class="margin-right5"
                  color="primary"
                  fill="outline"
                  size="small"
                  title="{{'Edit' | translate}}"
                  [disabled]="loading">
                    <ion-icon name="create"></ion-icon>
                  </ion-button>
                  <ion-button
                  *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate) && !cardMaxNumberReached"
                  (click)="copyTheCard(card)"
                  class="margin-right5"
                  color="medium"
                  fill="outline"
                  size="small"
                  title="{{'Copy' | translate}}"
                  [disabled]="loading">
                    <ion-icon name="copy"></ion-icon>
                  </ion-button>
                  <ion-button
                    *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                    (click)="moveGuestToAnotherRoom(card)"
                    color="success"
                    class="margin-right5"
                    fill="outline"
                    size="small"
                    title="{{'Move card to another room' | translate}}"
                    [disabled]="loading"
                    ><ion-icon name="exit-outline"></ion-icon>
                  </ion-button>

                  <ion-button
                  *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                  (click)="syncGuest(card.uid)"
                  color="warning"
                  fill="outline"
                  class="margin-right5"
                  size="small"
                  title="{{'Sync' | translate}}"
                  [disabled]="loading || cardSyncActive"
                  >
                    <ion-icon name="sync"></ion-icon>
                  </ion-button>


                  <ion-button
                  *ngIf="signedInUser | userHasPermission : swPermissions.CardsGuestUpdate"
                  (click)="deleteGuest(card.user)"
                  color="danger"
                  fill="outline"
                  size="small"
                  title="{{'Delete' | translate}}"
                  [disabled]="loading"
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </ion-button>

                </td>
              </tr>
            </ng-container>
          </table>
          <h3 *ngIf="guestCards.length === 0" class="flex-row-center-h margin-top50">
            {{'No guests in room' | translate}}
          </h3>
  </div>

  </div>
</div>


<ion-content
class="main-content">
<div class="flex-row">
  <div *ngIf="roomSwap" class="margin-auto margin-top30 margin-bottom30">
    {{'Location' | translate}}
    <ng-container *ngIf="objectsFilterFull$ | async as selectedObjectGlobal">

    <select
          class="margin-left20"
          (change)="onChangeObjectSubobject($event)"
          [ngModel]="selectedDesignation"
        >
          <ng-container
            *ngFor="let object of (allObjects$ | async) | getObjects: selectedObjectGlobal[0]"
          >
            <option [value]="object.designation">
               {{ object.name }}
            </option>
            <option
              *ngFor="let subObject of object.projectSubobjects.$values"
              [value]="subObject.designation"
            >
             {{ subObject.name }}
            </option>
          </ng-container>
          <option
            [value]="'*/*/*/*'"
          >
            {{ "All" | translate }}
          </option>
        </select>
      </ng-container>

  </div>
  <ion-button *ngIf="roomSwap" class="margin-right20 margin-top30" size="default" (click)="roomSwap = !roomSwap">{{'Cancel' | translate}}</ion-button>
</div>
<div *ngIf="roomSwap" class="drag-area" >
<div class="rooms-data">
   <ng-container *ngIf="guestLocationsThin$ | async as guestLocationsThin">
    <ng-container *ngIf="floorList$ | async as floorList">
      <div
        *ngFor="
          let floor of floorList
            | getFloorsForRoomSelection
              : selectedDesignation
              : guestLocationsThin
        "
        class="floor-box"
      >
        <div class="floor-number-box">
          <span [ngClass]="{ 'vertical-text': floor.name }">{{
            floor.name || floor
          }}</span>
        </div>
        <div>
          <div class="flex-row">
            <div
              *ngFor="
                let room of guestLocationsThin
                  | getRoomsOnFloorForSelection: floor : location
              "
              class="selectable-guest-room"
              (click)="onRoomSelection(room)"
            >
              <p>{{ room.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
</div>
</ion-content>

<app-modal-confirmation
    [type]="'roomSwap'"
    [content]="targetRoomToMoveCard"
    (decision)="onModalDecision($event)"
    *ngIf="openModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    (decision)="onCardOnreaderModalDecision($event)"
    *ngIf="openCardOnReaderModal">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardholder'"
    [content]=""
    [loading]="modalLoading"
    (decision)="onDuplicateModalDecision($event)"
    *ngIf="openModalDuplicate">
</app-modal-confirmation>

<app-modal-confirmation
    [type]="'cardExist'"
    [content]=""
    (decision)="onModalDecisionCardExist($event)"
    *ngIf="cardOnReaderExist">
</app-modal-confirmation>


