import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';
import { LocationsFilter } from 'src/app/core/models/locations-filter.model';


@Pipe({
  name: 'transformFloors'
})
export class TransformFloorsPipe implements PipeTransform {

  transform(
    fList: LocationGroup[],  filters: LocationsFilter,  locations: Location[],
    targetObject: ProjectObject, targetSubobject: ProjectSubObject
   ): LocationGroup[] {

     const targetObj: string = targetObject.designation.split('/')[0];
     const targetSubobj: string = targetSubobject.designation.split('/')[1];

     const floorsInObjectAndSuboject = fList.filter((flo)=> {
       return flo.locations.some((loc: Location)=> {
         const floorObject = loc.fullLocationId.split('/')[0];
         const floorSubobject = loc.fullLocationId.split('/')[1];
         return targetObj === floorObject && targetSubobj === floorSubobject
       })
     })

       const floorList = floorsInObjectAndSuboject.slice();
       const locationsOnFloors:Location[]  = floorList.reduce((flList,floor)=> {
           flList = [...floor.locations, ...flList]
           return flList;
         },[])
       const floorForUndistributedControllers: LocationGroup = {
           id: 9999,
           name: 'Undistributed',
           locationGroupTypeId: 3,
           locations: []
         };


         locations.forEach((loc : Location)=> {
          loc.controllers.$values.some( cont => {
            if (cont.object === targetObj && cont.subObject === targetSubobj && !locationsOnFloors.some( value => value.locationId === loc.locationId)) {
              floorForUndistributedControllers.locations.push(loc);
              return true
            }
          })
         })

         let filteredFloorList = floorList;
         // filter floors TODO koristiti novi filter

         if (filters.floors.length > 0) {
           filteredFloorList = filteredFloorList.filter((floor)=> {
             return filters.floors.includes(floor.id)
           })
         }



         if (floorForUndistributedControllers.locations.length>0) {
           filteredFloorList.push(floorForUndistributedControllers)
         }

         return filteredFloorList;


    }
}
