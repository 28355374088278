import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getBathroomRadiatorStatus'
})
export class GetBathroomRadiatorStatusPipe implements PipeTransform {

  transform(controllers: Controller[], updated):
   'By presence' | 'By rented' | 'Force on' | 'Force off' {

    const byRentedprops = [];
    const forcedOnProps = [];
    const forcedOffProps = [];

    controllers.forEach((cont)=> {
      cont.controllerProperties.$values.forEach((prop)=> {
        if (Property.isBathroomRadiatorByRented(prop)) {
          byRentedprops.push(prop)
        } else if (Property.isBathroomRadiatorForceOn(prop)) {
          forcedOnProps.push(prop)
        } else if (Property.isBathroomRadiatorForceOff(prop)) {
          forcedOffProps.push(prop)
        }
      })
    })

    if (forcedOnProps.some((p)=> Property.isActive(p))) {
      return 'Force on'
    } else if (forcedOffProps.some((p)=> Property.isActive(p))) {
      return 'Force off'
    }else if (byRentedprops.some((p)=> Property.isActive(p))) {
      return 'By rented'
    } else {
      return 'By presence'
    }

  }

}
