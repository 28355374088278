import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'propExistInControllers'
})
export class PropExistInControllersPipe implements PipeTransform {

  transform(controllers: Controller[], propFunct: Function, checkForHidden = false):  boolean {
    let exists = false;

    controllers.find( controller => {
        return controller.controllerProperties.$values.find( prop => {
          let target: Property;
          target = propFunct(prop);
          if (target) {
            exists = true;
            if (checkForHidden && prop.hidden) {
              exists = false;
            }
            return exists;
          }
        })
    })
    
    return exists;
  }

}


