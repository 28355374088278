import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { API_BASE_URL } from 'src/environments/environment';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AlarmNotifyType } from 'src/app/core/models/alarms/alarm-notify-type.model';
import { AlarmNotify } from 'src/app/core/models/alarms/alarm-notify.model';
import { CheckInOutTime, SettingsService } from '../settings.service';
import { Property } from 'src/app/core/models/project/property.model';
import { CardValidityService } from '../card-validity.service';
import { DisplayConfig } from 'src/app/shared/components/display-property/display-property.component';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { SettingsPropData } from 'src/app/shared/components/room-modal-settings/room-modal-settings.component';
import { AlarmSubscriptionsService } from 'src/app/modules/alarms/services/alarm-subscriptions.service';
import { Encoder } from 'src/app/core/models/encoder.model';
import { Observable } from 'rxjs';

export class CardValidity {
    cardExpiryCheckActive: boolean;
    lastSyncDate: Date;
    notifyDaysBefore: Array<number>;
    notifyUser: boolean;
    administrators: Array<string>;
    constructor() { }
}

export interface DefaultsPreset {
  /* _id: string;
  properties: Property[]; */
  id: number;
  name: string;
  equipmentPresetProperties: {
    $type: string,
    $values: Property[];
  }

}

export interface EqPreset{
  id: number;
  name: string;
  created: string;
  updated: string;
  equipmentPresetProperties: {type: string; values: []}
}

export interface CellConfig {
  position: number;
  fullLocationId: string;
  type : 'text' | 'picture' | 'controler'
  textValue?: string;
}

export interface RetroModeConfig {
  object: string,
  subObject: string,
  cellConfig: CellConfig[]
}

export interface FrontendSettings {
  _id: string;
  settings: {
    defaultCheckInTime: CheckInOutTime,
    defaultCheckoutTime: CheckInOutTime,
    propertyDisplayConfigs: DisplayConfig[],
    useBalconyLightsSchedule: boolean,
    requireAckAlarmText: boolean,
    defaultStayDurationDays: number,
    roomModalPropSettings : {
      room: SettingsPropData[],
      bathroom: SettingsPropData[]
    }
    retroRoomPositioning : RetroModeConfig[];
  }
}

export interface PmsKoAccessControllers {
  pmsKoAccessControllers: PmsKeyOptions[];
}

export interface PmsKeyOptions {
  locationId: number,
  isGroup: boolean,
  pmsKoId: number
}

@Injectable({
    providedIn: 'root'
})
export class ApiSettingsService {

    constructor(private http: HttpClient,
        private settingsService: SettingsService,
        private cardValidityService: CardValidityService,
        private alarmSubscriptionsService: AlarmSubscriptionsService
        ) { }

 /*    getAlarmTypes() {
        return this.http.get<AlarmType[]>(API_BASE_URL + '/alarms/types').pipe(
            tap(alarmTypes => {
                this.settingsService.setAlarmTypes(alarmTypes);
            }),
        );
    } */

    getAlarmNotifyTypes() {
        return this.http.get<AlarmNotifyType[]>(API_BASE_URL + '/alarms/notifytypes').pipe(
            tap(alarmNotifyTypes => {
                this.alarmSubscriptionsService.setAlarmNotifyTypes(alarmNotifyTypes);
            }),
        );
    }

    getAlarmSubscriptions() {
        return this.http.get<AlarmNotify[]>(API_BASE_URL + '/alarms/notify').pipe(
            tap(alarmSubscriptions => {
                this.alarmSubscriptionsService.setAlarmSubscriptions(alarmSubscriptions);
            }),
        );
    }

    getAlarmSubscription(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<AlarmNotify>(API_BASE_URL + '/alarms/notify/byId', { params }).pipe(
            tap(alarmSubscription => {
                this.alarmSubscriptionsService.updateAlarmSubscription(alarmSubscription);
            }),
        );
    }

    addAlarmSubscription(newAlarmSubscription: AlarmNotify) {
        const params = new HttpParams()
            .set('typeId', newAlarmSubscription.alarmTypeId.toString())
            .set('notificationTypeId', newAlarmSubscription.alarmNotifyTypeId.toString())
            .set('destination', newAlarmSubscription.destination);
        return this.http.post<AlarmNotify>(API_BASE_URL + '/alarms/notify', null, { params }).pipe(
            tap(receivedAlarmSub => {
                this.alarmSubscriptionsService.addAlarmSubscription(receivedAlarmSub);
            }),
        );
    }

    updateAlarmSubscription(updatedAlarmSubscription: AlarmNotify) {
        return this.http.put<AlarmNotify>(API_BASE_URL + '/alarms/notify', updatedAlarmSubscription).pipe(
            tap(receivedAlarmSub => {
                this.alarmSubscriptionsService.updateAlarmSubscription(receivedAlarmSub);
            }),
        );
    }

    deleteAlarmSubscription(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.delete(API_BASE_URL + '/alarms/notify', { params }).pipe(
            map(() => {
                this.alarmSubscriptionsService.deleteAlarmSubscription(id);
                return;
            }),
        );
    }

    getPresetProps() {
      return this.http.post<Property[]>(API_BASE_URL + '/equipment/presetprops', ['Room2'])
    }

  getDefaultPresets() {
    return this.http.get<DefaultsPreset[]>(API_BASE_URL + '/presets').pipe(
      tap( defaultSettings => {
          this.settingsService.setDefaultPresets(defaultSettings);
      }))
  }

  updateDefaultPreset(defaultsPreset: DefaultsPreset) {
    return this.http.put(API_BASE_URL + '/presets',defaultsPreset);
  }
  deleteDefaultPreset(defaultsPresetId) {
    const params = new HttpParams().set('id', defaultsPresetId.toString());
    return this.http.delete(API_BASE_URL + '/presets', {params});
  }
  createDefaultPreset(defaultsPreset) {
    return this.http.post(API_BASE_URL + '/presets', defaultsPreset);
  }

    activateDefaultPreset(defaultsPresetId: string, locationDesignation: string, toSubControllers: boolean) {
      const params = new HttpParams()
      .set('id', defaultsPresetId.toString())
      .set('locationDesignation', locationDesignation.toString())
      .set('toSubControllers', toSubControllers.toString())
      return this.http.get(API_BASE_URL + '/presets/activate', {params});
    }

    getLocationPresetCrossReferences(){
      return this.http.get<LocationPresetCrossRef[]>(`${API_BASE_URL}/presets/crossref`).pipe(
        tap(locCrossRefs => {
          return locCrossRefs;
        })
      );
    }

    addLocationPresetCrossReferences(locationPresetCrossRef: LocationPresetCrossRef[]){
      return this.http.post<any[]>(`${API_BASE_URL}/presets/crossref`, locationPresetCrossRef).pipe(
        map(lpcrefs => {
          return lpcrefs;
        })
      )
    }
  getCardValidity() {
    return this.http.get<CardValidity>(API_BASE_URL + '/datasync/cardvalidcheck/settings').pipe(
        tap( response => {
            this.cardValidityService.setCardValiditySettings(response);
        })
    );
  }

  setCardValidity(body: CardValidity) {
    return this.http.post<CardValidity>(API_BASE_URL + '/datasync/cardvalidcheck/settings' , body).pipe(
        tap( response => {
            this.cardValidityService.setCardValiditySettings(response);
        })
    );
  }

    getHeaderForAddress(address: string, cooling: boolean) {
        const params = new HttpParams().set('address', address.toString())
                                       .set('cooling', cooling.toString());
        return this.http.get(API_BASE_URL + '/tools/header', { params, responseType: 'text' });
    }

    getHeaderForAddressFullParams(address: string, type: string, mempos: string) {
        const params = new HttpParams()
            .set('address', address.toString())
            .set('type', type.toString())
            .set('mempos', mempos.toString());
        return this.http.get(API_BASE_URL + '/tools/mempos', { params });
    }

    setHeaderForAddressFullParams(address: string, type: string, mempos: string, value: string, broadcast: boolean) {
        const params = new HttpParams()
            .set('address', address.toString())
            .set('type', type.toString())
            .set('mempos', mempos.toString())
            .set('value', value)
            .set('broadcast', broadcast);
        return this.http.post(API_BASE_URL + '/tools/mempos', null, { params });
    }

    getProjectDifferences() {
        return this.http.get(API_BASE_URL + '/project/convert/diffs');
    }

    confirmProjectUpdate(retain: boolean) {
        const params = new HttpParams()
            .set('retain', retain.toString());
        return this.http.get(API_BASE_URL + '/project/convert', { params });
    }


    getFrontendSettings() : Observable<FrontendSettings> {
      return this.http.get/* <AlarmType[]> */(API_BASE_URL + '/settings/frontend').pipe(
            tap((frontEndSettings: FrontendSettings) => {
                if (frontEndSettings) {
                  this.settingsService.setFrontendSettings(frontEndSettings);
                }
            }),
        );
    }

    updateFrontendSettings(updatedFrontendSettings: FrontendSettings) {
      return this.http.put(API_BASE_URL + '/settings/frontend', updatedFrontendSettings).pipe(
        tap((frontEndSettings: FrontendSettings) => {
            this.settingsService.setFrontendSettings(frontEndSettings);
        }),
    );
    }

    createFrontendSettings(newFrontendSettings: FrontendSettings) {
      return this.http.post(API_BASE_URL + '/settings/frontend', newFrontendSettings).pipe(
        tap((frontEndSettings: FrontendSettings) => {
            this.settingsService.setFrontendSettings(frontEndSettings);
        }),
    );
    }

    updateDefaultCheckinCheckoutTime(
      defaultCheckinTime: CheckInOutTime, defaultCheckoutTime: CheckInOutTime, defaultStayDurationDays: number) {
      return this.settingsService.getFrontendSettings().pipe(take(1), switchMap((frontendSettings: FrontendSettings)=> {
        // const updatedFrontendSettings: FrontendSettings = JSON.parse(JSON.stringify(frontendSettings));
        const updatedFrontendSettings: FrontendSettings = structuredClone(frontendSettings);

        updatedFrontendSettings.settings.defaultCheckInTime = defaultCheckinTime;
        updatedFrontendSettings.settings.defaultCheckoutTime = defaultCheckoutTime;
        updatedFrontendSettings.settings.defaultStayDurationDays = defaultStayDurationDays;
        return this.updateFrontendSettings(updatedFrontendSettings);
      }))
    }

    updatePropertyDisplayConfigs(propertyDisplayConfigs: DisplayConfig[],
      roomModalPropSettings : {room: SettingsPropData[]; bathroom: SettingsPropData[]}) {
      return this.settingsService.getFrontendSettings().pipe(take(1),switchMap((frontendSettings: FrontendSettings)=> {
        // const updatedFrontendSettings: FrontendSettings = JSON.parse(JSON.stringify(frontendSettings));
        const updatedFrontendSettings: FrontendSettings = structuredClone(frontendSettings);

        updatedFrontendSettings.settings.propertyDisplayConfigs = propertyDisplayConfigs;
        updatedFrontendSettings.settings.roomModalPropSettings = roomModalPropSettings;
        return this.updateFrontendSettings(updatedFrontendSettings);
      }))
    }

    updateUseBalconyLights(newValue: boolean) {
      return this.settingsService.getFrontendSettings().pipe(take(1),switchMap((frontendSettings: FrontendSettings)=> {
        // const updatedFrontendSettings: FrontendSettings = JSON.parse(JSON.stringify(frontendSettings));
        const updatedFrontendSettings: FrontendSettings = structuredClone(frontendSettings);

        updatedFrontendSettings.settings.useBalconyLightsSchedule = newValue;
        return this.updateFrontendSettings(updatedFrontendSettings);
      }))
    }


    updateAckAlarmText(newValue: boolean) {
        return this.settingsService.getFrontendSettings().pipe(take(1),switchMap((frontendSettings: FrontendSettings)=> {
          // const updatedFrontendSettings: FrontendSettings = JSON.parse(JSON.stringify(frontendSettings));
          const updatedFrontendSettings: FrontendSettings = structuredClone(frontendSettings);

          updatedFrontendSettings.settings.requireAckAlarmText = newValue;
          return this.updateFrontendSettings(updatedFrontendSettings);
        }))
      }

      getEncoders() {
        return this.http.get<Encoder[]>(API_BASE_URL + '/encoders').pipe(
          tap((encoders) => {
            return encoders;
          })
        );
      }

      getEncoder(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.get<Encoder>(API_BASE_URL + '/encoders/byId', { params }).pipe(
          tap((encoder) => {
            return encoder;
          })
        );
      }

      addEncoder(encoder: Encoder) {
        return this.http.post<Encoder>(API_BASE_URL + '/encoders', encoder);
      }

      updateEncoder(updatedEncoder: Encoder) {
        return this.http.put<Encoder>(API_BASE_URL + '/encoders', updatedEncoder);
      }

      deleteEncoder(id: number) {
        const params = new HttpParams().set('id', id.toString());
        return this.http.delete(API_BASE_URL + '/encoders', { params });
      }

      getPmsKeyOptions() {
        return this.http.get<PmsKoAccessControllers>(API_BASE_URL + '/settings/pmskeyoptions').pipe(
          tap((pmsOptions) => {
            return pmsOptions;
          })
        );
      }

      setPmsKeyOptions(pmsKoAccessControllers: PmsKoAccessControllers) {
        return this.http.post<PmsKoAccessControllers>(API_BASE_URL + '/settings/pmskeyoptions', pmsKoAccessControllers);
      }

      getCardByPosition(cardPosition: string, address: string){
        const params = new HttpParams()
            .set('address', address.toString())
            .set('cardPos', cardPosition.toString())
        return this.http.get(API_BASE_URL + '/tools/cardpos', { params, responseType: 'text' }).pipe(
          tap((cardPos) => {
            return cardPos;
          })
        );
      }

      cleanUnusedCardPositions(address: string) {
        return this.http.get<any>(`${API_BASE_URL}/cards/cleanunusedguestcardpositions?address=${address}`).pipe(
          tap((value) => {
            return value;
          })
        );
      }

}
