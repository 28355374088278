import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserStoreService {

  user: User;
  userChanged = new Subject<User>();

  constructor() { }

  getUser(): User {
    return this.user;
  }

  setUser(user: User) {
    this.user = user;
    this.userChanged.next(user);
  }

}
