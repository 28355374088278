import { Pipe, PipeTransform } from '@angular/core';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'filterGroupsForSubobject'
})
export class FilterGroupsForSubobjectPipe implements PipeTransform {

  transform(groups: LocationGroup[], selectedDesignation = '*/*/*/*' ): LocationGroup[] {

    const targetObject = selectedDesignation.split('/')[0];
    const targetSubobject = selectedDesignation.split('/')[1];

    if (!groups) {
      return [];
    }
    if (targetObject === '*' && targetSubobject === '*') {
      return groups;
    }

    if ( targetSubobject === '*') {
      return groups.filter((gr)=> {
        return gr.locations.every((location)=> {
          const obj = Location.getLocationObject(location)
          return obj.toString() === targetObject.toString()
        })
      })
    }

    return groups.filter((gr)=> {
      return gr.locations.every((location)=> {
        const obj = Location.getLocationObject(location)
        const subObj = Location.getLocationSubObject(location)
        return obj.toString() === targetObject.toString() && subObj.toString() === targetSubobject.toString()
      })
    })


  }
}
