import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroup } from '../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';

@Pipe({
  name: 'getLocationGroupsByType'
})
export class GetLocationGroupsByTypePipe implements PipeTransform {

  transform(locGroup: LocationGroup[], locType: LocationGroupType): LocationGroup[] {
    return locGroup.filter( (loc:LocationGroup) => loc.locationGroupTypeId === locType)
  }
}