import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackBtnService {

  isBackPressed: boolean;

  constructor() { }

  getIsBackPressed() {
    return this.isBackPressed;
  }

  setIsBackPressed(isBackPressed: boolean) {
    this.isBackPressed = isBackPressed;
  }
}
