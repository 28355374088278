import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { AlarmNotifyType } from 'src/app/core/models/alarms/alarm-notify-type.model';
import { AlarmNotify } from 'src/app/core/models/alarms/alarm-notify.model';

@Injectable({
    providedIn: 'root'
})
export class AlarmSubscriptionsService {

    alarmTypes: AlarmType[] = [];
    alarmTypesChanged = new Subject<AlarmType[]>();
    alarmNotifyTypes: AlarmNotifyType[] = [];
    alarmNotifyTypesChanged = new Subject<AlarmNotifyType[]>();
    alarmSubscriptions: AlarmNotify[] = [];
    alarmSubscriptionsChanged = new Subject<AlarmNotify[]>();
    editingSubscriptionChange = new Subject<boolean>();
    editingSubscription = false;

    constructor() {}
    
    setEditingSubscription(value: boolean) {
        this.editingSubscription = value;
        this.editingSubscriptionChange.next(this.editingSubscription);
    }

    getAlarmTypes() {
        return this.alarmTypes;
    }

    setAlarmTypes(alarmTypes: AlarmType[]) {
        this.alarmTypes = alarmTypes;
        this.alarmTypesChanged.next(this.alarmTypes);
    }

    getAlarmNotifyTypes() {
        return this.alarmNotifyTypes;
    }

    setAlarmNotifyTypes(alarmNotifyTypes: AlarmNotifyType[]) {
        this.alarmNotifyTypes = alarmNotifyTypes;
        this.alarmNotifyTypesChanged.next(this.alarmNotifyTypes);
    }

    getAlarmSubscriptions() {
        return this.alarmSubscriptions;
    }

    setAlarmSubscriptions(alarmSubscriptions: AlarmNotify[]) {
        this.alarmSubscriptions = alarmSubscriptions;
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }

    getAlarmSubscription(id: number) {
        if (!this.alarmSubscriptions) {
            return undefined;
        }
        return this.alarmSubscriptions.find(alarmSubscription => alarmSubscription.alarmNotifyId === id);
    }

    updateAlarmSubscription(alarmSubscription: AlarmNotify) {
        const index = this.alarmSubscriptions.findIndex(alarmSubToChange => alarmSubToChange.alarmNotifyId === alarmSubscription.alarmNotifyId);
        if (index !== -1) {
            this.alarmSubscriptions[index] = alarmSubscription;
            this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
        } else {
            this.addAlarmSubscription(alarmSubscription);
        }
    }

    addAlarmSubscription(alarmSubscription: AlarmNotify) {
        this.alarmSubscriptions.push(alarmSubscription);
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }

    deleteAlarmSubscription(id: number) {
        const index = this.alarmSubscriptions.findIndex(alarmSubscription => alarmSubscription.alarmNotifyId === id);
        this.alarmSubscriptions.splice(index, 1);
        this.alarmSubscriptionsChanged.next(this.alarmSubscriptions);
    }
}