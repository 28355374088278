import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getRoomsOnFloor'
})
export class GetRoomsOnFloorPipe implements PipeTransform {

  transform(
    locations: Location[], fl: LocationGroup, controllerType: 'isGuestRoom' | 'isAccessControl' | 'isHvacCommonArea' | 'isIOCommonArea',
     targetObject?: ProjectObject, targetSubobject?: ProjectSubObject)
    : Location[] {

    if (locations.length === 0) {
      return  [];
    }

    return locations.filter( (location: Location) => {
              if (controllerType === 'isGuestRoom' && Location.isGuestRoom(location) &&
                 Location.getLocationObject(location) === ProjectObject.getDesignationObject(targetObject) &&
                 Location.getLocationSubObject(location) === ProjectSubObject.getDesignationSubobject(targetSubobject) &&
                 fl.locations.some(loc => loc.locationId === location.locationId)) {
                return true
              } else if (controllerType === 'isAccessControl' && Location.isAccessControl(location) &&
              Location.getLocationObject(location) === ProjectObject.getDesignationObject(targetObject) &&
              Location.getLocationSubObject(location) === ProjectSubObject.getDesignationSubobject(targetSubobject) &&
              fl.locations.some(loc => loc.locationId === location.locationId)) {
                return true
              } else if (controllerType === 'isHvacCommonArea' && Location.isHvacCommonArea(location) &&
              Location.getLocationObject(location) === ProjectObject.getDesignationObject(targetObject) &&
              Location.getLocationSubObject(location) === ProjectSubObject.getDesignationSubobject(targetSubobject) &&
              fl.locations.some(loc => loc.locationId === location.locationId)) {
                return true
              } else if (controllerType === 'isIOCommonArea' && Location.isIOCommonArea(location) &&
              Location.getLocationObject(location) === ProjectObject.getDesignationObject(targetObject) &&
              Location.getLocationSubObject(location) === ProjectSubObject.getDesignationSubobject(targetSubobject) &&
              fl.locations.some(loc => loc.locationId === location.locationId)) {
                return true
              }
    });
  }
}
