import { Pipe, PipeTransform } from '@angular/core';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'retroModeControllerPosition'
})
export class RetroModeControllerPositionPipe implements PipeTransform {

  transform(locations: Location[], fullLocationId: string):  Location {
    if (fullLocationId == null) {
        return null
    }

    let target = locations.find( location => location.fullLocationId === fullLocationId)

    return target;
    
  }
}


