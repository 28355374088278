import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputStatusNumber'
})
export class GetRelayOutputStatusNumberPipe implements PipeTransform {
  transform(property: Property): string {
    return property.mqttTopic.split('/')[2]
  }


}
