import { Pipe, PipeTransform } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';

import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getAllRelayPropsFromController'
})
export class GetAllRelayPropsFromControllerPipe implements PipeTransform {

  transform(controller: Controller): Property[] {
        return controller.controllerProperties.$values.filter(prop => { 
            if ((Property.isRelayOutputStatus(prop) ||
            Property.isRelayOutputControl(prop) ||
            Property.isRelayOutputForceOn(prop) ||
            Property.isRelayOutputForceOff(prop) ||
            Property.isRelayOutputByRentedOrPresence(prop) ||
            Property.isRelayOutputInCooling(prop)) && !prop.hidden) {
                return true;
            }
        })
  }
}
