export class ProjectSubObject {
  projectSubobjectId: number;
  name: string;
  designation: string;
  projectObjectId: number;
  projectObject: any;
  locations: any;
  controllers: any;

  public static getDesignationObject(subObject: ProjectSubObject): string {
    return subObject.designation.split('/')[0]
  }

  public static getDesignationSubobject(subObject: ProjectSubObject): string {
    return subObject.designation.split('/')[1]
  }

}


