import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-d3-room',
  templateUrl: './d3-room.component.html',
  styleUrls: ['./d3-room.component.scss']
})
export class D3RoomComponent implements OnInit, OnDestroy {

  @Input() controller: Controller;

  doorsOpening = false;
  doorsClosing = false;

  sub: Subscription;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.sub = this.projectService.doorsChanged.subscribe( (value) => {
      if (this.controller.designation === value[1]) {
        if (+value[0] === 1) {
      this.doorsOpening = true;
      this.doorsClosing = false;
        } else {
      this.doorsClosing = true;
      this.doorsOpening = false;
        }

        setTimeout( () => {
          this.doorsClosing = false;
          this.doorsOpening = false;
        },1500)
      }
    });

  }

  doorOpen() {
    const door = this.controller.controllerProperties.$values.find( (prop: Property) => {
      if (Property.isDoorOpened(prop)) {
        return true;
      }
    })

    if (+door.value === 1) {
      return true;
    } else {
      return false;
    }
  }


  airConditioning() {
    const airConditioning = this.controller.controllerProperties.$values.find( (prop: Property) => {
      if (Property.isHvacActive(prop)) {
        return true;
      }
    })

    if (+airConditioning.value === 1) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    if (this.sub){
      this.sub.unsubscribe();
    }
  }

}
