import { Pipe, PipeTransform } from '@angular/core';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'statusIsActive'
})
export class StatusIsActivePipe implements PipeTransform {

  transform(location: Location, statusName: string, activeCondition?: 'any' | 'every' | 'first', updated? : Date): boolean {
    if (!location) {
      return false;
    }
    return location[statusName] === true;

  }


}
