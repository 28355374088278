import { Directive, OnInit, HostListener, ElementRef } from '@angular/core';
import { ScrollService } from '../services/scroll.service';

@Directive({
    selector: '[stickyHeaderDirectiveRooms]'
})
export class StickyHeaderDirectiveRooms implements OnInit {

  ngOnInit() {
  }
  constructor(private scrollService: ScrollService) {}

  // @HostListener('scroll', ['$event']) private onScroll($event: any): void {
  //     sessionStorage.setItem('scrollTopRooms', $event.target.scrollTop);
  // }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: any): void {
  //   const scrollPosition = event.detail.scrollTop;
  //   console.log("scrollPosition: ", scrollPosition);
  //   this.scrollService.saveScrollPosition(scrollPosition);
  // }
}
