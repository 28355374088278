import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';
import { IPropDictionary } from '../services/prop-dictionary.service';

@Pipe({
  name: 'getDictionaryValue'
})
export class GetDictionaryValuePipe implements PipeTransform {

  transform(prop: Property, dictionary: IPropDictionary, updated?: Date): {text: string, iconOn: boolean, textShort: string} {
    if (!prop) {
      return
    }

    const dictionaryEntry = dictionary.entries.find((de)=> {
      return prop.value.toString() === de.value
    })
    if (!dictionaryEntry) {
      return dictionary.noEntryFound
    }
    return dictionaryEntry
  }

}
