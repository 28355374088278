export enum OutpuModeStatus {
    Off = 0,
    On = 1,
    Luxomat = 2,
    TimeInterval = 3,
    LuxomatAndTimeInterval = 4,
    OutputControl = 6,
    PIR = 7,
    KeyboardIN1 = 8,
    SwitchIN1 = 9,
    LuxomatAndPIR = 71,
    TimeIntervalAndPIR = 72
}


// 0=> Mod 0
// Isključen ( Off )

// Default!
// 1=> Mod 1 Uključen ( On )

// 2=> Mod 2
// Radi na foto senzor
// Luxomat dan / noć
// Dan=> isključen izlaz
// Noć=> uključen izlaz

// 3=> Mod 3
// Radi na vrijeme koje mu se definira
// moguće definirati dva vremenska
// intervala t1, t2!
// t1= n : m
// t2= n : m

// 4=> Mod 4
// Kombinacija dva moda istovremeno
// Mod 2 + Mod 3
// Dakle, gleda dan / noć + zadano
// vrijeme ( moguće definirati dva
// vremenska intervala t1, t2 )

// 6=> Mod 6
// Upravljanje izlazom računalom
// On / Off

// 7=> Mod 7
// Radi na PIR mirno / radni
// kontakt podržan!

// 8=> Mod 8
// Radi na tipkalo koje je spojeno na
// ulaz IN1

// 9=> Mod 9
// Radi na sklopku ( prekidač ) koja
// je spojena na ulaz IN1

// 71=> Mod 71
// Kombinacija dva moda istovremeno
// Mod 2 + Mod 7
// Dakle, gleda dan / noć + PIR
// Dan=> PIR ne uključuje izlaz
// Noć=> PIR uključuje izlaz

// 72=> Mod 72
// Kombinacija dva moda istovremeno
// Mod 3 + Mod 7
// Dakle, vrijeme koje mu se definira +
// PIR
// U zadanom vremenskom intervalu
// ( moguće definirati dva vremenska
// intervala t1, t2 ) PIR uključuje
// izlaz!