import { Pipe, PipeTransform } from '@angular/core';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getFloorsForRoomSelection'
})
export class GetFloorsForRoomSelectionPipe implements PipeTransform {

  transform(allFloors: LocationGroup[], selectedDesignation: string, locations?: Location[]): LocationGroup[] {
    const targetObj: string = selectedDesignation.split('/')[0];
    const targetSubobj: string = selectedDesignation.split('/')[1];

    const floorsInObjectAndSuboject = allFloors.filter((flo)=> {
      return flo.locations.some((location: Location)=> {
        const floorObject = location.fullLocationId.split('/')[0];
        const floorSubobject = location.fullLocationId.split('/')[1];
        return( targetObj === floorObject  || targetObj === '*') && (targetSubobj === floorSubobject || targetSubobj === '*')
      })
    })

    const floorList = floorsInObjectAndSuboject.slice();
        const locationsOnFloor:Location[]  = allFloors.reduce((flList,floor)=> {
            flList = [...floor.locations, ...flList]
            return flList;
          },[])
        const floorForUndistributedControllers: LocationGroup = {
            id: 9999,
            name: 'Undistributed',
            locationGroupTypeId: 3,
            locations: []
          };

          locations.forEach((loc : Location)=> {
            if ((Location.getLocationObject(loc) === targetObj || targetObj == '*') && (Location.getLocationSubObject(loc)=== targetSubobj || targetSubobj == '*') && !locationsOnFloor.find( location => location.locationId === loc.locationId)) {
              floorForUndistributedControllers.locations.push(loc);
            }
          })



          if (floorForUndistributedControllers.locations.length>0) {
            floorList.push(floorForUndistributedControllers)
          }
          return floorList;

  }

}
