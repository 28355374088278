import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'getRelayOutputByNumber'
})
export class GetRelayOutputByNumberPipe implements PipeTransform {

  transform(properties: Property[], relayStatusOutputNumber): Property[] {
    return properties.filter((prop)=> {
      return prop.mqttTopic.endsWith(relayStatusOutputNumber);
    });
  }

}
