<div class="flex-row-space-evenly margin-top30">
  <div class="width90 flex-row">

    <div class="flex-column width15">
      <ion-list *ngIf="controllers.length > 1" class="width-150">
        <ion-list-header>{{'Controllers' | translate}}</ion-list-header>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>

      <div class="flex-column width100">
        <ion-list class="flex-row">
          <ion-list-header>{{ "Tags" | translate }}
            <ion-icon *ngIf="(signedInUser | userHasPermission : SoftwarePermissionId.LocationTags)" class="margin-left15 clickable" name="create-outline" (click)="editControllerTags($event)"></ion-icon>
          </ion-list-header>
        </ion-list>
        <div *ngFor="let tag of location.locationTags.$values">
          {{tag.tag}}
        </div>
      </div>
    </div>

    <div class="flex-column width30">
      <div class="margin-bottom30">{{'STATUSES' | translate}}</div>

      <app-modal-view-boolean-prop [controllers]="controllers" [propFilterFunction]="Property.isDoorOpened"
      [activeText]="'Door open'" [inactiveText]="'Door closed'" [iconActive]="'icon-door_opened_status'"
      [iconInactive]="'icon-door'" [forcePropIndex]="-1">
    </app-modal-view-boolean-prop>
    </div>

    <div class="flex-column width15 margin-right40">
      <ion-button
      class="margin5 width100"
      color="light"
      (click)="syncHvac()"
      size="small"
      ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
      {{'Settings sync' | translate}}</ion-button>

      <div style="width: 300px;">
        <div>{{'Access options' |translate}}</div>

        <app-boolean-input
        *ngIf="controllers | propExistInControllers : isACDoorOpenPushButton : true"
        [controllers]="controllers"
        [findPropFunction]="isACDoorOpenPushButton"
        >{{'Door open push button' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDoorOpenAnyCard : true"
        [controllers]="controllers"
        [findPropFunction]="isACDoorOpenAnyCard"
        >{{'Door open any card' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACAlwaysOpen : true"
      [controllers]="controllers"
      [findPropFunction]="isACAlwaysOpen"
      >{{'Is Always open' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyGuests : true"
      [controllers]="controllers"
      [findPropFunction]="isACDenyGuests"
      >{{'Deny Guests' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyStaff : true"
      [controllers]="controllers"
      [findPropFunction]="isACDenyStaff"
      >{{'Deny Staff' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyAll : true"
      [controllers]="controllers"
      [findPropFunction]="isACDenyAll"
    >{{'Deny All' | translate}}</app-boolean-input>

    <ion-item lines="none" class="item-background-color">
      <ion-checkbox
        (ionChange)="changeControllerPooling($event)"
        justify="start"
        labelPlacement="end"
        class="margin-right5"
        [checked]="stopControllerPolling"
      ><span class="ion-text-wrap">
    {{ "Stop controller polling" | translate }}</span></ion-checkbox>
    </ion-item>

    <ion-item *ngIf="(controllers | propExistInControllers: isACDoorGuestDenyFrom : true) && (controllers | propExistInControllers: isACDoorGuestDenyTo : true) && (controllers | canIntervalBeSetPipe : isOfflineTimer)"  lines="none" class="item-background-color">
      <ion-checkbox
      justify="start"
      labelPlacement="end"
      class="margin-right5"
      [checked]="intervalForDoorsActive"
      (ionChange)="onSetIntervalClicked($event)">
      <span class="ion-text-wrap">
        {{'Set interval for denied entry' | translate}}
      </span>
    </ion-checkbox>
    </ion-item>
    <app-select-time
    *ngIf="intervalForDoorsActive"
    (setTime$)="procesTimeObject($event)"
    [controllers]="controllers">
    </app-select-time>

      </div>
    </div>

    <div class="flex-column width22 margin-left100">
       <!-- ALARM BOX YELLOW  -->
       <div class="alarm-box-yellow">
         <div class="flex-row-center-v margin-bottom5 width100">
           <div class="icon-alert font-size20 yellow"></div>
           <div class="margin-left20 margin-top5"> {{'Warnings' | translate}}</div>
         </div>

         <div class="flex-row margin-left20" *ngIf="
         (controllers
           | findProperty: Property.isDoorOpenTooLongAlarm: null : true
           | propIsActive)">

       <ng-container *ngIf="
       selectedControllers
             | findProperty: Property.isDoorOpenTooLongAlarm: null : true as doorOpenTooLongProps
         ">
         <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
           <div *ngIf="doorOpenTooLongProp | propIsActive">
             <div class="prop-title">
               {{ getAlarmName(doorOpenTooLongProp) | translate }}
             </div>
           </div>
         </ng-container>
       </ng-container>

     </div>
       </div>
     </div>

  </div>

</div>
