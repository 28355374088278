<div class="login-full-screen" >
    <div class="login-container">
        <div class="logo"></div>
        <div class="hotel-name">Hotel Name</div>
  
      <form *ngIf="licensingForm" [formGroup]="licensingForm" class="flex-column flex-center width80" (ngSubmit)="onEnterKey()">
        <div class="content-form-group width100 margin-top100">
            <label for="email">Adria user email:</label>
            <input
              type="text"
              id="email"
              placeholder="Enter username"
              formControlName="email" 
              [ngClass]="{ 'is-invalid': licensingForm.controls.email?.errors !=null && submitted }"
              name="email"
              required
              email>
              <div
                  class="invalid-input"
                  *ngIf="licensingForm.controls.email?.errors !=null && submitted">
                  {{'Email must be a valid email address' | translate}}
              </div>
            </div>
          <div class="content-form-group width100 margin-top10">
            <label for="password">{{'Password' | translate}}:</label>
            <input
              type="password"
              id="password"
              placeholder="{{'Enter password' | translate}}"
              formControlName="password" 
              [ngClass]="{ 'is-invalid': licensingForm.controls.password?.errors !=null && submitted }"
              name="password"
              required>
              <div
                  class="invalid-input"
                  *ngIf="licensingForm.controls.password?.errors !=null && submitted">
                  {{'Enter password' | translate}}
              </div>
          </div>
        <div class="content-form-group width100 margin-top10">
            <label for="projectID">Project ID</label>
            <input type="text" placeholder="Enter project ID" formControlName="projectID" name="projectID" [ngClass]="{ 'is-invalid': submitted && licensingForm.controls.projectID.errors }">
            <div *ngIf="submitted && licensingForm.controls.projectID.errors" class="invalid-input">
                <div *ngIf="licensingForm.controls.projectID.errors.required">Project ID is required.</div>
                <div *ngIf="licensingForm.controls.projectID.errors.maxlength">Project ID can have max 32 characters.</div>
            </div>
        </div>

        <button type="submit" class="btn-big margin-top20">Accept</button>

      </form>  
    </div>
  
  
  
    <div class="flex-column-center-full margin-top30 powered-by">
      <div>powered by</div>
      <div>ADRIA ELECTRONIC</div>
    </div>
  </div>
  