import { Pipe, PipeTransform } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { Card } from 'src/app/core/models/card/card.model';

@Pipe({
  name: 'getCardTypeCode'
})
export class GetCardTypeCodePipe implements PipeTransform {

  transform(card: Card, cardTypes: CardType[]): number {
    if (cardTypes.length !== 0) {
      return cardTypes.find((cType)=> {
        return cType.typeId === card.type
      }).code;
    }
  }

}
