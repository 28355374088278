import { Pipe, PipeTransform } from '@angular/core';
import { KeyOption, } from 'src/app/modules/project/services/http/api-project.service';
import { LocationGroup } from '../services/api-location-groups.service';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getFloorsForControllerSelection'
})
export class GetFloorsForControllerSelectionPipe implements PipeTransform {

  transform(allFloors: LocationGroup[], keyOptions: KeyOption[],
    selectedDesignation: string): LocationGroup[] {

    const targetObj: string = selectedDesignation.split('/')[0];
      const targetSubobj: string = selectedDesignation.split('/')[1];

      const floorsInObjectAndSuboject = allFloors.filter((flo)=> {
        return flo.locations.some((location: Location)=> {
          const floorObject = Location.getLocationObject(location);
          const floorSubobject = Location.getLocationSubObject(location);
          return (targetObj === floorObject || targetObj === '*')  && (targetSubobj === floorSubobject || targetSubobj === '*')
        })
      })


        const floorList = floorsInObjectAndSuboject.slice();
        const locationsOnFloor:Location[]  = floorList.reduce((flList,floor)=> {
            flList = [...floor.locations, ...flList]
            return flList;
          },[])
        const floorForUndistributedControllers: LocationGroup = {
            id: 9999,
            name: 'Undistributed',
            locationGroupTypeId: 3,
            locations: []
          };

          keyOptions.forEach((keyOption : KeyOption)=> {
            if ((targetObj === Location.getLocationObject(keyOption.location) || targetObj === '*' )
            && (targetSubobj === Location.getLocationSubObject(keyOption.location) || targetSubobj === '*') &&
             !locationsOnFloor.find( location => location.locationId === keyOption.location.locationId)) {
              floorForUndistributedControllers.locations.push(keyOption.location);
            }
          })



          if (floorForUndistributedControllers.locations.length>0) {
            floorList.push(floorForUndistributedControllers)
          }
          return floorList;
  }

}
