import { UserType } from './user-type.model';
import { Card } from '../card/card.model';
import { Role } from '../role.model';
import { Permission } from '../permissions/permission.model';

export class User {
    userId: number;
    id3dParty: string;
    typeId: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    refreshToken: string;
    customData: any;
    cards: Card[];
    type: UserType;
    fullName: string;
    cardLaters: Card[];
    token: string;
    permissions: any[];
    roles: Role[];
    activeUser: boolean;
    pin: string;
    licencePlate: string;

    constructor() {}


    public static isAdria(user: User) {
      return user.userId === 1
    }

    public static hasAnyOfRequiredPermissions(user: User, permissionIds: number[]){
      let userHasPermission = false;
      userHasPermission = user?.roles?.some((role: Role)=> {
          return role?.permissions?.some((rolePerm: Permission)=> {
            return permissionIds.includes(rolePerm.permissionId)
          })
        })
      if (!userHasPermission) {
          userHasPermission = user?.permissions?.some((perm: Permission)=> {
              return permissionIds.includes(perm.permissionId)
          })
        }
      return userHasPermission
    }

    havePermission(permissionId: number) {
        let userPermission = false;
        userPermission = this.roles?.some(role => role.permissions.some(permission => permission.permissionId === permissionId));
        if (!userPermission) {
           userPermission = this.permissions?.some(permission => permission.permissionId === permissionId);
        }
        return userPermission;
    }

    haveRole(roleId: number) {
        return this.roles?.some(role => role.roleId === roleId);
    }
}
