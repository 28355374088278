import { Injectable } from '@angular/core';

export interface IPropDictionary {
  entries: { value: string; text: string;textShort: string; iconOn: boolean }[];
  noEntryFound: {
    text: string;
    iconOn: boolean;
    textShort: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PropDictionaryService {
  daikinUnitStatusDictionary: IPropDictionary = {
    entries: [
      { value: '0', text: 'Daikin status error', textShort: 'Daikin status error', iconOn: false },
      { value: '1', text: 'Daikin status OK', textShort: 'Daikin status OK', iconOn: true },
      { value: '-1', text: 'Daikin status NC', textShort: 'Daikin status NC', iconOn: false },
    ],
    noEntryFound: {
      text: 'Daikin status undefined', textShort: 'Daikin status und.',
      iconOn: false,
    },
  };

  daikinUnitFanSpeedDictionary: IPropDictionary = {
    entries: [
      { value: '0', text: 'Daikin fan speed low', textShort: 'Daikin fan speed low', iconOn: true },
      { value: '1', text: 'Daikin fan speed high', textShort: 'Daikin fan speed high', iconOn: true },
      { value: '-1', text: 'Daikin fan speed unknown', textShort: 'Daikin fan speed unk.', iconOn: false },
    ],
    noEntryFound: {
      text: 'Daikin fan speed undefined', textShort: 'Daikin fan speed und.',
      iconOn: false,
    },
  };

  daikinUnitOperationModeDictionary: IPropDictionary = {
    entries: [
      { value: '1', text: 'Daikin mode fan', textShort: 'Daikin mode fan', iconOn: true },
      { value: '2', text: 'Daikin mode heat', textShort: 'Daikin mode heat', iconOn: true },
      { value: '4', text: 'Daikin mode cool', textShort: 'Daikin mode cool', iconOn: true },
      { value: '16', text: 'Daikin mode set point', textShort: 'Daikin mode set point', iconOn: true },
      { value: '32', text: 'Daikin mode ventilation', textShort: 'Daikin mode ventilation', iconOn: true },
      { value: '64', text: 'Daikin mode dry', textShort: 'Daikin mode dry', iconOn: true },
      { value: '256', text: 'Daikin Auto (Heat)', textShort: 'Daikin Auto (Heat)', iconOn: true },
      { value: '512', text: 'Daikin Auto (cool)', textShort: 'Daikin Auto (cool)', iconOn: true },
      { value: '4096', text: 'Daikin mode unknown', textShort: 'Daikin mode unknown', iconOn: false },
    ],
    noEntryFound: {
      text: 'Daikin mode undefined',
       textShort: 'Daikin mode undef.',
      iconOn: false,
    },
  };

  lgUnitOperationModeDictionary: IPropDictionary = {
    entries: [
      { value: '0', text: 'LG mode Cooling', textShort: 'LG mode cooling', iconOn: true },
      { value: '2', text: 'LG mode Fan', textShort: 'LG mode Fan', iconOn: true },
      { value: '3', text: 'LG mode Auto', textShort: 'LG mode Auto', iconOn: true },
      { value: '4', text: 'LG mode Heating', textShort: 'LG mode Heating', iconOn: true },
    ],
    noEntryFound: {
      text: 'LG mode undefined', textShort: 'LG mode und.',
      iconOn: false,
    },
  };


  lgUnitFanSpeedDictionary: IPropDictionary = {
    entries: [
      { value: '1', text: 'LG fan speed low', textShort: 'LG fan speed low', iconOn: true },
      { value: '2', text: 'LG fan speed mid', textShort: 'LG fan speed mid', iconOn: true },
      { value: '3', text: 'LG fan speed high', textShort: 'LG fan speed high', iconOn: true },
      { value: '4', text: 'LG fan speed auto', textShort: 'LG fan speed auto', iconOn: true },
      { value: '7', text: 'LG fan speed super high', textShort: 'LG fan speed super h.', iconOn: true },

    ],
    noEntryFound: {
      text: 'LG fan speed undefined', textShort: 'LG fan speed und.',
      iconOn: false,
    },
  };

  //lgtodo
  lgUnitStatusDictionary: IPropDictionary = {
    entries: [
      { value: '-1', text: 'Lg status  -1', textShort: 'Lg status -1', iconOn: false },
      { value: '0', text: 'Lg status 0', textShort: 'Lg status 0', iconOn: false },
      { value: '1', text: 'Lg status 1', textShort: 'Lg status 1', iconOn: true },
      { value: '2', text: 'Lg status 2', textShort: 'Lg status 2', iconOn: false },
      { value: '3', text: 'Lg status 3', textShort: 'Lg status 3', iconOn: false },
      { value: '4', text: 'Lg status 4', textShort: 'Lg status 4', iconOn: false },
      { value: '5', text: 'Lg status 5', textShort: 'Lg status 5', iconOn: false },
      { value: '6', text: 'Lg status 6', textShort: 'Lg status 6', iconOn: false },
      { value: '7', text: 'Lg status 7', textShort: 'Lg status 7', iconOn: false },
      { value: '8', text: 'Lg status 8', textShort: 'Lg status 8', iconOn: false },
    ],
    noEntryFound: {
      text: 'Lg status undefined', textShort: 'Lg status und.',
      iconOn: false,
    },
  };

}
