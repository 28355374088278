import { ProjectSubObject } from "./project-sub-object.model";

export class ProjectObject {
  projectObjectId: number;
  name: string;
  designation: string;
  projectSubobjects?: SubObjects;



  public static getObjectById(objectId: number, allObjects: ProjectObject[]): ProjectObject  {
    return allObjects.find((obj)=> {
      return obj.projectObjectId === objectId;
    })
  }

  public static getDesignationObject(object: ProjectObject): string {
    return object.designation.split('/')[0]
  }
}

export class SubObjects {
  $type: string
  $values: ProjectSubObject[]
}
