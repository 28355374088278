import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, OnDestroy/* , OnChanges */ {
  @Input() set controllers(value: Controller[]) {
    this._controllers = value;
    this.targetValue = null;
  };
  _controllers: Controller[]

  @Input() findPropFunction: (property: Property) => boolean;
  @Input() findPropFunctionSecondaryA: (property: Property) => boolean;
  @Input() findPropFunctionSecondaryB: (property: Property) => boolean;

  inputDelay = 2000;
  resetInputDelay = 3000;
  targetValue: number;
  valueSet$ = new Subject<[number, Controller[]]>();
  valueSubscription = new Subscription();
  resetTargetSubscription = new Subscription();

  loading = false;

  constructor(private apiProjectService: ApiProjectService) {}

  ngOnInit(): void {
    // this.originalControllers = structuredClone(this._controllers);
    this.valueSubscription = this.valueSet$
      .pipe(
        debounceTime(this.inputDelay),
        )
      .subscribe((newValue: [number, Controller[]]) => {
        this.loading = true;
        let property: Property;
        const parentController: Controller = newValue[1].find(
          (controller: Controller) => {
            property = controller.controllerProperties.$values.find(this.findPropFunction);
            return property;
          }
        );
        // this.valueChanged = false;
        const designation = parentController.designation;
        const propertyId = property.id;
        const trgtValue =
          this.findPropFunctionSecondaryA && this.findPropFunctionSecondaryB ?
          this.getTargetValue(parentController, newValue[0]):
           newValue[0]
        this.apiProjectService
          .changeProperty(designation, propertyId, trgtValue)
          .subscribe( () => this.loading = false);
      });
    this.resetTargetSubscription = this.valueSet$
      .pipe(debounceTime(this.resetInputDelay))
      .subscribe(() => {
        this.targetValue = null;
      });
  }

  getTargetValue(parentController: Controller, trgtValue: number) {
    const secondaryPropA = parentController.controllerProperties.$values.find(this.findPropFunctionSecondaryA);
    const secondaryPropB = parentController.controllerProperties.$values.find(this.findPropFunctionSecondaryB);
    const secondaryAValue = secondaryPropA === undefined ? 0 : Number(secondaryPropA?.value);
    const secondaryBValue = secondaryPropB === undefined ? 0 : Number(secondaryPropB?.value);

    const maxTotal = 40;
    if (trgtValue + secondaryAValue + secondaryBValue <= maxTotal) {
      return trgtValue;
    } else {
      return maxTotal - secondaryAValue - secondaryBValue
    }
  }

  /*   ngOnChanges() {
    if (this.valueChanged) {
      setTimeout(() => {
        this.originalControllers = structuredClone(this._controllers);
      },4000)
    } else {
      this.originalControllers = structuredClone(this._controllers);
    }
  } */

  decrement() {
    // this.valueChanged = true;
    if (!this.targetValue) {
      let property: Property;
      this._controllers.find((controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.findPropFunction);
        return property;
      });
      this.targetValue = property.value;
    }
    this.targetValue--;
    this.valueSet$.next([this.targetValue, structuredClone(this._controllers)]);
  }

  increment() {
    // this.valueChanged = true;
    if (!this.targetValue) {
      let property: Property;
      this._controllers.find((controller: Controller) => {
        property = controller.controllerProperties.$values.find(this.findPropFunction);
        return property;
      });
      this.targetValue = property.value;
    }
    this.targetValue++;
    this.valueSet$.next([this.targetValue, structuredClone(this._controllers)]);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.valueSubscription.unsubscribe();
    }, this.resetInputDelay);
    this.resetTargetSubscription.unsubscribe();
  }
}
