import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AuthenticationService } from './../../core/authentication/authentication.service';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from './../../core/services/user.service';
import { API_BASE_URL, DEMO_MODE } from 'src/environments/environment';
import { UrlService } from 'src/app/shared/services/url.service';
import { ApiProjectService, LicenceData } from 'src/app/modules/project/services/http/api-project.service';
import { DemoModeData, DemoModeService } from 'src/app/shared/services/demo-mode.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  user: User = new User();
  forgotPassword = false;
  passwordHasReset = false;
  emailForgotenPassword: string;
  interval;
  timeout;
  refreshIt = true;

  constructor(private auth: AuthenticationService,
              private userService: UserService,
              private router: Router,
              private http: HttpClient,
              private urlService: UrlService,
              private location: Location,
              private apiProjectService: ApiProjectService,
              private demoModeService: DemoModeService) { }

  ngOnInit() {
    this.apiProjectService.getProjectId().subscribe (projectID => {
      if (projectID === '' || projectID === null) {
        this.router.navigate(['/projectID'])
      } else {
        this.apiProjectService.getLicence().subscribe( (data:LicenceData) => {
          if (data.isValid) {
            if (this.urlService.lastUrl && this.isLoggedIn() && !this.urlService.lastUrl.includes('login') && !this.urlService.lastUrl.includes('license')) {
              this.router.navigate([this.urlService.lastUrl]);
            }
        
            this.interval = setInterval(() => {
            if (this.isLoggedIn()) {
                if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login') && !this.urlService.lastUrl.includes('license')) {
                  this.router.navigate([this.urlService.lastUrl]);
                } else {
                  this.router.navigate(['/rooms']);
                  }
                  this.timeout = setTimeout( () => {
                    if (this.refreshIt) {
                      this.location.replaceState('/rooms');
                      window.location.reload()
                    }
                  },3000)
              }
            }, 2000);
          } else {
            this.router.navigate(['/license'])
          }
          })
      }
    })
  }

  

  onLogin(form: NgForm) {
    if (form.valid) {
      if (!DEMO_MODE) {
        this.auth.login(this.user).subscribe(
          response => {
            this.userService.setUser(response.user);
            if (this.urlService.lastUrl && !this.urlService.lastUrl.includes('login')  && !this.urlService.lastUrl.includes('license')) {
              this.router.navigate([this.urlService.lastUrl]);
            } else {
              this.router.navigate(['/rooms']);
            }
          }
        );
      } else {
        this.auth.demoLogin(this.user).subscribe( response => {
          this.demoModeService.getData().subscribe ( (value: DemoModeData) => {
             this.userService.setUser(value.User)
             this.router.navigate(['/rooms']);  
          })
        })

      }

    }
  }

  isLoggedIn(): boolean {
    return this.auth.isValid();
  }

  backToLogin() {
    this.forgotPassword = false;
    this.passwordHasReset = false;
    this.emailForgotenPassword = '';
  }

  onResetPassword(form: NgForm) {
    if (form.valid) {
      this. passwordHasReset = true;
      this.apiResetPassword(this.emailForgotenPassword).subscribe();
    }
  }

  apiResetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<User[]>(API_BASE_URL + '/login/reset', {params});
  }

  continue() {
    this.router.navigateByUrl('/rooms');
  }

  ngOnDestroy(): void {
    this.refreshIt = false;
    if (this.interval) {
      clearInterval(this.interval);
   }
    if(this.timeout) {
      clearTimeout(this.timeout);
    }
  }

}
