import { Pipe, PipeTransform } from '@angular/core';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { Location } from 'src/app/core/models/project/location.model';

@Pipe({
  name: 'getLocationsInSubObject'
})
export class GetLocationsInSubObjectPipe implements PipeTransform {

  transform(locations: Location[], subObject: ProjectSubObject): Location[] {
    if (!subObject) {
      return locations;
    }
      const locationsInObject = locations.filter((loc )=> {
        return Location.getLocationSubObject(loc) === ProjectSubObject.getDesignationSubobject(subObject)
      })
      return locationsInObject

  }
}
