<div class="modal-background-layer">
    <div class="modal-container">
        <div class="modal-header">
            <div [ngSwitch]="type">
                <div *ngSwitchCase="'transferpermissions'">
                    <h2 >{{'You can transfer permission ownership to these users' | translate}}:</h2>
                    <select [(ngModel)]="selectedId">
                        <option value="null" disabled>{{loading? 'Loading...' : 'Choose user to transfer'}}</option>
                        <option [ngValue]="user.userId" *ngFor="let user of content">{{user.firstName + ' ' + user.lastName}}</option>
                    </select>
                </div>
                <div *ngSwitchCase="'transferroles'">
                    <h2 >{{'You can transfer role ownership to these users' | translate}}:</h2>
                    <select [(ngModel)]="selectedId">
                        <option value="null" disabled>{{loading? 'Loading...' : 'Choose user to transfer'}}</option>
                        <option [ngValue]="user.userId" *ngFor="let user of content">{{user.firstName + ' ' + user.lastName}}</option>
                    </select>
                </div>
                <div *ngSwitchDefault>
                    <h2>{{'Select' | translate}}</h2>
                </div>
            </div>
        </div>
        <div class="modal-button-box">
            <button (click)="decisionClick(false)">{{'Cancel' | translate}}</button>
            <button (click)="decisionClick(true)">{{'Transfer' | translate}}</button>
        </div>
    </div>
</div>
