import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import propertyDisplayConfigMinimal from 'src/assets/config/propertyDisplayConfigMinimal.json';
import { Controller } from 'src/app/core/models/controller.model';
import { User } from 'src/app/core/models/user/user.model';
import { RoomModalComponent } from '../components/room-modal/room-modal.component';
import { ModalController } from '@ionic/angular';

export type RoomViewSize = 'full' | 'scaledDown' | 'minimal' | 'split';

export interface MinimalViewPrimaryProperty {
  id: number,
  codeFrom: number,
  codeTo: number,
  activeCondition: string,
  activeName?: string,
  activeIcon?: string,
  activeTooltip?: string,
  inactiveName?: string,
  inactiveIcon?: string,
  inactiveTooltip?: string,
  statusName?: string,
  sufix?: string
}

export interface StoredViewSize {
  userId: number;
  view: RoomViewSize;
}

@Injectable({
  providedIn: 'root'
})
export class RoomsOverviewService {

  defaultViewSize: RoomViewSize = 'full'
  private viewSize$ = new BehaviorSubject<RoomViewSize>(this.defaultViewSize);
  private isFromCommonAreas$ = new BehaviorSubject<boolean>(false);
  // private floorFilterParams$ = new BehaviorSubject<FloorFilterParams[]>(null);

  private minimalViewPrimaryProperties$ = new BehaviorSubject<any[]>(propertyDisplayConfigMinimal);
  private minimalViewPrimaryProperty$  = new BehaviorSubject<any>(propertyDisplayConfigMinimal[0]);
  controllersForFacility: Controller[];

  selectedViewSizeLocalStorageKey = 'irooms_selected_view_size';
  loadedViewSizeForUserId: number;

  selectedFloorFilterLocalStorageKey = 'irooms_selected_floor_filter';
  loadedFloorFilterForUserId: number;
  private retroMode$ = new BehaviorSubject<boolean>(false);

  constructor(private modalController: ModalController) {
    }

  getMinimalViewProperties() {
    return this.minimalViewPrimaryProperties$.asObservable();
  }

  getMinimalViewPrimaryProp() {
    return this.minimalViewPrimaryProperty$.asObservable();
  }

  setMinimalViewPrimaryProp(id: number) {
    const prop = this.minimalViewPrimaryProperties$.getValue().find((propConfig)=> {
      return Number(propConfig.propInfo.id) === Number(id)
    })
    this.minimalViewPrimaryProperty$.next(prop);
  }

 /*  setFacilityControllers(controllers: Controller[]) {
    this.controllersForFacility = controllers;
  }

  getFacilityControllers() {
    return this.controllersForFacility;
  } */

  // VIEW SIZE

  getViewSize() {
    return this.viewSize$.asObservable();
  }

  getIsFromCommonAreas() {
    return this.isFromCommonAreas$.asObservable();
  }

  setViewSize(viewSize: RoomViewSize, user: User, inCommonAreas?: boolean) {
    // modified this so when we go to common areas from rooms the view is set to full if split was selected
    // but not saving to storage otherwise it wouldn't show properly!
    if(viewSize === 'split' && inCommonAreas === true){
      this.viewSize$.next("full");
      this.isFromCommonAreas$.next(true);
    }else{
      this.storeSelectedViewSize(viewSize, user);
      this.isFromCommonAreas$.next(false);
      this.viewSize$.next(viewSize);
    }
  }

  changeViewSize(user: User, type: RoomViewSize) {
    if (type === 'full') {
      this.setViewSize('full', user)
    } else if (type === 'scaledDown') {
      this.setViewSize('scaledDown', user)
    } else if (type === 'minimal') {
      this.setViewSize('minimal', user)
    }else if (type === 'split') {
      this.setViewSize('split', user)
    }
  }

  storeSelectedViewSize(viewSize: RoomViewSize, activeUser: User) {
    const storedSelectedViewSizeString: string = localStorage.getItem(this.selectedViewSizeLocalStorageKey);
    const newStoredViewSize: StoredViewSize = {
      userId: activeUser.userId,
      view: viewSize
    }
    if (storedSelectedViewSizeString === null) {
      const storedViewSize: StoredViewSize [] = [newStoredViewSize]
      localStorage.setItem(this.selectedViewSizeLocalStorageKey, JSON.stringify(storedViewSize));
    } else {
      const storedSelectedViewSizes: StoredViewSize [] = JSON.parse(storedSelectedViewSizeString);
      const newStoredViewSizes = storedSelectedViewSizes
        .filter((storedViewSize: StoredViewSize) => storedViewSize.userId !== activeUser.userId)
      newStoredViewSizes.push(newStoredViewSize);
      localStorage.setItem(this.selectedViewSizeLocalStorageKey, JSON.stringify(newStoredViewSizes));
    }
  }

  initializeViewSizeFromStorage(user: User) {
    if (this.loadedViewSizeForUserId !== user.userId) { // check if filters are already loaded for this user
      const storedSelectedViewSizeString: StoredViewSize [] = JSON.parse(localStorage.getItem(this.selectedViewSizeLocalStorageKey));
      const storedViewSizeForUser = storedSelectedViewSizeString?.find(
        (storedViewSize: StoredViewSize) => Number(storedViewSize.userId) === Number(user.userId))
      if (storedViewSizeForUser) {
        this.setViewSize(storedViewSizeForUser.view, user)
        this.loadedViewSizeForUserId = user.userId;
      } else {
        this.setViewSize('full', user);
      }
    }

  }

  getRetroMode() {
    return this.retroMode$.asObservable();
  }

  setRetroMode(value:boolean) {
    return this.retroMode$.next(value);
  }

  async onClickLocation(targetObject, targetSubObject, targetZone, locId, targetView?) {
    let props;
    if (!targetView) {
      props = {
        object: targetObject,
        subObject: targetSubObject,
        zone: targetZone,
        locationId: locId
        // startingView: 'settings'
      }
    } else {
      props = {
        object: targetObject,
        subObject: targetSubObject,
        zone: targetZone,
        locationId: locId,
        startingView: targetView
      }
    }
    const modal = await this.modalController.create({
      component: RoomModalComponent,
      cssClass: 'room-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps:  props
    });
    return await modal.present();
  }


  // FLOOR FILTER

/*   resetFloorFilter(user?: User) {
    combineLatest([
      this.projectService.getProject(),
      this.projectService.getSelectedObject(),
      this.locationGroupsService.getFloorList()
    ])
    .pipe(take(1))
    .subscribe(([project, selectedObjectGlobal, allFloors]) => {
      const objects = (Number(selectedObjectGlobal) === -1? Project.getObjects(project):[selectedObjectGlobal]);
      let floorFilterParams = [];
      objects.forEach((obj)=> {
        const subObjects = Project.getSubobjects(project, obj)
        const params = subObjects.map((subObj)=> {
          const objSubobjFloors = allFloors.reduce ((floors, fl)=>{
            const floorIsOnSubObject: boolean = fl.locationIds.every((locationId)=> {
              const ob = locationId.split('/')[0];
              const subOb = locationId.split('/')[1];
              return Number(ob) === Number(obj) && Number(subOb) === Number(subObj)
            })
            if (floorIsOnSubObject) {
              floors.push({
                floorId: fl.id,
                name: fl.name,
                autoGenerated: false,
                show: true
              })
            }
            return floors
          }, [])
          if (objSubobjFloors.length > 0) {
            return {
              object: obj,
              subObject: subObj,
              floors: objSubobjFloors
            };
          } else {
            const autoGeneratedObjSubobjFloors = Project.getGeneratedFloors(project, obj, subObj ).map((gFl)=> {
              return  {
                floorId: null,
                name: gFl,
                autoGenerated: true,
                show: true
              }
            })
            return {
              object: obj,
              subObject: subObj,
              floors: autoGeneratedObjSubobjFloors
            }
          }
        })
        floorFilterParams = [...floorFilterParams, ...params]
      })
      this.setFloorFilterParams(floorFilterParams, user);
    });
  } */

  /* getFloorFilterParams() {
    return this.floorFilterParams$.asObservable();
  }

  setFloorFilterParams(floorFilterParams, user: User) {
    this.storeFloorFilterParams(floorFilterParams, user)
    return this.floorFilterParams$.next(floorFilterParams);
  }

  changeFloorFilter(floor, object, subObject, newValue: boolean, user: User ) {
    const floorFilterParams = JSON.parse (JSON.stringify(this.floorFilterParams$.getValue()));
    const objParams = floorFilterParams.find((params: FloorFilterParams)=> {
      return Number(params.subObject) === Number (subObject) && Number(params.object) === Number (object)
    })
    if (floor.autoGenerated) {
      const fl = objParams.floors.find((f)=>{
        return Number(floor.name) === Number (f.name)
      })
      fl.show = newValue;
    } else {
      const fl = objParams.floors.find((f)=>{
        return Number(floor.floorId) === Number (f.floorId)
      })
      fl.show = newValue;
    }
    this.setFloorFilterParams(floorFilterParams, user);
  }

  storeFloorFilterParams(floorFilterParams, user: User) {
    const storedSelectedFloorFilterParamsString: string = localStorage.getItem(this.selectedFloorFilterLocalStorageKey);
    const newFloorFilterParams = {
      userId: user.userId,
      filters: floorFilterParams
    }
    if (storedSelectedFloorFilterParamsString === null) {
      const storedFloorFilterParams: any[] = [newFloorFilterParams]
      localStorage.setItem(this.selectedFloorFilterLocalStorageKey, JSON.stringify(storedFloorFilterParams));
    } else {
      const storedSelectedFloorFilterParams: any [] = JSON.parse(storedSelectedFloorFilterParamsString);
      const newStoredFloorFilterParams = storedSelectedFloorFilterParams
        .filter((storedFloorFilterParams ) => storedFloorFilterParams.userId !== user.userId)
      newStoredFloorFilterParams.push(newFloorFilterParams);
      localStorage.setItem(this.selectedFloorFilterLocalStorageKey, JSON.stringify(newStoredFloorFilterParams));
    }
  }

  initializeFloorFilterFromStorage(user: User) {
    if (this.loadedFloorFilterForUserId !== user.userId) { // check if filters are already loaded for this user
      const storedFloorFiltersString: any [] = JSON.parse(localStorage.getItem(this.selectedFloorFilterLocalStorageKey));
      const storedFloorFilterForUser = storedFloorFiltersString?.find(
        (floorFilter) => Number(floorFilter.userId) === Number(user.userId))
      if (storedFloorFilterForUser) {
        if (storedFloorFilterForUser.filters.length == 0) {
          this.resetFloorFilter(user);
        } else {
          this.setFloorFilterParams(storedFloorFilterForUser.filters, user)
        }
        this.loadedFloorFilterForUserId = user.userId;
      } else {
        this.resetFloorFilter(user);
      }
    }
  }

  selectAllFloors(user: User) {
    const oldFloorFilters: FloorFilterParams[] = JSON.parse(
      JSON.stringify(this.floorFilterParams$.getValue())
    );
    const newFloorFilters = oldFloorFilters.map((flFilter) => {
      const newFloors = flFilter.floors.map((fl) => {
        const newFl = fl;
        newFl.show = true;
        return newFl;
      });
      const newFloorFilter = flFilter;
      newFloorFilter.floors = newFloors;
      return newFloorFilter;
    });
    this.setFloorFilterParams(newFloorFilters, user);
  } */

}
