import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DaliProject } from 'src/app/core/models/dali/dali-project.model';
import { API_BASE_URL, USE_DALI } from 'src/environments/environment';
import { DaliProjectService } from '../dali-project.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDaliProjectService {


  constructor(
    private daliProjectService: DaliProjectService,
    private http: HttpClient) {}

  getProject() {
    return this.http.get<DaliProject>(API_BASE_URL + '/dali/project').pipe(
      tap( (daliProj) => {
              this.daliProjectService.setDaliProject(daliProj);
      })
  );
  }

  setProject(body: DaliProject) {
    if (USE_DALI) {
      return this.http.put(API_BASE_URL + '/dali/project', body).pipe(
        tap(() => {
                this.daliProjectService.setDaliProject(body);
            })
      );
    }
  }

  activateDaliScene(daliSceneId: string) {
    const params = new HttpParams().set('value', '1');
    return this.http.post(`${API_BASE_URL}/dali/scene/${daliSceneId}`, {}, { params });
  }

  deactivateDaliScene(daliSceneId: string) {
    const params = new HttpParams().set('value', '0');
    return this.http.post(`${API_BASE_URL}/dali/scene/${daliSceneId}`, {}, { params });
  }

  setDaliSceneToValue(daliSceneId: string, value) {
    const params = new HttpParams().set('value', value.toString());
    return this.http.post(`${API_BASE_URL}/dali/scene/${daliSceneId}`, {}, { params });
  }

  getBaseDaliMqttSubscription() {
      return this.http.get(`${API_BASE_URL}/dali/mqtt/basetopic`, {responseType: 'text'});
  }

}
