import { Pipe, PipeTransform } from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'isBurglaryDetectedProp'
})
export class IsBurglaryDetectedPropPipe implements PipeTransform {
  transform(property: Property): boolean {
    return Property.isBurglaryDetectedDoor(property) ||
     Property.isBurglaryDetectedByPIR(property) ||
      Property.isBurglaryDetectedWindow(property);
  }
}
