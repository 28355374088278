import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-edit-controller',
  templateUrl: './edit-controller.component.html',
  styleUrls: ['./edit-controller.component.scss'],
})
export class EditControllerComponent implements OnInit {
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() controllerInput;

  controller: Controller;
  submitted = false;

  constructor(private apiProjectService: ApiProjectService) {}

  ngOnInit(): void {
    // this.controller = JSON.parse(JSON.stringify(this.controllerInput));
    this.controller = structuredClone(this.controllerInput);
  }

  // clickIsGuestRoom(value: boolean) {
  //   if (value === true) {
  //     this.controller.isGuestRoom = true;
  //   } else if (value === false) {
  //     this.controller.isGuestRoom = false;
  //   }
  // }

  // clickIsAccessControl(value: boolean) {
  //   if (value === true) {p
  //     this.controller.isAccessControl = true;
  //   } else if (value === false) {
  //     this.controller.isAccessControl = false;
  //   }
  // }

  clickIsRestoreDefaultPropertiesOnCheckout(value: boolean) {
    if (value === true) {
      this.controller.restoreDefaultOnCheckout = true;
    } else if (value === false) {
      this.controller.restoreDefaultOnCheckout = false;
    }
  }

  decisionClick(choice: boolean) {
    if (choice === true) {
      const updatedControllers = [];
      updatedControllers.push(this.controller);
      this.submitted = true;
      this.apiProjectService
        .updateControllers(updatedControllers)
        .subscribe(() => {
          this.cancelEvent.next(true);
        });
    } else {
      this.cancelEvent.next(true);
    }
  }
}
