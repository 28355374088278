import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CardsTabSelectedService {

  private selectedTab: string = 'staff';

  constructor() { }

  setSelectedTab(tab: string) {
    this.selectedTab = tab;
  }

  getSelectedTab() {
    return this.selectedTab;
  }
}
