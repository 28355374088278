import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CardType } from 'src/app/core/models/card/card-type.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { User } from 'src/app/core/models/user/user.model';
import { DefaultsPreset, FrontendSettings } from 'src/app/modules/settings/services/http/api-settings.service';
import { LocationGroup } from './api-location-groups.service';
import { Observable, lastValueFrom} from 'rxjs';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { Location } from 'src/app/core/models/project/location.model';
import { AlarmType } from 'src/app/core/models/alarms/alarm-type.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { LocationGroupsService } from './location-groups.service';
import { AlarmSubscriptionsService } from 'src/app/modules/alarms/services/alarm-subscriptions.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Card } from 'src/app/core/models/card/card.model';
import { GuestCard } from 'src/app/modules/users/services/http/api-users.service';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { HowToArticle } from 'src/app/modules/how-to/services/api-how-to.service';
import { AutomationService } from 'src/app/modules/automation/services/automation.service';
import { Automation } from 'src/app/core/models/automation/automation.model';
import { AccessLog } from 'src/app/core/models/logs/access-log.model';
import { AlarmLog } from 'src/app/core/models/logs/alarm-log.model';
import { ValueGraph } from 'src/app/core/models/logs/value-graph.model';
import { HvacMode } from 'src/app/core/models/hvac-modes/hvac-mode.model';
import { HvacModesService } from 'src/app/modules/groups/services/hvac-modes.service';
import { Property } from 'src/app/core/models/project/property.model';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { UserType } from 'src/app/core/models/user/user-type.model';
import { AuditLog } from 'src/app/core/models/logs/audit-log.model';
import { CardEventLog } from 'src/app/core/models/logs/card-event-log.model';
import { Role } from 'src/app/core/models/role.model';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { LocationsFilter } from 'src/app/core/models/locations-filter.model';
import { StoredFilter } from 'src/app/core/models/project/stored-filter.model';

export interface DemoModeData {
    RoomsFat: Location[];
    CommonAreaFat: Location[]
    LocationsFat: Location[];
    User: User;
    FrontendSettings: FrontendSettings;
    CardTypes: CardType[];
    Objects: ProjectObject[],
    Subobjects: ProjectSubObject[],
    Groups:LocationGroup [];
    Floors:LocationGroup [];
    Presets: DefaultsPreset[];
    UnconfirmedAlarms: AlarmAlert[];
    AlarmTypes: AlarmType[];
    StaffCards: Card[];
    GuestCards: GuestCard[];
    Pagination: PaginationData;
    GuestCardsByLocation: Card[],
    HowToArticle: HowToArticle[],
    KeyOption: KeyOption[];
    Automations: Automation[],
    AccessLog: AccessLog[],
    AlarmLog: AlarmLog[];
    AuditLog: AuditLog[];
    CardEventLog: CardEventLog[]
    ValueLog: ValueGraph[];
    TemperatureLog: ValueGraph[];
    TableProperties: string[];
    HvacModes: HvacMode[];
    PresetProps: Property[];
    CrossReff: LocationPresetCrossRef[],
    Staff: User[]
    UserTypes: UserType[],
    CardEventGraphData: any,
    Roles: Role[],
    Permissions: Permission[];
  }

@Injectable({
  providedIn: 'root'
})
export class DemoModeService {

  private readonly DEMO_DATA = 'assets/demo-mode.json';
        constructor(private http: HttpClient,
                    private settingsService: SettingsService,
                    private cardsService: CardsService,
                    private projectService: ProjectService,
                    private locationGroupsService: LocationGroupsService,
                    private alarmSubscriptionsService: AlarmSubscriptionsService,
                    private apiProjectService: ApiProjectService,
                    private currentUserStoreService: CurrentUserStoreService,
                    private automationService: AutomationService,
                    private hvacModesService: HvacModesService,
                    private usersService: UsersService,
                    private rolesService: RolesService
                  ) { }


        getData(): Observable<DemoModeData> {
            return this.http.get<DemoModeData>(this.DEMO_DATA);
          }

        setupDemoProject() {
           this.getData().subscribe( (value:DemoModeData) => {
               this.currentUserStoreService.setUser(value.User)
                this.settingsService.setFrontendSettings(value.FrontendSettings);
                this.settingsService.setupViewConfigs();
                this.settingsService.initializeViewFromStorage(value.User);
                this.settingsService.initializeGuestStayShortcut(value.User);
                this.cardsService.setCardTypes(value.CardTypes);
                this.projectService.setObjects(value.Objects);
                this.projectService.setSubObjects(value.Subobjects);
                this.locationGroupsService.setFloorList(value.Floors);
                this.alarmSubscriptionsService.setAlarmTypes(value.AlarmTypes);
                this.projectService.setAllLocations(value.LocationsFat, true)
                this.apiProjectService.projectReady$.next(true);
                this.projectService.setLoadingFilteredLocations(false)
                this.locationGroupsService.setLocationGroups(value.Groups);
                this.automationService.setAutomations(value.Automations);
                this.hvacModesService.setHvacModes(value.HvacModes);
                this.settingsService.setDefaultPresets(value.Presets);
                this.usersService.setUserTypes(value.UserTypes);
                this.usersService.setUsers(value.Staff);
                this.usersService.setNonGuestUsers(value.Staff)
                this.rolesService.setRoles(value.Roles);
                this.rolesService.setPermissions(value.Permissions);
            })
        }

        setRoomsLocations() {
            // this.projectService.setFilteredLocations(value.RoomsFat)
            let demoUserFilters = StoredFilter.getGuestRoomFilterFromStorage(1).filters
            demoUserFilters = demoUserFilters?demoUserFilters: new LocationsFilter([1])

            this.setFilteredDemoProjectRooms(demoUserFilters)
            this.projectService.setLoadingFilteredLocations(false)
            this.apiProjectService.filteredFatLocationsIsReady$.next(true);
        }

        setCommonAreaLocations() {
            // this.projectService.setFilteredLocations(value.CommonAreaFat)
            let demoUserFilters = StoredFilter.getCommonAreaFilterFromStorage(1).filters
            demoUserFilters = demoUserFilters?demoUserFilters: new LocationsFilter([2,3,4])
            this.setFilteredDemoProjectCommonAreas(demoUserFilters)
            this.projectService.setLoadingFilteredLocations(false)
            this.apiProjectService.filteredFatLocationsIsReady$.next(true);
        }



        async setFilteredDemoProjectRooms(controllerFilters: LocationsFilter) {
          const demoModeData = await lastValueFrom(this.http.get<DemoModeData>(this.DEMO_DATA))

          let filteredLocations =  demoModeData.RoomsFat.filter((location: Location)=> {
            let showController = true;

            // status - online
            if (controllerFilters.customControllerFilter.Statuses.Online === true && !location.isOnline) {
              showController = false
            } else if ( controllerFilters.customControllerFilter.Statuses.Online === false && location.isOnline) {
              showController = false
            }
            // status - rented
            else if (controllerFilters.customControllerFilter.Statuses.Rented === true && !location.isRented) {
              showController = false
            } else if ( controllerFilters.customControllerFilter.Statuses.Rented === false && location.isRented) {
              showController = false
            }

            // hvac
            let hvacActive;
            let isHvacHeatCool;
            let roomFloorHeating;
            let v1;
            let v2;
            let v3;

            // cleaning
            let inspectedStatus;
            let makeUpRoom;
            let cleaningStatus;
            let highestCleaningStatus;

            // statuses
            let roomArmed
            let cardDeposited
            let cardTypeProp;
            let dndStatus
            let windowOpen;
            let doorOpen;
            let isIgnoreWindow;
            let isIgnoreCardTray;

            // alarms
            const alarms = {
               burglary : undefined,
               burglaryDetectedByPIR : undefined,
               burglaryDetectedWindow: undefined,
               burglaryDetectedDoor: undefined,
               safeBurglary: undefined,
               safeCommError: undefined,
               HVACLargeTempDiffRoom: undefined,
               HVACLargeTempDiffBathroom: undefined,
               flood: undefined,
               SOS: undefined,
               doorOpenTooLongAlarm: undefined,
               windowOpenTooLongAlarm: undefined,
            }




            location.controllers.$values[0].controllerProperties.$values.forEach((prop)=> {
              if (Property.isInspectedStatus(prop)) {
                inspectedStatus = prop;
              } else if (Property.isMakeUpRoom(prop)) {
                makeUpRoom  = prop;
              } else if (Property.isCleaningStatus(prop)) {
                cleaningStatus  = prop;
              } else if (Property.isHighestCleaningStatus(prop)) {
                highestCleaningStatus  = prop;
              }  else if (Property.isRoomArmed(prop)) {
                roomArmed  = prop;
              } else if (Property.isCardDeposited(prop)) {
                cardDeposited  = prop;
              } else if (Property.isCardType(prop)) {
                cardTypeProp  = prop;
              } else if (Property.isDndStatus(prop)) {
                dndStatus  = prop;
              } else if (Property.isWindowOpened(prop)) {
                windowOpen  = prop;
              } else if (Property.isDoorOpened(prop)) {
                doorOpen  = prop;
              } else if (Property.isIgnoreWindow(prop)) {
                isIgnoreWindow  = prop;
              } else if (Property.isIgnoreCardTray(prop)) {
                isIgnoreCardTray  = prop;
              } else if (Property.isHvacActive(prop)) {
                hvacActive  = prop;
              } else if (Property.isRoomFloorHeating(prop)) {
                roomFloorHeating  = prop;
              } else if (Property.isFancoilV1(prop)) {
                v1  = prop;
              } else if (Property.isFancoilV2(prop)) {
                v2  = prop;
              } else if (Property.isFancoilV3(prop)) {
                v3  = prop;
              }  else if (Property.isHvacHeatCool(prop)) {
                isHvacHeatCool  = prop;
              }
              // alarms
              else if (Property.isBurglary(prop)) {
                alarms.burglary  = prop;
              } else if (Property.isBurglaryDetectedByPIR(prop)) {
                alarms.burglaryDetectedByPIR  = prop;
              } else if (Property.isBurglaryDetectedWindow(prop)) {
                alarms.burglaryDetectedWindow  = prop;
              } else if (Property.isBurglaryDetectedDoor(prop)) {
                alarms.burglaryDetectedDoor  = prop;
              } else if (Property.isSafeBurglary(prop)) {
                alarms.safeBurglary  = prop;
              } else if (Property.isSafeCommError(prop)) {
                alarms.safeCommError  = prop;
              } else if (Property.isHVACLargeTempDiffRoom(prop)) {
                alarms.HVACLargeTempDiffRoom  = prop;
              } else if (Property.isHVACLargeTempDiffBathroom(prop)) {
                alarms.HVACLargeTempDiffBathroom  = prop;
              }  else if (Property.isFlood(prop)) {
                alarms.flood  = prop;
              } else if (Property.isSOS(prop)) {
                alarms.SOS  = prop;
              }
              if (Property.isDoorOpenTooLongAlarm(prop)) {
                alarms.doorOpenTooLongAlarm  = prop;
              }
               if (Property.isWindowOpenTooLongAlarm(prop)) {
                alarms.windowOpenTooLongAlarm  = prop;
              }

            })

            if (controllerFilters.customControllerFilter.Statuses.AlarmActive === true && !this.anyAlarmActive(alarms)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Statuses.AlarmActive === false && this.anyAlarmActive(alarms)) {
              showController = false
            }

            // hvac - hvac active
            if (!this.showControler(controllerFilters.customControllerFilter.Hvac.Active,hvacActive)) {
              showController = false
            }

            // hvac - room floorheating
            if (!this.showControler(controllerFilters.customControllerFilter.Hvac.FloorheatingRoom,roomFloorHeating)) {
              showController = false
            }

            // hvac - fan speed
            if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 1 &&
              (!v1 || Property.isInactive(v1) || !v1.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 2 &&
              (!v2 || Property.isInactive(v2) || !v2.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 3 &&
              (!v3 || Property.isInactive(v3) || !v3.value)) {
              showController = false
            }

            // hvac  - cooling

            if (controllerFilters.customControllerFilter.Hvac.Cooling === true &&
              (!isHvacHeatCool || +isHvacHeatCool.value !== 1 || !isHvacHeatCool.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.Cooling === false &&
              (!isHvacHeatCool || +isHvacHeatCool.value !== 0 || !isHvacHeatCool.value)) {
              showController = false
            }

            // hvac  - heating

            if (controllerFilters.customControllerFilter.Hvac.Heating === true &&
              (!isHvacHeatCool || +isHvacHeatCool.value !== 0 || !isHvacHeatCool.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.Heating === false &&
              (!isHvacHeatCool || +isHvacHeatCool.value !== 1 || !isHvacHeatCool.value)) {
              showController = false
            }




            // cleaning - inspected
            if (!this.showControler(controllerFilters.customControllerFilter.Cleaning.Inspected,inspectedStatus)) {
              showController = false
            }

              // cleaning - mur
              if (!this.showControler(controllerFilters.customControllerFilter.Cleaning.MakeUpRoom,makeUpRoom)) {
                showController = false
              }

            // cleaning - cleaned

             if (controllerFilters.customControllerFilter.Cleaning.Cleaned === 0 &&
              (!cleaningStatus || +cleaningStatus.value !== 0 || !cleaningStatus.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Cleaning.Cleaned === 1 &&
              (!cleaningStatus || +cleaningStatus.value !== 1 || !cleaningStatus.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Cleaning.Cleaned === 2 &&
              (!cleaningStatus || +cleaningStatus.value !== 2 || !cleaningStatus.value)) {
              showController = false
            }

             // cleaning - HighestCleaningStatus

             if (controllerFilters.customControllerFilter.Cleaning.HighestCleaningStatus === 0 &&
              (!highestCleaningStatus || +highestCleaningStatus.value !== 0 || !highestCleaningStatus.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Cleaning.HighestCleaningStatus === 1 &&
              (!highestCleaningStatus || +highestCleaningStatus.value !== 1 || !highestCleaningStatus.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Cleaning.HighestCleaningStatus === 2 &&
              (!highestCleaningStatus || +highestCleaningStatus.value !== 2 || !highestCleaningStatus.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Cleaning.HighestCleaningStatus === 3 &&
              (!highestCleaningStatus || +highestCleaningStatus.value !== 3 || !highestCleaningStatus.value)) {
              showController = false
            }

             // hvac - fan speed
             if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 1 &&
              (!v1 || Property.isInactive(v1) || !v1.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 2 &&
              (!v2 || Property.isInactive(v2) || !v2.value)) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Hvac.HvacSpeed === 3 &&
              (!v3 || Property.isInactive(v3) || !v3.value)) {
              showController = false
            }


            // other statuses - room armed
            if (!this.showControler(controllerFilters.customControllerFilter.Statuses.RoomArmed,roomArmed)) {
              showController = false
            }

            // other statuses - presence/card deposited
            if (!this.showControler(controllerFilters.customControllerFilter.Statuses.CardDeposited,cardDeposited)) {
              showController = false
            }

            // other statuses - dnd
            if (!this.showControler(controllerFilters.customControllerFilter.Statuses.DoNotDisturb,dndStatus)) {
              showController = false
            }

            // other statuses - window open
              if (!this.showControler(controllerFilters.customControllerFilter.Statuses.WindowOpen,windowOpen)) {
              showController = false
            }


            // other statuses - door open
             if (!this.showControler(controllerFilters.customControllerFilter.Statuses.DoorOpen,doorOpen)) {
              showController = false
            }

            // other statuses - ignore window
            if (!this.showControler(controllerFilters.customControllerFilter.Statuses.IgnoreWindow,isIgnoreWindow)) {
              showController = false
            }


            // other statuses - ignore card tray
            if (!this.showControler(controllerFilters.customControllerFilter.Statuses.IgnoreCardTray,isIgnoreCardTray)) {
              showController = false
            }


             // other statuses - maid in room
             const depositedCardType: CardType = demoModeData.CardTypes.find((ct: CardType)=> {
              return cardTypeProp.value.toString() === ct.code.toString()
            });

             if (controllerFilters.customControllerFilter.Statuses.MaidAtLocation === true &&
              (!cardDeposited || !cardTypeProp || Property.isInactive(cardDeposited) || !cardDeposited.value
              || !cardTypeProp.value || !depositedCardType || !CardType.isMaidCard(depositedCardType))) {
              showController = false
            } else if (controllerFilters.customControllerFilter.Statuses.MaidAtLocation === false &&
              (!cardDeposited || !cardTypeProp /* || Property.isActive(cardDeposited)  */|| !cardDeposited.value
              || !cardTypeProp.value || !depositedCardType) ) {
              showController = false
            }

              return showController;
            })

            filteredLocations = this.filterLocationsByGroup(filteredLocations,controllerFilters,demoModeData.Groups)

          // })


          this.projectService.setFilteredLocations(filteredLocations)
        }

         async setFilteredDemoProjectCommonAreas(controllerFilters: LocationsFilter) {
          const demoModeData = await lastValueFrom(this.http.get<DemoModeData>(this.DEMO_DATA))

          const filteredLocations =  demoModeData.CommonAreaFat.filter((location: Location)=> {
            return controllerFilters.locationTypes.some((locType)=> {
              return locType === location.locationTypeId
            })
          })



          this.projectService.setFilteredLocations(filteredLocations)
        }



        showControler (filterActive, prop: Property): boolean {
          let showControler = true;
          if (filterActive === true
            && (!prop || Property.isInactive(prop) || !prop.value)) {
              showControler = false
          } else if (filterActive === false
            && (!prop || (Property.isActive(prop)))) {
              showControler = false
          }
          return showControler
        }

        anyAlarmActive (alarms : {
          burglary: any;
          burglaryDetectedByPIR: any;
          burglaryDetectedWindow: any;
          burglaryDetectedDoor: any;
          safeBurglary: any;
          safeCommError: any;
          HVACLargeTempDiffRoom: any;
          HVACLargeTempDiffBathroom: any;
          flood: any;
          SOS: any;
          doorOpenTooLongAlarm: any;
          windowOpenTooLongAlarm: any;
      } ): boolean {

        if ( Property.isActive(alarms.burglary)) {
          return true
        } else if (Property.isActive(alarms.burglaryDetectedByPIR)) {
          return true
        }else if (Property.isActive(alarms.burglaryDetectedWindow)) {
          return true
        }else if (Property.isActive(alarms.burglaryDetectedDoor)) {
          return true
        }else if (Property.isActive(alarms.safeBurglary)) {
          return true
        }else if (Property.isActive(alarms.safeCommError)) {
          return true
        }else if (Property.isActive(alarms.HVACLargeTempDiffRoom)) {
          return true
        }else if (Property.isActive(alarms.HVACLargeTempDiffBathroom)) {
          return true
        }else if (Property.isActive(alarms.flood)) {
          return true
        }else if (Property.isActive(alarms.SOS)) {
          return true
        }else if (Property.isActive(alarms.doorOpenTooLongAlarm)) {
          return true
        }else if (Property.isActive(alarms.windowOpenTooLongAlarm)) {
          return true
        }

        else return false;
        }

        filterLocationsByGroup(locations: Location[],controllerFilters: LocationsFilter,groups: LocationGroup[]) {
          if (!controllerFilters.groups.length || controllerFilters.groups.length === 0) {
            return locations;
          }
          const groupsInFilter = controllerFilters.groups.map((groupId)=> {
            return groups.find((group)=> {
              return group.id === groupId
            })
          })

          const locationsFilteredByGroup = locations.filter((loc)=> {
            return groupsInFilter.some((group)=> {
              return group.locations.some((l)=> {
                return l.locationId === loc.locationId
              })
            })

          })

          return locationsFilteredByGroup


        }


}
