import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss']
})
export class BooleanInputComponent implements OnInit, OnDestroy {
  @Input() controllers: Controller[];
  @Input() findPropFunction: (property: Property) => boolean;

  inputDelay = 2000;
  targetValue: boolean;
  valueSet$ = new Subject<boolean>();
  valueSubscription = new Subscription();
  resetTargetSubscription = new Subscription();
  reqInProgress = false;
  constructor(private apiProjectService: ApiProjectService) { }

  ngOnInit(): void {
    this.valueSubscription = this.valueSet$
      .pipe(
        debounceTime(this.inputDelay),
       )
      .subscribe((newValue) => {
        let property: Property;
        const parentController: Controller = this.controllers.find(
          (controller: Controller) => {
            property = controller.controllerProperties.$values.find(this.findPropFunction);
            return property;
          }
        );
        if (parentController && property) {
          const designation = parentController.designation;
          const propertyId = property.id;
          const value = (newValue === true? 1 : 0 );
          this.apiProjectService
            .changeProperty(designation, propertyId, value)
            .subscribe(() => {
              // this.reqInProgress = false;
            });
        }
      });
    this.resetTargetSubscription = this.valueSet$
      .pipe(debounceTime(5000))
      .subscribe(() => {
        this.reqInProgress = false;
        this.targetValue = null;
      });
  }

  toggleValue(event) {
    this.reqInProgress = true;
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation()
    this.targetValue = !event.target.checked;
    this.valueSet$.next(this.targetValue);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.valueSubscription.unsubscribe();
    }, 5000);
    this.resetTargetSubscription.unsubscribe();
  }

}
