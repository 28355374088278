import { Pipe, PipeTransform } from '@angular/core';
import { DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';

@Pipe({
  name: 'getPresetName'
})
export class GetPresetNamePipe implements PipeTransform {

  transform(presetId: number, presets: DefaultsPreset[]): string {
    const targetPreset =  presets.find((preset)=>{
      return presetId === preset.id
    });
    return targetPreset?.name
  }
}
