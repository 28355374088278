<div class="pagination" *ngIf="paginationData.length > 0">
    <div class="pagination">
      <a [ngClass]="{
          'not-clickable' : selectedPage === 1,
          'clickable' : selectedPage !== 1}" class="first-page" (click)="onNavigate(1)">
      </a>
  
      <a class="clickable" (click)="onNavigate(selectedPage - 1)" *ngIf="selectedPage - 1 !== 0">
        {{ this.selectedPage - 1 }}
      </a>
      <a class="active" (click)="onNavigate(selectedPage)">
        {{ this.selectedPage }}
      </a>
  
      <a class="clickable" (click)="onNavigate(selectedPage + 1)"
        *ngIf="selectedPage !== lastPage">
        {{ this.selectedPage + 1 }}
      </a>

      
    <a class="clickable" (click)="onNavigate(this.selectedPage + 2)"
    *ngIf="this.selectedPage===1 && this.paginationData.length > 101">
    {{ this.selectedPage + 2 }}
  </a>
  
      <a [ngClass]="{
        'not-clickable' : (paginationData.length === selectedPage || selectedPage === lastPage),
        'clickable' : paginationData.length !== selectedPage && selectedPage !== lastPage}" class="last-page"
        (click)="onNavigate(getPageNumber(paginationData.length))">
      </a>
    </div>