import { Pipe, PipeTransform } from '@angular/core';
import { OutpuModeStatus } from 'src/app/core/models/common-area/output-mode.enum';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Pipe({
  name: 'outputModeForLightIsControlPipe'
})
export class OutputModeForLightIsControlPipe implements PipeTransform {

  transform(controller: Controller, light: Property, propFunct: Function , outputModesEnum: number): boolean {
    let check = false;

    const outputModeNum = light.mqttTopic.split('/')[2];
    const prop = controller.controllerProperties.$values.find( p => {
      if (propFunct(p) && p.mqttTopic.split('/')[3] === outputModeNum && +p.value === outputModesEnum) {
        return true;
      }
    })
    if (prop) {
        check = true;
    }

    return check;
  }

}