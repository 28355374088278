<div class="flex-row margin-top30 modal-content-container">
  <div class="width100 flex-row margin-right50 margin-left100" style="justify-content: flex-start; flex-flow: nowrap">
    <div class="flex-column width8">
      <ion-list *ngIf="allControllersInLocation.length > 1" class="margin20">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item
          button
          style="width: 80px;"
          *ngFor="let controller of allControllersInLocation"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
          <div [ngClass]="{'inactive-icon-small': !controller.isOnline}"></div>
        </ion-item>
      </ion-list>
    </div>
    <!-- <ng-container *ngIf="renderNumberInputs">

    <div class="flex-column width23">
      <div class="margin-bottom30 uppercase">{{ "Room" | translate }}</div>

       <ng-container *ngFor="let propData of (settings$ | async).room  | sortByAdminProps as roomSettings; let i = index">
          <ng-container
  *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsRoomProps"
>
<ng-container *ngIf="settingsRoomProps.length > 0">
  <div *ngIf="i>0 && roomSettings[i-1].adminProp=== false &&
    roomSettings[i].adminProp=== true &&
     logedInUser.userId === 1"
    class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
  </div>
  <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
        <app-number-input-code
         [controllers]="selectedControllers"
          [propData]="propData"
        ></app-number-input-code>
    </ng-container>
      </ng-container>
      </ng-container>
    </ng-container>


      <app-room-temp-info [selectedControllers]="selectedControllers"
        [location]="'room'"
      ></app-room-temp-info>
    </div>

    <div *ngIf="bathroomHasTemps" class="flex-column width24">
      <div class="margin-bottom30 uppercase">{{ "Bathroom" | translate }}</div>
      <ng-container *ngFor="let propData of (settings$ | async).bathroom | sortByAdminProps as bathroomSettings; let i = index">
        <ng-container
        *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsBathroomProps"
      >
      <ng-container *ngIf="settingsBathroomProps.length > 0">
        <div *ngIf="i>0 && bathroomSettings[i-1].adminProp=== false &&
          bathroomSettings[i].adminProp=== true &&
          logedInUser.userId === 1"
         class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
       </div>
       <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
        <app-number-input-code
         [controllers]="selectedControllers"
          [propData]="propData"
        ></app-number-input-code>
      </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
      <div style="height:54px"></div>
      <app-room-temp-info *ngIf="selectedControllers | roomHaveBathroom" [selectedControllers]="selectedControllers"
        [location]="'bathroom'"
      ></app-room-temp-info>
    </div>
  </ng-container> -->
  <!-- <div class="width25" *ngIf="!renderNumberInputs"></div> -->
  <!-- SPACER. Kees spacing while rerendering components -->
  <!-- <div class="width25" *ngIf="!renderNumberInputs"></div> -->
  <ng-container *ngIf="renderNumberInputs">
      <div class="flex-row margin-left50">
        <div class="flex-column margin-right25">
          <ion-button
          class="margin-left5 margin-right5 margin-top5 margin-bottom10 width100"
          color="light"
          (click)="syncHvacSettings()"
          size="small"
          ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
          {{'Sync HVAC settings' | translate}}</ion-button>
          <ion-button *ngIf="toggleHvacExist"
          class="margin5 width100"
          color="light"
          (click)="toggleHvac()"
          size="small"
          ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
          {{'Toggle HVAC' | translate}}</ion-button>
          <ng-container *ngIf="selectedControllers | findProperty:  Property.isHvacHeatCool: null : true as hvacHeatCoolProps">
            <ng-container *ngIf="selectedControllers | findProperty: Property.isHvacHeatCoolAuto: null : true as hvacHCAutoProps">
              <!-- HEAT/COOL/AUTO -->
              <div *ngIf="hvacHCAutoProps.length > 0" class="flex-row-space-between width100">
                <ion-button class="margin-bottom5 margin-top5 width30" size="small"
                  [disabled]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
                  [color]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(0)">
                  {{'Heat' | translate}}
                </ion-button>
                <ion-button class="margin-bottom5 margin-top5 width30"  size="small"
                  [disabled]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
                  [color]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(1)">
                  {{'Cool' | translate}}
                </ion-button>
                <ion-button class="margin-bottom5 margin-top5 width30"
                  size="small"
                  [disabled]="(hvacHCAutoProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
                  [color]="(hvacHCAutoProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(2)">
                  {{'Auto' | translate}}
                </ion-button>
              </div>
              <!-- HEAT/COOL -->
              <div *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0" class="flex-row-space-between width100">
                <ion-button class="margin5 width45" size="small"
                  [disabled]="!(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
                  [color]="!(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(0)">
                  {{'Heat' | translate}}
                </ion-button>
                <ion-button class="margin-bottom5 margin-top5 width45" size="small"
                  [disabled]="(hvacHeatCoolProps | propIsActive) || FORBID_HEAT_COOL_CHANGE"
                  [color]="(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                  (click)="setHvacMode(1)">
                   {{'Cool' | translate}}
                </ion-button>
              </div>
            </ng-container>
          </ng-container>



        <ng-container
          *ngIf="
            selectedControllers
              | findProperty
                : Property.isMinibarWasOpenedStatus: null : true as isMinibarWasOpenedProps
          "
        >
          <ion-button
            *ngIf="isMinibarWasOpenedProps?.length > 0"
            size="small"
            class="margin5 "
            color="light"
            (click)="resetMinibar()"
            ><ion-icon class="margin-right10" name="wine-outline"></ion-icon
            >{{ "Reset minibar" | translate }}</ion-button
          >
        </ng-container>
          <div style="width: 300px;">
            <div>{{'Equipment ignore' |translate}}</div>
            <app-boolean-input
                  *ngIf="
                selectedControllers | propExistInControllers : Property.isIgnoreWindow : true
              "
                  [controllers]="selectedControllers"
                  [findPropFunction]="Property.isIgnoreWindow"
                  >{{ "Ignore window" | translate }}</app-boolean-input
                >
                <app-boolean-input
                *ngIf="
              selectedControllers | propExistInControllers : Property.isIgnoreCardTray : true
            "
                [controllers]="selectedControllers"
                [findPropFunction]="Property.isIgnoreCardTray"
                >{{ "Ignore card tray" | translate }}</app-boolean-input
              >
                <ion-item lines="none" class="item-background-color">
                  <ion-checkbox
                    (ionChange)="changeControllerPooling($event)"
                    justify="start"
                    labelPlacement="end"
                    class="margin-right5"
                    [checked]="selectedControllers[0] | controllerNotPolled: notPollingControllers"
                  ><span class="ion-text-wrap">
                {{ "Stop controller polling" | translate }}</span></ion-checkbox
              >
            </ion-item>
                <app-boolean-input
                  *ngIf="
                selectedControllers | propExistInControllers : Property.isPanelBlocked : true
              "
                  [controllers]="selectedControllers"
                  [findPropFunction]="Property.isPanelBlocked"
                  >{{ "Block keyboard" | translate }}</app-boolean-input
                >
                <div>{{'Burglary ignore' | translate}}</div>
                <ng-container
                *ngIf="
                  selectedControllers
                    | findProperty
                      : Property.isBurglaryDetectedDoor: null : true as burglaryDetectedProps
                "
              >
                <ion-item
                  *ngIf="burglaryDetectedProps.length > 0"
                  lines="none"
                  class="item-background-color"
                >
                  <ion-checkbox
                    (click)="
                      changeIgnoreBurglary($event, burglaryDetectedProps)
                    "
                    justify="start"
                    labelPlacement="end"
                    [checked]="
                      ignorePublishingList
                        | includes : burglaryDetectedProps[0].id
                    "
                    [disabled]="loadingPublishing"
                  ><span class="">
                    {{ "Ignore burglary by door" | translate }}
                  </span>
                  </ion-checkbox
                  >
                </ion-item>
              </ng-container>
              <ng-container
              *ngIf="
                selectedControllers
                  | findProperty
                    : Property.isBurglaryDetectedWindow: null : true as burglaryDetectedWindowProps
              "
            >
              <ion-item
                *ngIf="burglaryDetectedWindowProps.length > 0"
                lines="none"
                class="item-background-color"
              >
                <ion-checkbox
                  (click)="
                    changeIgnoreBurglary($event, burglaryDetectedWindowProps)
                  "
                  justify="start"
                  labelPlacement="end"
                  [checked]="
                    ignorePublishingList
                      | includes : burglaryDetectedWindowProps[0].id
                  "
                  [disabled]="loadingPublishing"
                >
                <span class="">
                  {{ "Ignore burglary by window" | translate }}</span>
                  </ion-checkbox
                >
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="
                selectedControllers
                  | findProperty
                    : Property.isBurglaryDetectedByPIR: null : true as burglaryDetectedByPIR
              "
            >
              <ion-item
                *ngIf="burglaryDetectedByPIR.length > 0"
                lines="none"
                class="item-background-color"
              >
                <ion-checkbox
                  (click)="
                    changeIgnoreBurglary($event, burglaryDetectedByPIR)
                  "
                  justify="start"
                  labelPlacement="end"
                  [checked]="
                    ignorePublishingList
                      | includes : burglaryDetectedByPIR[0].id
                  "
                  [disabled]="loadingPublishing"
                ><span class="ion-text-wrap">
                  {{ "Ignore burglary by pir" | translate }}</span></ion-checkbox
                >
                  </ion-item>
            </ng-container>
          </div>
        </div>
        <div class="flex-column">
          <ng-container
          *ngIf="selectedControllers | findProperty: Property.isRoomArmed: null : true as roomArmedProps"
        >
          <ion-button
            *ngIf="roomArmedProps?.length > 0"
            class="margin5 width100"
            color="light"
            (click)="armRoom()"
            size="small"
            ><ion-icon class="margin-right10" name="shield-half-outline">
            </ion-icon
            >{{'Arm room' | translate}} (alarm)</ion-button
          >
          <ion-button
          size="small"
          class="margin5 width100"
          color="light"
          style="letter-spacing: 0.1px;"
          (click)="openPopoverToSelectPreset($event)"
          ><ion-icon class="margin-right10" name="settings-sharp"></ion-icon>
          {{ "Reset to default settings" | translate }}</ion-button>
        </ng-container>
          <div style="width: 100%; margin-left: 5px;">
            <ion-item lines="none" class="item-background-color">
              <ion-checkbox
                (ionChange)="changeResetDefaultPropertiesOnCheckout($event)"
                justify="start"
                labelPlacement="end"
                [checked]="location.restoreDefaultOnCheckout"

                ><span class="ion-text-wrap">{{
              "Reset to default settings on checkout" | translate
            }}</span>
            </ion-checkbox
          >
            </ion-item>
          </div>
        <div class="width100">

  <!-- RADIATOR STATUS -->
    <ng-container *ngIf="selectedControllers | showRadiator: 'room'">
      <div class="margin-top20"> {{'Room radiator' | translate}}</div>
      <div class="width100" style="background-color: #EFEDED;">
    <div  *ngIf="selectedControllers | propExistInControllers : Property.isRoomRadiator : true" class="font-size16" style="margin-left: 8px; font-family: sans-serif;">
      {{'Room radiator' | translate}}:
      <ion-chip *ngIf="selectedControllers | findProperty: Property.isRoomRadiator: null : true | propIsActive; else radiatorNotActive" class="chip no-click" [outline]="true">
        <ion-label class="chip-label"
          >{{'On' |translate}}
        </ion-label>
      </ion-chip>
      <ng-template #radiatorNotActive>
      <ion-chip class="chip no-click" [outline]="true">
        <ion-label class="chip-label"
          >{{'Off' |translate}}
        </ion-label>
      </ion-chip>
    </ng-template>
      </div>

      <div *ngIf="selectedControllers | showRadiatorModeSelector : 'room'; else roomRadiatorSingleInput" class="width100 flex-row-center-h">
    <ion-button  id="click-trigger-room" size="small" class="width90 margin-bottom5">
       <ng-container *ngIf="selectedControllers | getRoomRadiatorStatus: updated as roomRadiatorStatus">
        {{roomRadiatorStatus | translate}}
      </ng-container>
    </ion-button>

    <ion-popover trigger="click-trigger-room" triggerAction="click" [showBackdrop]="false">
      <ng-template>
        <ion-content>
          <ion-list>
            <ion-list-header class="margin-left5">{{'Choose room radiator operation mode' | translate}}</ion-list-header>
            <ng-container *ngIf="selectedControllers | propExistInControllers: Property.isRadiatorByRented : true">
              <ion-item [button]="true" (click)="onSelectRoomRadiatorBy('active by rented')">{{'By rented' | translate}}</ion-item>
              <ion-item [button]="true" (click)="onSelectRoomRadiatorBy('active by presence')">{{'By presence' | translate}}</ion-item>
            </ng-container>
            <ion-item *ngIf="selectedControllers | propExistInControllers: Property.isRadiatorForceOn : true"
              [button]="true" (click)="activateProp(Property.isRadiatorForceOn, 1)">{{'Force on' | translate}}</ion-item>
            <ion-item *ngIf="selectedControllers | propExistInControllers: Property.isRadiatorForceOff : true"
              lines="none" [button]="true" (click)="activateProp(Property.isRadiatorForceOff, 1)">{{'Force off' | translate}}</ion-item>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
  </div>
    <ng-template #roomRadiatorSingleInput>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isRadiatorByRented : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isRadiatorByRented"
      >{{ "Room radiator active by rented" | translate }}
      </app-boolean-input>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isRadiatorForceOn : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isRadiatorForceOn"
      >{{ "Room radiator forced on" | translate }}
      </app-boolean-input>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isRadiatorForceOff : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isRadiatorForceOff"
      >{{ "Room radiator forced off" | translate }}
      </app-boolean-input>
    </ng-template>
    <app-boolean-input
      *ngIf="selectedControllers | propExistInControllers : Property.isRadiatorInCooling : true"
      [controllers]="selectedControllers"
      [findPropFunction]="Property.isRadiatorInCooling"
    >{{ "Room radiator in cooling" | translate }}
    </app-boolean-input>
</div>
</ng-container>


       <!--    <div *ngIf="controllers | propExistInControllers : Property.isRadiatorByRented" class="margin-top50">
              <div *ngFor="let option of optionsRoomRadiator" class="margin-top5">
                <div class="flex-column" style="margin-left: 11px; font-family: sans-serif;">
                <label class="font-size16 margin-bottom10 clickable">
                  <input
                    style="transform: scale(1.3);"
                    name="options"
                    class="margin-right10 clickable"
                    type="radio"
                    [(ngModel)]="selectedOptionRoom"
                    [value]="option"
                    [disabled]="reqInProgress"
                    (change)="onSelectRoomRadiatorBy(option)">{{ option }}</label>
              </div>
            </div>
          </div>

          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isRadiatorForcedOn"
            [controllers]="controllers"
            [findPropFunction]="Property.isRadiatorForcedOn"
            >{{ "Room radiator force on" | translate }}</app-boolean-input> -->

  <!-- BATHROOM RADIATOR STATUS -->
    <ng-container *ngIf="selectedControllers | showRadiator: 'bathroom'">
      <div class="margin-top20">{{'Bathroom radiator' | translate}}</div>
      <div class="width100" style="background-color: #EFEDED;">
    <div *ngIf="selectedControllers | propExistInControllers : Property.isBathroomRadiator : true"class="font-size16" style="margin-left: 8px; font-family: sans-serif;">
      {{'Bathroom radiator' | translate}}:
      <ion-chip *ngIf="selectedControllers | findProperty: Property.isBathroomRadiator: null : true | propIsActive; else bathroomRadiatorNotActive" class="chip no-click" [outline]="true">
        <ion-label class="chip-label"
          >{{'On' |translate}}
        </ion-label>
      </ion-chip>
      <ng-template #bathroomRadiatorNotActive>
      <ion-chip class="chip no-click" [outline]="true">
        <ion-label class="chip-label"
          >{{'Off' |translate}}
        </ion-label>
      </ion-chip>
    </ng-template>
      </div>
      <div *ngIf="selectedControllers | showRadiatorModeSelector : 'bathroom'; else bathroomRadiatorSingleInput" class="width100 flex-row-center-h">
    <ion-button  id="click-trigger-bathroom" size="small" class="width90 margin-bottom5">
      <ng-container *ngIf="selectedControllers | getBathroomRadiatorStatus: updated as bathroomRadiatorStatus">
        {{bathroomRadiatorStatus | translate}}
      </ng-container>
    </ion-button>
    <ion-popover trigger="click-trigger-bathroom" triggerAction="click" [showBackdrop]="false">
      <ng-template>
        <ion-content>
          <ion-list>
            <ion-list-header class="margin-left5">{{'Choose bathroom radiator operation mode' | translate}}</ion-list-header>
            <ng-container *ngIf="selectedControllers | propExistInControllers: Property.isBathroomRadiatorByRented : true">
              <ion-item [button]="true" (click)="onSelectBathroomRadiatorBy('active by rented')">{{'By rented' | translate}}</ion-item>
              <ion-item [button]="true" (click)="onSelectBathroomRadiatorBy('active by presence')">{{'By presence' | translate}}</ion-item>
            </ng-container>
            <ion-item  *ngIf="selectedControllers | propExistInControllers: Property.isBathroomRadiatorForceOn : true"
              [button]="true" (click)="activateProp(Property.isBathroomRadiatorForceOn, 1)">{{'Force on' | translate}}</ion-item>
            <ion-item  *ngIf="selectedControllers | propExistInControllers: Property.isBathroomRadiatorForceOff : true"
              lines="none" [button]="true" (click)="activateProp(Property.isBathroomRadiatorForceOff, 1)">{{'Force off' | translate}}</ion-item>
          </ion-list>
        </ion-content>
      </ng-template>
    </ion-popover>
  </div>
    <ng-template #bathroomRadiatorSingleInput>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isBathroomRadiatorByRented : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isBathroomRadiatorByRented"
      >{{ "Bathroom radiator active by rented" | translate }}
      </app-boolean-input>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isBathroomRadiatorForceOn : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isBathroomRadiatorForceOn"
      >{{ "Bathroom radiator forced on" | translate }}
      </app-boolean-input>
      <app-boolean-input
        *ngIf="selectedControllers | propExistInControllers : Property.isBathroomRadiatorForceOff : true"
        [controllers]="selectedControllers"
        [findPropFunction]="Property.isBathroomRadiatorForceOff"
      >{{ "Bathroom radiator forced off" | translate }}
      </app-boolean-input>
    </ng-template>
    <app-boolean-input
      *ngIf="selectedControllers | propExistInControllers : Property.isBathroomRadiatorInCooling : true"
      [controllers]="selectedControllers"
      [findPropFunction]="Property.isBathroomRadiatorInCooling"
    >{{ "Room radiator in cooling" | translate }}
    </app-boolean-input>
</div>
</ng-container>
            <!-- <div *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorByRented">
              <div class="font-size16 margin-bottom15" style="margin-left: 8px; margin-top: 5px; font-family: sans-serif;">
                {{'Bathroom radiator' | translate}}:
              </div>

                <div *ngFor="let option of optionsBathroomRadiator" class="margin-top5">
                  <div class="flex-column" style="margin-left: 11px; font-family: sans-serif;">
                  <label class="font-size16 margin-bottom10 clickable">
                    <input
                      style="transform: scale(1.3);"
                      name="options"
                      class="margin-right10 clickable"
                      type="radio"
                      [(ngModel)]="selectedOptionBathroom"
                      [value]="option"
                      [disabled]="reqInProgress"
                      (change)="onSelectBathroomRadiatorBy(option)">{{ option }}</label>
                </div>
              </div>
            </div>
          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorForcedOn"
            [controllers]="controllers"
            [findPropFunction]="Property.isBathroomRadiatorForcedOn"
            >{{ "Bathroom radiator force on" | translate }}</app-boolean-input>
          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorInCooling"
            [controllers]="controllers"
            [findPropFunction]="Property.isBathroomRadiatorInCooling"
            >{{ "Bathroom radiator in cooling" | translate }}</app-boolean-input> -->

      </div>
      </div>
      <div style="min-width: 300px">
         <!-- RELAY OUTPUTS -->
        <ng-container *ngIf="selectedControllers | getRelayOutputNumbers as relayOutputNumbers">
              <ng-container *ngFor="let relayOutputNo of relayOutputNumbers; let ind = index">
                <ng-container *ngIf="selectedControllers | findProperty: Property.isRelayOutputStatus :  updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputStatusProps">

          <!--  RELAY OUTPUT STATUS -->
            <div class="margin-left20"> {{selectedControllers | getRelayOutputName : relayOutputNo | translate}}</div>
            <div class="width100 margin-bottom20 margin-left20" style="background-color: #EFEDED;">
          <div *ngIf="relayOutputStatusProps.length>0" class="font-size16 flex-row-center" style="margin-left: 8px; font-family: sans-serif;">
            {{relayOutputStatusProps | getRelayOutputStatusName | translate}}:
            <ion-chip *ngIf="relayOutputStatusProps | propIsActive; else relayOutputPropNotActive" class="chip no-click" [outline]="true">
              <ion-label class="chip-label"
                >{{'On' |translate}}
              </ion-label>
            </ion-chip>
            <ng-template #relayOutputPropNotActive>
            <ion-chip class="chip no-click" [outline]="true">
              <ion-label class="chip-label"
                >{{'Off' |translate}}
              </ion-label>
            </ion-chip>
            </ng-template>
            <ion-icon
              class="clickable margin-left150"
              name="sync-outline"
              style="font-size: 20px;"
              (click)="syncRekuperator()">
            </ion-icon>
          </div>
            <!-- RELAY OUTPUT SELECTOR -->
            <div *ngIf="selectedControllers | showRelayOutputSelector : relayOutputNo ; else relayOutputSingleInput"class="width100 flex-row-center-h">
          <ion-button id="click-trigger-relayOutput{{ind}}" size="" class="width90 margin-bottom5" >
             <ng-container *ngIf="selectedControllers | getRelayOutputStatusState : relayOutputNo: updated as relayOutputStatusState">
              {{relayOutputStatusState | translate}}
            </ng-container>
          </ion-button>

          <ion-popover trigger="click-trigger-relayOutput{{ind}}" triggerAction="click" [showBackdrop]="false">
            <ng-template>
              <ion-content>
                <ion-list>
                  <!-- BY RENTED OR PRESENCE -->
                  <ion-list-header class="margin-left5">{{'Choose operation mode' | translate}}</ion-list-header>
                  <ng-container *ngIf="(selectedControllers | findProperty: Property.isRelayOutputByRentedOrPresence :
                   updated : true | getRelayOutputByNumber : relayOutputNo) as relayOutputByRentedOrPresence
                  ">
                    <ng-container *ngIf="relayOutputByRentedOrPresence.length > 0" >
                    <ion-item
                      [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputByRentedOrPresence,1,relayOutputNo)">
                      {{'By rented' | translate}}
                    </ion-item>
                    <ion-item [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputByRentedOrPresence,0, relayOutputNo)">
                      {{'By presence' | translate}}
                    </ion-item>
                  </ng-container>
                  </ng-container>
                  <!-- FORCE ON -->
                <ng-container *ngIf="(selectedControllers | findProperty: Property.isRelayOutputForceOn :
                      updated : true | getRelayOutputByNumber : relayOutputNo) as relayOutputForceOnProps">
                  <ng-container *ngIf="relayOutputForceOnProps.length > 0">
                    <ion-item
                    [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputForceOn, 1, relayOutputNo)">
                      {{relayOutputForceOnProps[0].name.split('|')[1]  ?
                        ((relayOutputForceOnProps[0].name.split('|')[1]) | translate): 'Force on' | translate}}
                    </ion-item>
                  </ng-container>
                </ng-container>
                   <!-- FORCE OFF -->
                <ng-container *ngIf="selectedControllers | findProperty: Property.isRelayOutputForceOff :
                 updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputForceOffProps">
                  <ng-container *ngIf="relayOutputForceOffProps.length > 0">
                  <ion-item
                    lines="none" [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputForceOff, 1, relayOutputNo)">
                    {{relayOutputForceOffProps[0].name.split('|')[1]  ?
                    ((relayOutputForceOffProps[0].name.split('|')[1]) | translate): 'Force off' | translate}}
                  </ion-item>
                  </ng-container>
                </ng-container>
                </ion-list>
              </ion-content>
            </ng-template>
          </ion-popover>
        </div>
          <ng-template #relayOutputSingleInput>
          <ng-container  *ngIf="selectedControllers | findProperty: Property.isRelayOutputByRentedOrPresence :
                 updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputByRentedOrPresenceProps">
              <ion-item *ngIf="relayOutputByRentedOrPresenceProps.length > 0"   lines="none" class="item-background-color">
              <ion-checkbox

              (click)="toggleRelayOutputValue($event,Property.isRelayOutputByRentedOrPresence ,relayOutputNo)
              "
              justify="start"
              labelPlacement="end"
              [checked]="relayOutputByRentedOrPresenceProps | propIsActive "
              [disabled]="false"
              ><span class="ion-text-wrap">
              {{"By rented" | translate}}</span>
              </ion-checkbox
            > </ion-item >
          </ng-container>
          <ng-container  *ngIf="selectedControllers | findProperty: Property.isRelayOutputForceOn :
                 updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputForceOnProps">
              <ion-item *ngIf="relayOutputForceOnProps.length > 0"   lines="none" class="item-background-color">
              <ion-checkbox

              (click)="toggleRelayOutputValue($event,Property.isRelayOutputForceOn ,relayOutputNo)
              "
              justify="start"
              labelPlacement="end"
              [checked]="relayOutputForceOnProps | propIsActive "
              [disabled]="false"
              ><span class="ion-text-wrap">
              {{"Force on" | translate}}</span>
              </ion-checkbox
            > </ion-item >
          </ng-container>
          <ng-container  *ngIf="selectedControllers | findProperty: Property.isRelayOutputForceOff :
                 updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputForceOffProps">
              <ion-item *ngIf="relayOutputForceOffProps.length > 0"   lines="none" class="item-background-color">
              <ion-checkbox

              (click)="toggleRelayOutputValue($event,Property.isRelayOutputForceOff ,relayOutputNo)
            "
              justify="start"
              labelPlacement="end"
              [checked]="relayOutputForceOffProps | propIsActive "
              [disabled]="false"
              ><span class="ion-text-wrap">
              {{"Force off" | translate}}</span>
              </ion-checkbox
            > </ion-item >
          </ng-container>

          </ng-template>
          <ng-container  *ngIf="selectedControllers | findProperty: Property.isRelayOutputInCooling :
               updated : true | getRelayOutputByNumber : relayOutputNo as relayOutputInCoolingProps">
            <ion-item *ngIf="relayOutputInCoolingProps.length > 0"   lines="none" class="item-background-color">
            <ion-checkbox

            (click)="toggleRelayOutputValue($event,Property.isRelayOutputInCooling ,relayOutputNo)
            "
            justify="start"
            labelPlacement="end"
            [checked]="relayOutputInCoolingProps | propIsActive "
            [disabled]="false"
            ><span class="ion-text-wrap">
            {{"In cooling" | translate}}</span>
            </ion-checkbox
          > </ion-item >
      </ng-container>

      </div>
      </ng-container>
    </ng-container>
      <!--     <ng-container *ngFor="let relayOutputStatusProp of relayOutputStatusProps; let i = index">
            <div class="margin-left20"> {{relayOutputStatusProp | getRelayOutputStatusName | translate}}</div>
            <div class="width100 margin-bottom20 margin-left20" style="background-color: #EFEDED;">
              <ng-container *ngIf="relayOutputStatusProp | getRelayOutputStatusNumber as relayStatusOutputNumber">
          <div class="font-size16" style="margin-left: 8px; font-family: sans-serif;">
            {{relayOutputStatusProp | getRelayOutputStatusName | translate}}:
            <ion-chip *ngIf="relayOutputStatusProp | propIsActive; else relayOutputPropNotActive" class="chip no-click" [outline]="true">
              <ion-label class="chip-label"
                >{{'On' |translate}}
              </ion-label>
            </ion-chip>
            <ng-template #relayOutputPropNotActive>
            <ion-chip class="chip no-click" [outline]="true">
              <ion-label class="chip-label"
                >{{'Off' |translate}}
              </ion-label>
            </ion-chip>
          </ng-template>
            </div>
            <div class="width100 flex-row-center-h">
          <ion-button id="click-trigger-relayOutput{{i}}" size="small" class="width90 margin-bottom5" >
             <ng-container *ngIf="controllers | getRelayOutputStatusState : relayStatusOutputNumber: updated as relayOutputStatusState">
              {{relayOutputStatusState | translate}}
            </ng-container>
          </ion-button>
        </div>
          <ion-popover trigger="click-trigger-relayOutput{{i}}" triggerAction="click" [showBackdrop]="false">
            <ng-template>
              <ion-content>
                <ion-list>
                  <ion-list-header class="margin-left5">{{'Choose operation mode' | translate}}</ion-list-header>
                  <ion-item [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputByRentedOrPresence,1,relayStatusOutputNumber)">
                    {{'By rented' | translate}}
                  </ion-item>
                  <ion-item [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputByRentedOrPresence,0, relayStatusOutputNumber)">
                    {{'By presence' | translate}}
                  </ion-item>
                  <ion-item *ngIf="
                  (controllers | findProperty: Property.isRelayOutputForceOn : updated | getRelayOutputByNumber : relayStatusOutputNumber)
                  .length > 0"
                  [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputForceOn, 1, relayStatusOutputNumber)">
                    {{'Force on' | translate}}
                  </ion-item>
                  <ion-item *ngIf="
                    (controllers | findProperty: Property.isRelayOutputForceOff : updated | getRelayOutputByNumber : relayStatusOutputNumber)
                    .length > 0"
                    lines="none" [button]="true" (click)="activateRelayOutputProp(Property.isRelayOutputForceOff, 1, relayStatusOutputNumber)">
                    {{'Force off' | translate}}
                  </ion-item>
                </ion-list>
              </ion-content>
            </ng-template>
          </ion-popover>
          <ng-container  *ngIf="controllers | findProperty: Property.isRelayOutputInCooling :
               updated | getRelayOutputByNumber : relayStatusOutputNumber as relayOutputInCoolingProps">
            <ion-item *ngIf="relayOutputInCoolingProps.length > 0"   lines="none" class="item-background-color">
            <ion-checkbox

            (click)="changeRelayOutputInCooling($event, relayStatusOutputNumber)
            "
            justify="start"
            labelPlacement="end"
            [checked]="relayOutputInCoolingProps | propIsActive "
            [disabled]="false"
            ><span class="ion-text-wrap">
            {{relayOutputStatusProp | getRelayOutputStatusName | translate}} {{"in cooling" | translate}}</span>
            </ion-checkbox
          > </ion-item >
      </ng-container>
        </ng-container>

      </div>
      </ng-container> -->
      </ng-container>
      <ng-container *ngIf="selectedControllers | findProperty: Property.isCO2SetPoint: null : true as CO2SetPointProps">
      <div
        *ngFor="let CO2SetPointProp of CO2SetPointProps"
        style="background-color: #EFEDED; width: 100%;"
        class="flex-row-center-v margin-left20">
          <div class="content-form-group margin-left20">
            <label>{{'CO2 set point' | translate}}:</label>
            <input type="number" [placeholder]="'CO2 set point' | translate" #input style="width: 200px;" [value]="CO2SetPointProp.value"/>
          </div>
          <div class="flex-column">
            <ion-icon
              class="clickable margin-left110 margin-top5"
              name="sync-outline"
              style="font-size: 20px;"
              (click)="syncCo2SetPoint()">
            </ion-icon>
            <ion-button style="width: 120px; margin-top: 5px" class="margin-top15 margin-left10 margin-bottom10" (click)="setCO2SetPoint(input.value, CO2SetPointProp)">Set</ion-button>
          </div>
      </div>
    </ng-container>
    </div>
      </div>
    </ng-container>
  </div>
</div>
