
<ng-container
  *ngIf="propFilterFunction === Property.isCleaningStatus && controllers | findProperty : Property.isCleaningStatus: updated : true as props"
>
  <div
    *ngFor="let prop of props"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <!-- 0 not cleaned -->
    <ng-container *ngIf="prop?.value === 0 || prop?.value === '0'">
      <div class="icon-cleaned icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Room not cleaned' | translate }}</div>
    </ng-container>
    <!-- 1 cleaned -->
    <ng-container *ngIf="prop?.value === 1 || prop?.value === '1'">
      <div class="icon-cleaned icon-background-on font-size19"></div>
      <div class="status-text-on">{{ 'Room cleaned' | translate }}</div>
    </ng-container>
    <!-- 2 touched -->

    <ng-container *ngIf="prop?.value === 2 || prop?.value === '2'">
      <div class="icon-cleaned icon-background-on font-size19"></div>
      <div class="status-text-on">{{ 'Room touched' | translate }}</div>
    </ng-container>
    <!-- ELSE -->
    <ng-container
      *ngIf="
        prop?.value !== -1 &&
        prop?.value !== '-1' &&
        prop?.value !== 0 &&
        prop?.value !== '0' &&
        prop?.value !== 1 &&
        prop?.value !== '1' &&
        prop?.value !== 2 &&
        prop?.value !== '2'
      "
    >
      <div class="icon-cleaned icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Cleaned status undefined' | translate }}</div>
    </ng-container>
  </div>
</ng-container>

<ng-container
  *ngIf="propFilterFunction === Property.isHighestCleaningStatus && controllers | findProperty : Property.isHighestCleaningStatus: updated : true as props">
  <ng-container *ngIf="props.length > 0">
      <div
        *ngFor="let prop of props"
        class="status-container-small flex-row-center-v margin-bottom12 width230">
        <!-- 0 dirty -->
        <ng-container *ngIf="prop?.value === 0 || prop?.value === '0'">
          <div class="icon-cleaned icon-background-on font-size19"></div>
          <div class="status-text-on font-size11">{{ 'Highest cleaning status - not cleaned' | translate }}</div>
        </ng-container>
        <!-- 1 cleaned -->
        <ng-container *ngIf="prop?.value === 1 || prop?.value === '1'">
          <div class="icon-cleaned icon-background-on font-size19"></div>
          <div class="status-text-on font-size11">{{ 'Highest cleaning status - cleaned' | translate }}</div>
        </ng-container>
        <!-- 2 inspected -->
        <ng-container *ngIf="prop?.value === 2 || prop?.value === '2'">
          <div class="icon-cleaned icon-background-on font-size19"></div>
          <div class="status-text-on font-size11">{{ 'Highest cleaning status - inspected' | translate }}</div>
        </ng-container>
    
        <!-- 3 did not pass inspection -->
        <ng-container *ngIf="prop?.value === 3 || prop?.value === '3'">
            <div class="icon-cleaned icon-background-on font-size19"></div>
            <div class="status-text-on font-size11">{{ 'Highest cleaning status - did not pass inspection' | translate }}</div>
          </ng-container>
        <!-- ELSE -->
        <ng-container
          *ngIf="
            prop?.value !== -1 &&
            prop?.value !== '-1' &&
            prop?.value !== 0 &&
            prop?.value !== '0' &&
            prop?.value !== 1 &&
            prop?.value !== '1' &&
            prop?.value !== 2 &&
            prop?.value !== '2' &&
            prop?.value !== 3 &&
            prop?.value !== '3'
          "
        >
          <div class="icon-cleaned icon-background-off font-size19"></div>
          <div class="status-text-off font-size11">{{ 'Highest cleaning status - undefined' | translate }}</div>
        </ng-container>
      </div>
  </ng-container>
</ng-container>
