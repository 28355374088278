import { OutpuModeStatus } from './output-mode.enum';

export const OutputStatusNameHr = new Map<number, string>([
    [OutpuModeStatus.Off, 'Isključeno'],
    [OutpuModeStatus.On, 'Uključeno'],
    [OutpuModeStatus.Luxomat, 'Luksomat'],
    [OutpuModeStatus.TimeInterval, 'Vremenski interval'],
    [OutpuModeStatus.LuxomatAndTimeInterval, 'Luksomat i vremenski interval'],
    [OutpuModeStatus.OutputControl, 'Udaljeno upravljanje'],
    [OutpuModeStatus.PIR, 'Prisutnost u sobi'],
    [OutpuModeStatus.KeyboardIN1, 'Tipkovnica'],
    [OutpuModeStatus.SwitchIN1, 'Sklopka'],
    [OutpuModeStatus.LuxomatAndPIR, 'Luksomat i prisutnost u sobi'],
    [OutpuModeStatus.TimeIntervalAndPIR, 'Vremenski interval i prisutnost u sobi']
    ]);
