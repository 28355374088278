<div class="flex-column margin-top20 modal-content-container">
    <div class="flex-row-space-evenly width100">
        <div class="flex-column margin-left10 margin-right10 width100">
            <ion-card-title class="flex-row-center-h full-minus-20 padding5 backgroundBlue">
              {{'Select where to paste output mode' | translate }}
            </ion-card-title>
          </div>
    </div>

    <div class="flex-row">
        <div *ngFor="let controller of controllers" class="flex-column padding10">
            <div class="flex-row-name-arrow">
                <h2>
                    {{controller.name}}
                </h2>
            </div>

                <div class="flex-column">
                    <ng-container *ngIf="[controller] | findProperty: isOutputMode: null : true as outputModes">
                            <div *ngFor="let outputMode of outputModes" class="padding5">
                                <ion-checkbox color="primary" class="margin-left5" (ionChange)="addOutputModeToPaste($event,outputMode, controller)">{{ outputMode.name }}</ion-checkbox>
                            </div>
                    </ng-container>
                </div>

        </div>
    </div>
  </div>
  <div class="flex-row-end-end margin-bottom20 margin-right20 sticky">
    <ion-button class="margin-top10" (click)="pasteSelectedOutputMode()" [disabled]="propsAndControllersForPaste.length < 1">{{'Accept selected' | translate}}</ion-button>
</div>

