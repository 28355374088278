import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'runFunction'
})
export class RunFunctionPipe implements PipeTransform {

  transform<T>(param: any, funct: (i: any)=> T): T {
    return funct(param);
  }

}
