import { Pipe, PipeTransform } from '@angular/core';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';

@Pipe({
  name: 'getAlarmsLevel'
})
export class GetAlarmsLevelPipe implements PipeTransform {

  transform(alarms: AlarmAlert[]): string {
    const lvl = this.getAlarmsBiggestLvl(alarms);
    let value: string;
    switch(lvl) {
      case 1: {
         value = 'info';
         break;
      }
      case 2: {
         value = 'warning';
         break;
      }
      case 3: {
         value = 'danger';
         break;
      }
      case 4: {
        value = 'critical';
        break;
     }
      default: {
         value = '';
         break;
      }
   }
   return value;
  }


  getAlarmsBiggestLvl(alarms: AlarmAlert[]) {
    let max = 0;
    alarms.forEach(alarm => {
      if (max < alarm.level && +alarm.confirmed === 0) {
        max = alarm.level;
      }
    })
    return max;
  }

}
