<!-- <ion-app class="app-layout">
  <app-header *ngIf="showHeader"></app-header>
  <app-message-box></app-message-box>
  <section>
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <router-outlet></router-outlet>
  </section>
  <app-footer *ngIf="showFooter"></app-footer>
</ion-app>
 -->

<app-message-box></app-message-box>
<ion-app>
  <!-- <ion-split-pane when="(min-width: 1300px)" contentId="main"> -->
  <ion-split-pane when="(min-width: 0px)" contentId="main">
    <ion-menu contentId="main" [disabled]="!showSidebar" class="ion-menu">
      <app-sidebar *ngIf="showSidebar" class="sidebar"></app-sidebar>
    </ion-menu>
    <div id="main" class="width-full">
      <ion-header *ngIf="showHeader">
        <app-header></app-header>
      </ion-header>
      <!-- <ion-content [ngClass]="{'content-footer': showFooter}">
        <router-outlet></router-outlet>
      </ion-content> -->
      <ion-content [ngClass]="{'reduce-height-by-header': showHeader}" #content (ionScroll)="onScroll($event)" scrollEvents="true">
        <router-outlet></router-outlet>
      </ion-content>
      <!-- <ion-footer>
        <app-footer *ngIf="showFooter"></app-footer>
      </ion-footer> -->
    </div>
  </ion-split-pane>
</ion-app>
<div class="version">v: {{version}}</div>

<app-modal-confirmation
[type]="'levelFourAlarm'"
[content]="alarmAlert.message"
[burgRooms]="burgRooms"
(decision)="onConfirmLevelFourAlarmModal()"
*ngIf="openModal">
</app-modal-confirmation>
