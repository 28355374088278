import { trigger, transition, style, animate } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({transform: 'translateY(-10%)', opacity: 0}),
      animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('300ms', style({transform: 'translateY(-10%)', opacity: 0}))
    ])
  ]
);


export const insertRemoveTrigger = trigger(
  'insertRemoveTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.5s', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('1s', style({ opacity: 0 }))
    ])
  ]);
