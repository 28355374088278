import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { ControllerCommisioningParam } from 'src/app/core/models/project/controller-commissioning-params';
import { API_BASE_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCcpService {

  constructor(private http: HttpClient) { }

  read(ccp: ControllerCommisioningParam[]){
    return this.http.post(API_BASE_URL + '/project/controllers/ccp/get', ccp);
  }

  send(ccp: ControllerCommisioningParam[]){
    return this.http.post(API_BASE_URL + '/project/controllers/ccp/set', ccp);
  }

  addCCP(ccp: ControllerCommisioningParam[]){
    return this.http.post(API_BASE_URL + '/project/controllers/ccp', ccp)
  }

  updateCCP(ccp: ControllerCommisioningParam[]){
    return this.http.put(API_BASE_URL + '/project/controllers/ccp', ccp)
  }

  deleteCCP(ccp: ControllerCommisioningParam[]){
    const options = {body: ccp};
    return this.http.delete(API_BASE_URL + '/project/controllers/ccp', options);
  }

  applyToAlLControllers(ccps: ControllerCommisioningParam[], controllerIds: number[], overrideConflicts: boolean, skipIntercept?: boolean){
    const headers = new HttpHeaders().set('skip-interceptor', '');
    const body = {
      "CcpsToApply": ccps,
      "ControllerIdsToApply": controllerIds,
      "OverrideConflicts": overrideConflicts
    }
    if(skipIntercept){
      return this.http.post(API_BASE_URL + '/project/controllers/ccp/apply', body, {headers: headers});
    }else{
      return this.http.post(API_BASE_URL + '/project/controllers/ccp/apply', body);
    }
  }
}
