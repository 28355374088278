<div
  *ngIf="_controllers | findProperty : findPropFunction: null : true as property"
  class="flex-row border-bottom margin-bottom10"
>
  <div class="temp-box-title margin-right20 icon-left-text">
    {{ property[0].name | translate }}
  </div>
  <ion-button
    size="small"
    fill="outline"
    color="medium"
    class="ion-btn-pad-sm margin2 width-25"
    (click)="decrement()"
    >-</ion-button
  >
  <div
    *ngIf="(targetValue || property[0]?.value) && !loading; else novalue"
    class="temp-box-content"
  >
    <span class="temp-num">{{ targetValue || property[0]?.value }}</span
    ><span class="temp-unit">°C</span>
  </div>
  <ng-template #novalue>
    <div *ngIf="!loading" class="temp-box-content">
      <div class="no-info">{{ "No info" | translate }}</div>
    </div>
    <ion-spinner *ngIf="loading" class="flex-row-center-full" name="lines-sharp"></ion-spinner>
  </ng-template>
  <ion-button
    size="small"
    fill="outline"
    color="medium"
    class="ion-btn-pad-sm margin2 width-25"
    (click)="increment()"
    >+</ion-button
  >
</div>
