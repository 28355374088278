import { Component, OnInit, Input } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';

@Component({
  selector: 'app-room-settings-temps',
  templateUrl: './room-settings-temps.component.html',
  styleUrls: ['./room-settings-temps.component.scss']
})
export class RoomSettingsTempsComponent implements OnInit {
  @Input() selectedControllers: Controller[];
  @Input() hvacBaseSet: (property: Property) => boolean;
  @Input() hvacEcoHyst1: (property: Property) => boolean;
  @Input() hvacEcoHyst2: (property: Property) => boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
